import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allLeads: [],
    selectedLeadLog: null
};

export const leadLogSlice = createSlice({
    name: 'leadLog',
    initialState,
    reducers: {
        getLeads: (state, action) => {
            state.allLeads = action.payload;
        },
        getSelectedLeadLog: (state, action) => {
            state.selectedLeadLog = action.payload;
        }
    }
});

export const leadLogSliceAction = leadLogSlice.actions;
