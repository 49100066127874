import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    exitPageDetails: []
};

export const exitPageDetailsSlice = createSlice({
    name: 'exitPageDetails',
    initialState,
    reducers: {
        getExitPageDetails: (state, action) => {
            state.exitPageDetails = action.payload;
        }
    }
});

export const exitPageDetailsAction = exitPageDetailsSlice.actions;
