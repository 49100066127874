import React, { useEffect } from 'react';
import {
    Typography,
    Button,
    Grid,
    useFormik,
    Box,
    LoadingButton,
    Drawer,
    Autocomplete,
    Checkbox,
    TextField,
    Divider,
    apiEndPointsConfig,
    Yup,
    FormLabel,
    useState,
    useParams
} from '../../../../collections/Imports';
import { FormControlLabel, FormControl, Radio, RadioGroup, FormGroup } from '@mui/material';
import '../../../schedule/schedule.css';
import apiService from 'helpers/apiService';
import { enqueueSnackbar } from 'notistack';

const AddEditSchedule = ({ addToggleDrawer, setIsOpen, edit, setEdit, isEdit, loading, setLoading, getPageData }) => {
    const { id } = useParams();

    useEffect(() => {
        if (isEdit && edit?.scheduleMethod == 'Days') {
            setSelectedDays(edit?.period);
        }
    }, [isEdit]);

    const [editingSchedule, setEditingSchedule] = useState([]);
    const [selectedDays, setSelectedDays] = useState(editingSchedule.days || []);

    const handleCheckboxChange = (event, days) => {
        const day = days;
        const updatedSelectedDays = [...selectedDays];

        if (event.target.checked) {
            updatedSelectedDays.push(day);
        } else {
            const index = updatedSelectedDays.indexOf(day);
            if (index !== -1) {
                updatedSelectedDays.splice(index, 1);
            }
        }

        setSelectedDays(updatedSelectedDays);
    };

    const numericErrorMessage = 'Please enter a valid number';

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This Field is required'),
        scheduleMethod: Yup.string().required('This Field is required'),

        startTimeHour: Yup.string().required('This Field is required').matches(/^\d+$/, numericErrorMessage),
        endTimeHour: Yup.string().required('This Field is required').matches(/^\d+$/, numericErrorMessage),
        startTimeMinute: Yup.string().required('This Field is required').matches(/^\d+$/, numericErrorMessage),
        endTimeMinute: Yup.string().required('This Field is required').matches(/^\d+$/, numericErrorMessage),
        startDate: Yup.date().when('scheduleMethod', {
            is: 'DATE RANGE',
            then: Yup.date().required('Start Date is required')
        }),
        endDate: Yup.date().when('scheduleMethod', {
            is: 'DATE RANGE',
            then: Yup.date().required('End Date is required').min(Yup.ref('startDate'), 'End Date must be after Start Date')
        }),
        submitLeadLimit: Yup.number().when('action', {
            is: 'Post',
            then: Yup.number().required('Submit Lead Limit is required').positive('Must be a positive number')
        }),
        disbursedLeadLimit: Yup.number().when('action', {
            is: 'Post',
            then: Yup.number()
                .required('Disbursed Lead Limit is required')
                .positive('Must be a positive number')
                .test('is-disbursed-lead-limit-valid', 'Disbursed Lead Limit cannot exceed Submit Lead Limit', function (value) {
                    const submitLeadLimit = this.parent.submitLeadLimit;
                    return value <= submitLeadLimit;
                })
        })
    });

    // const validationSchema = Yup.object()
    //     .shape({
    //         action: Yup.string().required('This Field is required'),
    //         scheduleMethod: Yup.string().required('This Field is required'),

    //         startTimeHour: Yup.string().required('This Field is required').matches(/^\d+$/, numericErrorMessage),
    //         endTimeHour: Yup.string().required('This Field is required').matches(/^\d+$/, numericErrorMessage),
    //         startTimeMinute: Yup.string().required('This Field is required').matches(/^\d+$/, numericErrorMessage),
    //         endTimeMinute: Yup.string().required('This Field is required').matches(/^\d+$/, numericErrorMessage),

    //         submitLeadLimit: Yup.number().required('This Field is required').positive('Must be a positive number'),
    //         disbursedLeadLimit: Yup.number().required('This Field is required').positive('Must be a positive number')
    //     })
    //     .test('is-start-before-end', 'Start date must be before end date', function (values) {
    //         const { startTimeHour, startTimeMinute, endTimeHour, endTimeMinute } = values;
    //         const start = parseInt(startTimeHour) * 60 + parseInt(startTimeMinute);
    //         const end = parseInt(endTimeHour) * 60 + parseInt(endTimeMinute);
    //         return start < end;
    //     })
    //     .test('is-disbursed-lead-limit-valid', 'Disbursed lead limit cannot exceed submit lead limit', function (values) {
    //         const { submitLeadLimit, disbursedLeadLimit } = values;
    //         return disbursedLeadLimit <= submitLeadLimit;
    //     });

    // EDIT start time
    if (isEdit) {
        var timeString = edit?.startTime;
        var timeComponents = timeString.split(':');
        var startHours = timeComponents[0];
        var startMinute = timeComponents[1];
    }

    if (isEdit) {
        var timeStringEndTime = edit?.endTime;
        var timeComponentsendTime = timeStringEndTime.split(':');
        var endHours = timeComponentsendTime[0];
        var endMinute = timeComponentsendTime[1];
    }
    // EDIT end time

    //EDIT DATE

    var periodString = edit?.scheduleMethod == 'Date range' && edit?.period;

    // Split the period string into start and end date strings
    if (isEdit && edit?.scheduleMethod === 'Date range') {
        var dates = periodString.split(' - ');
        var startDateString = dates[0];
        var endDateString = dates[1];
    }

    const formik = useFormik({
        initialValues: {
            dsaId: isEdit ? edit?.dsa : id,
            action: isEdit ? edit?.action || '' : 'Post',
            scheduleMethod: isEdit ? edit?.scheduleMethod || '' : 'DAYS',
            days: isEdit ? edit?.period || [] : [],
            startDate: isEdit ? startDateString || '' : '',
            endDate: isEdit ? endDateString || '' : '',
            startTimeHour: isEdit ? startHours || '' : '',
            startTimeMinute: isEdit ? startMinute || '' : '',
            endTimeHour: isEdit ? endHours || '' : '',
            endTimeMinute: isEdit ? endMinute || '' : '',
            submitLeadLimit: isEdit ? edit?.submitLeadLimit || '' : '',
            disbursedLeadLimit: isEdit ? edit?.disbursedLeadLimit || '' : '',
            totalSubmit: isEdit ? edit?.totalSubmit || '' : '',
            totalDisbursed: isEdit ? edit?.totalDisbursed || '' : '',
            status: true
        },

        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);

            const addData = {
                dsaId: id,
                action: values.action,
                scheduleMethod: values.scheduleMethod,
                days: selectedDays,
                startDate: values.startDate,
                endDate: values.endDate,
                startTime: values.startTimeHour + ':' + values.startTimeMinute,
                endTime: values.endTimeHour + ':' + values.endTimeMinute,
                submitLeadLimit: values.submitLeadLimit,
                disbursedLeadLimit: values.disbursedLeadLimit,
                status: true
            };
            const updateData = {
                dsaScheduleId: edit?.dsaScheduleId,
                dsaId: edit?.dsa,
                action: values.action,
                scheduleMethod: values.scheduleMethod,
                days: selectedDays,
                startDate: values.startDate,
                endDate: values.endDate,
                startTime: values.startTimeHour + ':' + values.startTimeMinute,
                endTime: values.endTimeHour + ':' + values.endTimeMinute,
                submitLeadLimit: values.submitLeadLimit,
                disbursedLeadLimit: values.disbursedLeadLimit,
                status: true
            };

            if (isEdit) {
                apiService
                    .post(apiEndPointsConfig.updateDsaSchedule, { data: updateData })
                    .then((response) => {
                        if (response.data.status.code === 200) {
                            setLoading(false);
                            enqueueSnackbar(`${edit ? 'Scheduler Updated Successfully' : 'Scheduler Created Successfully'}`, {
                                variant: 'success'
                            });
                            setEdit(response);
                            formik.resetForm();
                            addToggleDrawer();
                            getPageData();
                        } else {
                            setLoading(false);
                            enqueueSnackbar(response.data.data, { variant: 'error' });
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        enqueueSnackbar(error, { variant: 'error' });
                    });
            } else {
                apiService
                    .post(apiEndPointsConfig.addDsaSchedule, { data: addData })
                    .then((response) => {
                        if (response.data.status.code === 200) {
                            setLoading(false);
                            enqueueSnackbar(`${edit ? 'Scheduler Update  Successfully' : 'Scheduler Created  Successfully'}`, {
                                variant: 'success'
                            });
                            setEdit(response);
                            formik.resetForm();
                            getPageData();
                            addToggleDrawer();
                        } else {
                            setLoading(false);
                            enqueueSnackbar(response.data.data, { variant: 'error' });
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        enqueueSnackbar(error, { variant: 'error' });
                    });
            }
        }
    });

    const daysArray = ['MON', 'TUE', 'WED', 'THURS', 'FRI', 'SAT', 'SUN'];

    return (
        <Drawer
            anchor="right"
            open={addToggleDrawer}
            className="drawar-style"
            sx={{
                '& .MuiDrawer-paperAnchorRight': {
                    margin: 2,
                    height: '98vh',
                    borderRadius: '8px',
                    border: '1px solid #DFE3E7',
                    background: '#FFF',
                    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.12)'
                }
            }}
        >
            <Box minWidth="500px" maxWidth="500px">
                <form onSubmit={formik.handleSubmit}>
                    <Box className="filter-header">
                        <Typography className="filter-title">{isEdit ? `Update Schedule Rule` : `Add Schedule Rule`}</Typography>
                    </Box>
                    <Divider />
                    <Box minWidth="500px" maxWidth="500px">
                        <Box p={1}>
                            <Box mt={1}>
                                <FormLabel htmlFor="action">Action</FormLabel>
                                <Autocomplete
                                    size="small"
                                    te
                                    limitTags={2}
                                    id="action-list-autocomplete"
                                    value={formik.values.action}
                                    options={['Post', 'Do Not Post']}
                                    getOptionLabel={(option) => String(option)}
                                    name="action"
                                    disableClearable
                                    onChange={(event, newValue) => formik.setFieldValue('action', newValue, true)}
                                    renderInput={(params) => <TextField size="small" {...params} placeholder="Select Schedule Action" />}
                                />
                                {formik.touched.action && formik.errors.action ? (
                                    <div className="error-message">{formik.errors.action}</div>
                                ) : null}
                            </Box>
                        </Box>
                        <Box p={1}>
                            <Typography>Scheduler Method</Typography>
                            <RadioGroup
                                aria-label="method"
                                // defaultValue={editingSchedule ? editingSchedule.recursionType : ''}
                                name="scheduleMethod"
                                onChange={formik.handleChange}
                                value={formik.values.scheduleMethod}
                            >
                                <Box display={'flex'}>
                                    <FormControlLabel value="DAYS" control={<Radio />} label="Days" className="radio_box" />
                                    <FormControlLabel value="DATE RANGE" control={<Radio />} label="Date Range" className="radio_box" />
                                </Box>
                                {formik.touched.scheduleMethod && formik.errors.scheduleMethod ? (
                                    <div className="error-message">{formik.errors.scheduleMethod}</div>
                                ) : null}
                            </RadioGroup>
                        </Box>
                        {formik.values.scheduleMethod === 'DAYS' && (
                            <Box p={1}>
                                <FormLabel htmlFor="days">Select Days</FormLabel>
                                <FormControl>
                                    <FormGroup row={true}>
                                        {daysArray.map((day) => (
                                            <div key={day} className="check_box_sty">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            value={day}
                                                            name="days"
                                                            checked={selectedDays.includes(day)} // Simplify this line
                                                            onChange={(event) => handleCheckboxChange(event, day)}
                                                        />
                                                    }
                                                    label={day}
                                                />
                                            </div>
                                        ))}
                                    </FormGroup>

                                    <Box>
                                        {selectedDays.length === 0 && <div className="error-message">Please select at least one day.</div>}
                                    </Box>
                                </FormControl>
                            </Box>
                        )}

                        {formik.values.scheduleMethod === 'DATE RANGE' && (
                            <>
                                <Box p={1} display={'flex'}>
                                    <div style={{ width: '100%', paddingRight: '10px' }}>
                                        <Typography>Start Date</Typography>
                                        <TextField
                                            size="small"
                                            name="startDate"
                                            placeholder={'Start Date'}
                                            value={formik?.values?.startDate}
                                            onChange={(e) => formik.setFieldValue('startDate', e.target.value)}
                                            fullWidth
                                            type="date"
                                            error={formik.touched.startDate && formik.errors.startDate}
                                            helperText={formik.touched.startDate && formik.errors.startDate}
                                        />
                                    </div>
                                    <div style={{ width: '100%', paddingLeft: '10px' }}>
                                        <Typography>End Date</Typography>
                                        <TextField
                                            size="small"
                                            name="endDate"
                                            placeholder={'End Date'}
                                            value={formik?.values?.endDate}
                                            onChange={(e) => formik.setFieldValue('endDate', e.target.value)}
                                            fullWidth
                                            type="date"
                                            error={formik.touched.endDate && formik.errors.endDate}
                                            helperText={formik.touched.endDate && formik.errors.endDate}
                                        />
                                    </div>
                                </Box>
                            </>
                        )}
                        <Box p={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box width={'17%'} sx={{ paddingTop: '6px' }}>
                                Start time
                            </Box>
                            <Box sx={{ flex: 1, px: 2 }}>
                                <FormLabel htmlFor="startTimeHour">Hour</FormLabel>
                                <Autocomplete
                                    size="small"
                                    te
                                    limitTags={2}
                                    id="startTimeHour-list-autocomplete"
                                    value={formik.values.startTimeHour || []}
                                    options={[
                                        '00',
                                        '01',
                                        '02',
                                        '03',
                                        '04',
                                        '05',
                                        '06',
                                        '07',
                                        '08',
                                        '09',
                                        '10',
                                        '11',
                                        '12',
                                        '13',
                                        '14',
                                        '15',
                                        '16',
                                        '17',
                                        '18',
                                        '19',
                                        '20',
                                        '21',
                                        '22',
                                        '23'
                                    ]}
                                    getOptionLabel={(option) => String(option)}
                                    name={'startTimeHour'}
                                    disableClearable
                                    onChange={(event, newValue) => formik.setFieldValue('startTimeHour', newValue, true)}
                                    renderInput={(params) => <TextField size="small" {...params} placeholder="Select Hour" />}
                                />
                                {formik.touched.startTimeHour && formik.errors.startTimeHour ? (
                                    <div className="error-message">{formik.errors.startTimeHour}</div>
                                ) : null}
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <FormLabel htmlFor="startTimeMinute">Minute</FormLabel>
                                <Autocomplete
                                    size="small"
                                    te
                                    limitTags={2}
                                    id="startTimeMinute-list-autocomplete"
                                    value={formik.values.startTimeMinute || []}
                                    options={[
                                        '00',
                                        '01',
                                        '02',
                                        '03',
                                        '04',
                                        '05',
                                        '06',
                                        '07',
                                        '08',
                                        '09',
                                        '10',
                                        '11',
                                        '12',
                                        '13',
                                        '14',
                                        '15',
                                        '16',
                                        '17',
                                        '18',
                                        '19',
                                        '20',
                                        '21',
                                        '22',
                                        '23',
                                        '24',
                                        '25',
                                        '26',
                                        '27',
                                        '28',
                                        '29',
                                        '30',
                                        '31',
                                        '32',
                                        '33',
                                        '34',
                                        '35',
                                        '36',
                                        '37',
                                        '38',
                                        '39',
                                        '40',
                                        '41',
                                        '42',
                                        '43',
                                        '44',
                                        '45',
                                        '46',
                                        '47',
                                        '48',
                                        '49',
                                        '50',
                                        '51',
                                        '52',
                                        '53',
                                        '54',
                                        '55',
                                        '56',
                                        '57',
                                        '58',
                                        '59'
                                    ]}
                                    getOptionLabel={(option) => String(option)}
                                    name={'startTimeMinute'}
                                    disableClearable
                                    onChange={(event, newValue) => formik.setFieldValue('startTimeMinute', newValue, true)}
                                    renderInput={(params) => <TextField size="small" {...params} placeholder="Select Minute" />}
                                />
                                {formik.touched.startTimeMinute && formik.errors.startTimeMinute ? (
                                    <div className="error-message">{formik.errors.startTimeMinute}</div>
                                ) : null}
                            </Box>
                        </Box>
                        <Box p={1} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box width={'17%'} sx={{ paddingTop: '6px' }}>
                                End time
                            </Box>
                            <Box sx={{ flex: 1, px: 2 }}>
                                <FormLabel htmlFor="endTimeHour">Hour</FormLabel>
                                <Autocomplete
                                    size="small"
                                    te
                                    limitTags={2}
                                    id="endTimeHour-list-autocomplete"
                                    value={formik.values.endTimeHour || []}
                                    options={[
                                        '00',
                                        '01',
                                        '02',
                                        '03',
                                        '04',
                                        '05',
                                        '06',
                                        '07',
                                        '08',
                                        '09',
                                        '10',
                                        '11',
                                        '12',
                                        '13',
                                        '14',
                                        '15',
                                        '16',
                                        '17',
                                        '18',
                                        '19',
                                        '20',
                                        '21',
                                        '22',
                                        '23'
                                    ]}
                                    getOptionLabel={(option) => String(option)}
                                    name={'endTimeHour'}
                                    disableClearable
                                    onChange={(event, newValue) => formik.setFieldValue('endTimeHour', newValue, true)}
                                    renderInput={(params) => <TextField size="small" {...params} placeholder="Select Hour" />}
                                />
                                {formik.touched.endTimeHour && formik.errors.endTimeHour ? (
                                    <div className="error-message">{formik.errors.endTimeHour}</div>
                                ) : null}
                            </Box>
                            <Box sx={{ flex: 1 }}>
                                <FormLabel htmlFor="endTimeMinute">Minute</FormLabel>
                                <Autocomplete
                                    size="small"
                                    te
                                    limitTags={2}
                                    id="endTimeMinute-list-autocomplete"
                                    value={formik.values.endTimeMinute || []}
                                    options={[
                                        '00',
                                        '01',
                                        '02',
                                        '03',
                                        '04',
                                        '05',
                                        '06',
                                        '07',
                                        '08',
                                        '09',
                                        '10',
                                        '11',
                                        '12',
                                        '13',
                                        '14',
                                        '15',
                                        '16',
                                        '17',
                                        '18',
                                        '19',
                                        '20',
                                        '21',
                                        '22',
                                        '23',
                                        '24',
                                        '25',
                                        '26',
                                        '27',
                                        '28',
                                        '29',
                                        '30',
                                        '31',
                                        '32',
                                        '33',
                                        '34',
                                        '35',
                                        '36',
                                        '37',
                                        '38',
                                        '39',
                                        '40',
                                        '41',
                                        '42',
                                        '43',
                                        '44',
                                        '45',
                                        '46',
                                        '47',
                                        '48',
                                        '49',
                                        '50',
                                        '51',
                                        '52',
                                        '53',
                                        '54',
                                        '55',
                                        '56',
                                        '57',
                                        '58',
                                        '59'
                                    ]}
                                    getOptionLabel={(option) => String(option)}
                                    name={'endTimeMinute'}
                                    disableClearable
                                    onChange={(event, newValue) => formik.setFieldValue('endTimeMinute', newValue, true)}
                                    renderInput={(params) => <TextField size="small" {...params} placeholder="Select Minute" />}
                                />
                                {formik.touched.endTimeMinute && formik.errors.endTimeMinute ? (
                                    <div className="error-message">{formik.errors.endTimeMinute}</div>
                                ) : null}
                            </Box>
                        </Box>
                        {formik.values.action === 'Post' && (
                            <Box p={1} sx={{ display: 'flex' }}>
                                <Box sx={{ flex: 1, pr: '10px' }}>
                                    <Typography>Submit Lead Limit </Typography>

                                    <TextField
                                        size="small"
                                        name={'submitLeadLimit'}
                                        placeholder={'Enter Submit Lead Limit'}
                                        value={formik?.values?.submitLeadLimit}
                                        onChange={(e) => formik.setFieldValue('submitLeadLimit', e.target.value)}
                                        fullWidth
                                        error={formik.touched.submitLeadLimit && formik.errors.submitLeadLimit}
                                        helperText={formik.touched.submitLeadLimit && formik.errors.submitLeadLimit}
                                    />
                                </Box>

                                <Box sx={{ flex: 1, pl: '10px' }}>
                                    <Typography>Disbursed Lead Limit </Typography>

                                    <TextField
                                        size="small"
                                        name={'disbursedLeadLimit'}
                                        placeholder={'Enter Disbursed Lead Limit'}
                                        value={formik?.values?.disbursedLeadLimit}
                                        onChange={(e) => formik.setFieldValue('disbursedLeadLimit', e.target.value)}
                                        fullWidth
                                        error={formik.touched.disbursedLeadLimit && formik.errors.disbursedLeadLimit}
                                        helperText={formik.touched.disbursedLeadLimit && formik.errors.disbursedLeadLimit}
                                    />
                                </Box>
                            </Box>
                        )}
                        <br />
                        <Divider />
                        <Box p={2}>
                            <Grid container spacing={2} display={'flex'} justifyContent="flex-end" align-items="center">
                                <Grid item sm={4} mt={2} display={'flex'} justifyContent={'flex-end'}>
                                    <LoadingButton
                                        loading={loading}
                                        loadingPosition="end"
                                        type="submit"
                                        onClick={formik.handleSubmit}
                                        variant="contained"
                                        className="apply-button"
                                    >
                                        {isEdit ? 'Update' : 'Create'}
                                    </LoadingButton>
                                </Grid>
                                <Grid item sm={2} mt={2}>
                                    <Button onClick={addToggleDrawer} variant="outlined" className="cancel-button">
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </form>
            </Box>
        </Drawer>
    );
};

export default AddEditSchedule;
