import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lendersTimeResponse: []
};

export const lenderTimeResponseSlice = createSlice({
    name: 'lenderTimeResponse',
    initialState,
    reducers: {
        getlenderTimeResponse: (state, action) => {
            state.lendersTimeResponse = action.payload;
        }
    }
});

export const lenderTimeResponseAction = lenderTimeResponseSlice.actions;
