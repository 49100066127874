import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tabValues: 0
};

export const tabValuesSlice = createSlice({
    name: 'tabValues',
    initialState,
    reducers: {
        getTabValues: (state, action) => {
            state.tabValues = action.payload;
        }
    }
});

export const tabValuesSliceAction = tabValuesSlice.actions;
