import React from 'react';
import PropTypes from 'prop-types';

function CapitalizeName({ value }) {
    const name = value.charAt(0).toUpperCase() + value.slice(1);
    return <div>{name}</div>;
}

CapitalizeName.propTypes = {
    value: PropTypes.string.isRequired
};

export default CapitalizeName;
