import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    AllData: []
};

export const SchedulerSlice = createSlice({
    name: 'scheduler',
    initialState,
    reducers: {
        scheduleData: (state, action) => {
            state.AllData = action.payload;
        }
    }
});

export const schedulerActions = SchedulerSlice.actions;
