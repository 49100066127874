import React, { createContext, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { tabValuesDSASlice, tabValuesDSASliceAction } from 'store/dsa/tabValueDsaSlice';
import { tabValuesSliceAction } from 'store/leads/tabHandlerSlice';
const TabContext = createContext();

const TabProvider = ({ children }) => {
    const [tabValue, setTabValue] = useState(1);
    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        dispatch(tabValuesSliceAction.getTabValues(newValue));
    };

    return <TabContext.Provider value={{ tabValue, handleChange }}>{children}</TabContext.Provider>;
};

const useTabContext = () => {
    const context = useContext(TabContext);
    if (!context) {
        throw new Error('useTabContext must be used within a TabProvider');
    }
    return context;
};

export { TabProvider, useTabContext };
