import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    moduleVisePermissions: []
};
export const permissionsSlice = createSlice({
    name: 'permissionsSlice',
    initialState,
    reducers: {
        addAllPermissionsStatus: (state, action) => {
            state.moduleVisePermissions = action.payload;
        }
    }
});

export const addAllPermissionsStatusActions = permissionsSlice.actions;
