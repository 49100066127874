import MinimalLayout from 'layout/MinimalLayout';
import Login from 'views/login';

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <Login />
        }
    ]
};

export default AuthenticationRoutes;
