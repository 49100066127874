import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  CommonChart,
  Grid,
  PageHeader,
  plus,
  Typography,
  Stack,
  TextField,
  useFormik,
  Yup,
  LoadingButton,
  Button,
  useSnackbar,
  moment,
  Icon,
  CommonDrawer,
  useSelector,
  ConfirmDialog,
  apiEndPointsConfig,
  useDispatch,
  useNavigate,
} from "../../collections/Imports";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  Tooltip,
  IconButton,
  Switch,
  Dialog,
} from "@mui/material";
import { Divider } from "@mui/material";
import "moment-timezone";
import { mdiLink } from "@mdi/js";
import { CloseIcon } from "collections/Imports";
import { mdiPencil } from "@mdi/js";
import apiService from "helpers/apiService";

import { offersActions } from "store/offers/offerSlice";

const PromotionalOffer = () => {
  const childRef = useRef();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const modulePermissions = useSelector((state) =>
    state.permissionsSlice.moduleVisePermissions.find(
      (res) => res.route === "/promotional-offer"
    )
  );
  const { offersData } = useSelector((state) => state?.offers);

  // console.log(offersData, " kkkkk");
  const [existingTitles, setExistingTitles] = useState([]);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [openZoom, setOpenZoom] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [columnDef, setColumnDef] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOfferId, setOfferId] = useState("");
  const [isEditOffer, setEditOffer] = useState(false);
  const [tableDataLoading, setTableDataLoading] = useState(false);
  const [totalData, setTotalData] = useState();
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 1000,
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleToggleFullscreen = () => {
    const element = childRef.current;
    if (!isFullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
        setIsFullscreen(true);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  // NOTE: Permissions
  useEffect(() => {
    if (offersData) {
      const titles = offersData.map((offer) => offer.title.toLowerCase());
      setExistingTitles(titles);
    }
  }, [offersData]);
  useEffect(() => {
    getOfferData();
  }, [controller]);

  // pagination Function

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value),
      page: 0,
    });
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
    setEditOffer(false);
    formik.resetForm();
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .test("unique-title", "Title must be unique", function (value) {
        const { path, createError } = this;
        if (isEditOffer) {
          // If editing, allow the current title
          const currentTitle = offersData
            .find((offer) => offer.id === isOfferId)
            ?.title.toLowerCase();
          if (currentTitle === value?.toLowerCase()) {
            return true;
          }
        }
        // Otherwise, check for uniqueness
        if (existingTitles.includes(value?.toLowerCase())) {
          return createError({ path, message: "Title must be unique" });
        }
        return true;
      }),
    destinationUrl: Yup.string()
      .url("Invalid URL")
      .required("URL is required")
      .test(
        "https-url",
        "URL must start with https",
        (value) => value && value.startsWith("https")
      ),
    bannerUrl: Yup.mixed()
      .test("fileFormat", "Only PNG files are allowed", function (value) {
        if (isEditOffer && !(value instanceof File)) {
          // Skip validation if in edit mode and no new file is uploaded
          return true;
        }
        return value && value.type === "image/png";
      })
      .required("Banner is required"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      destinationUrl: "",
      bannerUrl: "",
    },
    validationSchema: validationSchema,

    onSubmit: async (values, formik) => {
      const createOffer = new FormData();
      createOffer.append("bannerUrl", values?.bannerUrl);
      createOffer.append(
        "data",
        JSON.stringify({
          data: {
            title: values.title,
            destinationUrl: values?.destinationUrl,
          },
        })
      );

      const updateOffer = new FormData();
      updateOffer.append("bannerUrl", values?.bannerUrl);
      updateOffer.append(
        "data",
        JSON.stringify({
          data: {
            id: isOfferId,
            title: values?.title,
            destinationUrl: values?.destinationUrl,
          },
        })
      );

      let res;
      if (isEditOffer) {
        res = await apiService
          .post(apiEndPointsConfig.Update_Offers, updateOffer, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            getOfferData();
            setLoading(false);
          })
          .catch((error) => {
            enqueueSnackbar("Something went Wrong", { variant: "error" });
          });
      } else {
        apiService
          .post(apiEndPointsConfig.addOffers, createOffer, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            getOfferData();
          })
          .catch((error) => {
            enqueueSnackbar("Something went Wrong", { variant: "error" });
          });
      }
      if (res?.error) {
        enqueueSnackbar(`${res?.data}`, {
          variant: "error",
        });
        setLoading(false);
        return;
      }

      handleCloseDrawer();
      enqueueSnackbar(
        `Offer ${isEditOffer ? "Updated" : "Created"} Successfully`,
        {
          variant: "success",
        }
      );

      setLoading(false);
      formik.resetForm();
    },
  });

  const getOfferData = async () => {
    setTableDataLoading(true);
    apiService
      .post(apiEndPointsConfig.Get_Offers)
      .then((response) => {
        dispatch(
          offersActions.getOffers(response?.data?.data?.promotionalOffers)
        );
        setTotalData(response?.data?.data?.total);
        setTableDataLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setTableDataLoading(false);
        enqueueSnackbar("Something went Wrong", { variant: "error" });
      });
  };

  async function refresh() {
    getOfferData();
  }

  const handleEdit = (data) => {
    // console.log(data, "lllll");

    setEditOffer(true);
    handleOpenDrawer();
    setOfferId(data?.id);
    formik.setFieldValue("title", data.title, true);
    formik.setFieldValue("destinationUrl", data.destinationUrl, true);
    formik.setFieldValue("bannerUrl", data.bannerName);
  };

  const renderCreatedOn = ({ data }) =>
    moment(data?.createdOn).utc().format("LLL");

  useEffect(() => {
    if (offersData && offersData?.length) {
      const allKeys = Object.keys(offersData[0])?.filter(
        (res) =>
          res !== "active" &&
          res !== "destinationUrl" &&
          res !== "createdOn" &&
          res !== "bannerUrl" &&
          res !== "bannerName"
      );

      let newColumns = allKeys.map((key) => {
        return {
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1),
          textAlign: "center",
          defaultSort: "asc",
          cellClass: "my-class",
          editable: false,
          filterParams: {
            buttons: ["apply", "reset"],
            closeOnApply: true,
          },
        };
      });

      newColumns?.push({
        key: "bannerUrl",
        field: "bannerUrl",
        headerName: "Banner Image",
        textAlign: "center",
        sortable: false,

        cellRenderer: (params) => (
          <Tooltip title="Click to zoom">
            <IconButton
              onClick={() => {
                setSelectedImage(params?.data?.bannerUrl);
                setOpenZoom(true);
              }}
            >
              <img
                src={params?.data?.bannerUrl}
                alt="Banner"
                style={{ width: "60px", height: "30px" }}
              />
            </IconButton>
          </Tooltip>
        ),
      });

      newColumns?.push({
        key: "createdOn",
        field: "createdOn",
        headerName: "CreatedOn",
        textAlign: "center",
        sortable: false,
        cellRenderer: renderCreatedOn,
      });
      newColumns?.push({
        key: "active",
        field: "active",
        headerName: "Active",
        textAlign: "center",
        sortable: false,
        cellRenderer: statusButtons,
      });
      newColumns?.push({
        key: "destinationUrl",
        field: "destinationUrl",
        headerName: "Destination Url",
        textAlign: "center",
        sortable: false,

        cellRenderer: ({ data }) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "0.5rem",
              }}
            >
              <Box px={1}>
                <Tooltip
                  sx={{
                    cursor: "pointer",
                  }}
                  title={"Link"}
                  arrow
                >
                  <a
                    href={data.destinationUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Icon path={mdiLink} color="#0C247B" size={1} />
                  </a>
                </Tooltip>
              </Box>
            </Box>
          );
        },
      });

      newColumns?.push({
        key: "action",
        field: "action",
        headerName: "Action",
        textAlign: "center",
        sortable: false,

        cellRenderer: ({ data }) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "0.5rem",
              }}
            >
              <Box px={1}>
                <Tooltip
                  sx={{
                    cursor: "pointer",
                  }}
                  title={"Edit"}
                  arrow
                >
                  <Icon
                    path={mdiPencil}
                    color="#0C247B"
                    size={1}
                    onClick={() => handleEdit(data)}
                  />
                </Tooltip>
              </Box>
            </Box>
          );
        },
      });

      setColumnDef(newColumns);
    }
  }, [offersData]);

  const statusButtons = (params) => {
    // console.log(params, "dddd");
    return (
      <Switch
        sx={{
          cursor:
            modulePermissions?.permissions?.modifyContent ||
            modulePermissions?.permissions?.publishContent ||
            modulePermissions?.permissions?.admin
              ? "pointer"
              : "not-allowed",
          opacity:
            modulePermissions?.permissions?.modifyContent ||
            modulePermissions?.permissions?.publishContent ||
            modulePermissions?.permissions?.admin
              ? 1
              : 0.5,
        }}
        disabled={
          !modulePermissions?.permissions?.modifyContent &&
          !modulePermissions?.permissions?.publishContent &&
          !modulePermissions?.permissions?.admin
        }
        checked={params?.data?.active}
        onClick={(event) => {
          setConfirmDialog({
            isOpen: true,
            title: "Are you sure to Update Offer Status?",
            onConfirm: () => {
              handleSwitchChange(params?.data?.id, params?.data?.active);
            },
          });
        }}
      />
    );
  };

  const handleSwitchChange = useCallback(
    async (id, currentStatus) => {
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });

      const newStatus = !currentStatus; // Toggle the current status

      const data = { id: id, status: newStatus };
      try {
        await apiService.post(apiEndPointsConfig.SET_Offer_STATUS, { data });
        getOfferData();
        enqueueSnackbar("Status Updated Successfully", { variant: "success" });
      } catch (error) {
        enqueueSnackbar("Something went Wrong", { variant: "error" });
      }
    },
    [confirmDialog, enqueueSnackbar, getOfferData]
  );

  return (
    <>
      <CommonDrawer open={openDrawer} onClose={handleCloseDrawer}>
        <form onSubmit={formik.handleSubmit}>
          <Box className="filter-header">
            <Typography className="filter-title">
              {isEditOffer ? "Edit Offer" : "Add New Offer"}
            </Typography>
          </Box>
          <Divider />
          <Box minWidth="500px" maxWidth="500px">
            <Stack spacing={2} marginTop={2} paddingX={1}>
              <Stack spacing={2}>
                <Box className="custom-textfield" marginTop={0}>
                  <Typography mb={1}>Title </Typography>
                  <TextField
                    size="small"
                    name={"title"}
                    placeholder={"Enter Title"}
                    value={formik?.values?.title}
                    onChange={(e) =>
                      formik.setFieldValue("title", e.target.value)
                    }
                    fullWidth
                    error={formik.touched.title && formik.errors.title}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </Box>

                <Box className="custom-textfield">
                  <Typography mb={1}>Destination URL</Typography>
                  <TextField
                    size="small"
                    name={"destinationUrl"}
                    placeholder={"Enter destinationUrl URL"}
                    value={formik?.values?.destinationUrl}
                    onChange={(e) =>
                      formik.setFieldValue("destinationUrl", e.target.value)
                    }
                    fullWidth
                    error={
                      formik.touched.destinationUrl &&
                      formik.errors.destinationUrl
                    }
                    helperText={
                      formik.touched.destinationUrl &&
                      formik.errors.destinationUrl
                    }
                  />
                </Box>

                <Box>
                  <Typography mb={1}>Upload Image </Typography>

                  <Box style={{ position: "relative" }}>
                    {/* {console.log('=--==-=-=-=-=-=-=-=-=-=-=-=-=-=-', formik?.values?.bannerUrl)} */}
                    <TextField
                      size="small"
                      name={"bannerUrl"}
                      placeholder={"Upload Image"}
                      // value={formik?.values?.bannerUrl}
                      value={
                        formik.values.bannerUrl instanceof File
                          ? formik.values.bannerUrl.name
                          : formik.values.bannerUrl
                      }
                      onChange={(e) =>
                        formik.setFieldValue("bannerUrl", e.target.value)
                      }
                      inputProps={{
                        maxLength: 10,
                        readOnly: true,
                      }}
                      fullWidth
                      error={
                        formik.touched.bannerUrl && formik.errors.bannerUrl
                      }
                      helperText={
                        formik.touched.bannerUrl && formik.errors.bannerUrl
                      }
                    ></TextField>

                    <Box>
                      <input
                        style={{
                          position: "absolute",
                          top: "7px",
                          right: "0px",
                        }}
                        type="file"
                        onChange={(e) =>
                          formik.setFieldValue("bannerUrl", e.target.files[0])
                        }
                        accept=".png" // Specify accepted file types if needed
                      />
                    </Box>
                  </Box>
                </Box>
              </Stack>
            </Stack>
          </Box>
          <br />
          <Divider />
          <Box p={2}>
            <Grid
              container
              spacing={2}
              display={"flex"}
              justifyContent="flex-end"
              align-items="center"
            >
              <Grid
                item
                sm={4}
                mt={2}
                display={"flex"}
                justifyContent={"flex-end"}
              >
                <LoadingButton
                  loading={loading}
                  loadingPosition="end"
                  type="submit"
                  variant="contained"
                  className="apply-button"
                >
                  {isEditOffer ? "Update" : "Create"}
                </LoadingButton>
              </Grid>
              <Grid item sm={2} mt={2}>
                <Button
                  onClick={handleCloseDrawer}
                  variant="outlined"
                  className="cancel-button"
                >
                  Close
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </CommonDrawer>
      <Box>
        <PageHeader title={"Promotional Offers"} helpText={"Offers"} />
      </Box>

      <Grid item sm={12}>
        <Grid item sm={12}>
          <Box
            sx={{ width: { xs: "730px", md: "100%" } }}
            // pl={2}
            pt={2}
            spacing={isFullscreen ? 0 : 2}
            ref={childRef}
            className={`child-component ${isFullscreen ? "fullscreen" : ""}`}
          >
            {offersData && (
              <CommonChart
                title={"Promotional Offer Management"}
                createButtonValue={"Create Offer"}
                createIcon={<img src={plus} width={20} alt={"Export Button"} />}
                createOnClick={handleOpenDrawer}
                createBgColor="#EE9329"
                TextColor="#fff"
                hoverColor="red"
                onToggleFullscreen={handleToggleFullscreen}
                ref={childRef}
                className={`child-component ${
                  isFullscreen ? "fullscreen" : ""
                }`}
                isFullscreen={isFullscreen}
                columnDefs={columnDef}
                rowData={offersData ? [...offersData].reverse() : []}
                isGridRequired={true}
                isServerPagination={true}
                isLoading={tableDataLoading}
                onRefresh={refresh}
              >
                <TablePagination
                  component="div"
                  onPageChange={handlePageChange}
                  page={controller.page}
                  count={totalData}
                  rowsPerPage={controller.rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[1000, 2500, 5000, 10000]}
                  style={{
                    ...(isFullscreen && {
                      position: "absolute",
                      bottom: "20px",
                      right: 0,
                      left: 0,
                    }),
                  }}
                />
              </CommonChart>
            )}
          </Box>
          <Dialog
            open={openZoom}
            onClose={() => setOpenZoom(false)}
            maxWidth="lg"
          >
            <DialogTitle>
              Image Zoom
              <IconButton
                aria-label="close"
                onClick={() => setOpenZoom(false)}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <img src={selectedImage} alt="Zoomed" style={{ width: "100%" }} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenZoom(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PromotionalOffer;
