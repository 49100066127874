function createAgeRanges(ageData) {
    const ageRanges = [];
    if (ageData.lessThan22) {
        ageRanges.push({ min: 0, max: 21 });
    }
    if (ageData.between22To27) {
        ageRanges.push({ min: 22, max: 27 });
    }
    if (ageData.between28To35) {
        ageRanges.push({ min: 28, max: 34 });
    }
    if (ageData.between35To45) {
        ageRanges.push({ min: 35, max: 44 });
    }
    if (ageData.between45To55) {
        ageRanges.push({ min: 45, max: 54 });
    }
    if (ageData.moreThen55) {
        ageRanges.push({ min: 55, max: 2147483647 });
    }

    return ageRanges;
}

function createSalaryRanges(salaryData) {
    const salaryRanges = [];
    if (salaryData.lessThan10) {
        salaryRanges.push({ min: 0, max: 9999 });
    }
    if (salaryData.between10To30) {
        salaryRanges.push({ min: 10000, max: 29999 });
    }
    if (salaryData.between30To50) {
        salaryRanges.push({ min: 30000, max: 49999 });
    }
    if (salaryData.between50To1Lakh) {
        salaryRanges.push({ min: 50000, max: 99999 });
    }
    if (salaryData.moreThan1Lakh) {
        salaryRanges.push({ min: 100000, max: 2147483647 });
    }

    return salaryRanges;
}

function createLoanRanges(loanData) {
    const loanRanges = [];
    if (loanData.lessThan10) {
        loanRanges.push({ min: 0, max: 9999 });
    }
    if (loanData.between10To30) {
        loanRanges.push({ min: 10000, max: 29999 });
    }
    if (loanData.between30To50) {
        loanRanges.push({ min: 30000, max: 49999 });
    }
    if (loanData.between50To1Lakh) {
        loanRanges.push({ min: 50000, max: 99999 });
    }
    if (loanData.moreThan1Lakh) {
        loanRanges.push({ min: 100000, max: 2147483647 });
    }
    if (loanData.between1LakhTo2Point5Lakh) {
        loanRanges.push({ min: 100000, max: 250000 });
    }
    if (loanData.moreThan2Point5Lakh) {
        loanRanges.push({ min: 250000, max: 2147483647 });
    }

    return loanRanges;
}

export { createAgeRanges, createSalaryRanges, createLoanRanges };
