import React, { useEffect, useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const YourFilterComponent = ({ filterOptions, selectedFilterOptions, onFilterChange, ...rest }) => {
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(true);

    const handleCheckboxChange = (event) => {
        const { value: checkboxValue, checked } = event.target;

        if (checkboxValue === 'selectAll') {
            setSelectAllChecked(checked);
            setSelectedValues(checked ? filterOptions.map((option) => option.field) : []);
        } else if (checked) {
            setSelectedValues((prevValues) => [...prevValues, checkboxValue]);
        } else {
            const updatedValues = checked ? [...selectedValues, checkboxValue] : selectedValues.filter((value) => value !== checkboxValue);

            setSelectedValues(updatedValues);
            setSelectAllChecked(updatedValues.length === filterOptions.length);
        }
    };
    useEffect(() => {
        if (selectedFilterOptions) {
            setSelectedValues(selectedFilterOptions);
        }
    }, []);

    useEffect(() => {
        onFilterChange(selectedValues);
    }, [selectedValues]);

    return (
        <FormGroup {...rest} sx={{ p: 1 }}>
            <FormControlLabel
                control={<Checkbox checked={selectAllChecked} onChange={handleCheckboxChange} value="selectAll" />}
                label="Select All"
            />
            {filterOptions.map((option) => (
                <FormControlLabel
                    key={option.field}
                    control={
                        <Checkbox checked={selectedValues.includes(option.field)} onChange={handleCheckboxChange} value={option.field} />
                    }
                    label={option.field}
                />
            ))}
        </FormGroup>
    );
};

export default YourFilterComponent;
