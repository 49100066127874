import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    punchInKpi: [],
    punchinLenderStats: [],
    lender: [],
    status: [],
    gridData: [],
    selectedPunchinFilter: null
};

export const punchinSlice = createSlice({
    name: 'punchin',
    initialState,
    reducers: {
        getPunchInKpi: (state, action) => {
            state.punchInKpi = action.payload;
        },
        getPunchInLenderStats: (state, action) => {
            state.punchinLenderStats = action.payload;
        },
        getPunchInLender: (state, action) => {
            state.lender = action.payload;
        },
        getPunchInStatus: (state, action) => {
            state.status = action.payload;
        },
        getPunchInGridData: (state, action) => {
            state.gridData = action.payload;
        },
        getSelectedPunchinFilter: (state, action) => {
            state.selectedPunchinFilter = action.payload;
        }
    }
});

export const punchinAction = punchinSlice.actions;
