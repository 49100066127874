import React, { useEffect, useRef, useState } from 'react';
import { init } from 'echarts';

const CommonBarChart = ({ title, height, data }) => {
    const myChart = useRef(null);

    const [chartData, setChartData] = useState({});

    useEffect(() => {
        if (Object.keys(data).length) {
            setChartData(data);
        }
    });

    useEffect(() => {
        const chart = init(myChart.current);

        const handleResize = () => {
            chart.resize();
        };
        window.addEventListener('resize', handleResize);

        const options = {
            tooltip: chartData?.tooltip,
            title: [
                {
                    right: '60%',
                    left: 'center',
                    text: title
                }
            ],
            toolbox: {
                show: chartData?.toolbox,
                orient: 'vertical',
                left: 'right',
                feature: {
                    mark: { show: false },
                    dataView: { show: false, readOnly: false },
                    magicType: { show: true, type: ['line', 'bar'] }, // Remove 'stack' from the type array
                    restore: { show: false },
                    saveAsImage: { show: false }
                }
            },
            xAxis: [
                {
                    type: 'category',
                    axisTick: { show: false },
                    data: chartData?.xaxis,
                    axisLabel: {
                        rotate: chartData?.rotate,
                        interval: 0,
                        fontSize: window.innerWidth < 768 ? 5 : 10 // Adjust the font size based on screen width
                    }
                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        fontSize: '0.5rem'
                    }
                }
            ],
            series: chartData?.series
        };

        chart.setOption(options);
        return () => {
            chart.dispose();
        };
        // }
    }, [chartData]);

    return (
        <div
            ref={myChart}
            style={{
                width: '100%',
                height: height,
                paddingRight: 0,
                marginRight: 0
            }}
        ></div>
    );
};

export default CommonBarChart;
