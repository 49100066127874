import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { baseURL } from "@configs/envConfig";
// import { snackbarMessageUpdate } from "@store/config/configSlice";

const baseURL = process.env.REACT_APP_BASE_API_URL;
const baseQuery = fetchBaseQuery({
    baseUrl: baseURL,
    Credential: 'include'
    // prepareHeaders: (headers, { getState }) => {
    //   headers.set(
    //     "authorization",
    //     `Bearer ${getState()?.user?.token?.access_token}`
    //   );
    //   return headers;
    // },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    //   if (result?.error?.data?.status?.value) {
    //     api.dispatch(
    //       snackbarMessageUpdate({
    //         open: true,
    //         color: result.error?.data?.status?.severity
    //           ? result.error?.data?.status?.severity
    //           : "error",
    //         message: result.error?.data?.status?.value,
    //       })
    //     );
    //   } else if (result?.error?.status && result?.error?.status !== 401) {
    //     api.dispatch(
    //       snackbarMessageUpdate({
    //         open: true,
    //         color: "error",
    //         message: result?.error?.status,
    //       })
    //     );
    //   }
    //   if (
    //     result?.data?.error &&
    //     result?.data?.status?.value !== "Your request is successfully executed."
    //   ) {
    //     api.dispatch(
    //       snackbarMessageUpdate({
    //         open: true,
    //         color: result?.data?.status?.severity || "error",
    //         message: result?.data?.status?.value || result?.data?.message,
    //       })
    //     );
    //   } else if (
    //     result?.data?.status?.value === "Your request is successfully executed." &&
    //     result?.data?.data?.data?.length < 1
    //   ) {
    //     api.dispatch(
    //       snackbarMessageUpdate({
    //         open: true,
    //         color: "info",
    //         message: "No Data Found",
    //       })
    //     );
    //   }
    if (result?.error && result?.error?.status === 401) {
        const refreshToken = localStorage.getItem('refressToken');

        // const refreshResult = await baseQuery(
        //     {
        //         url: `${baseURL}/lds/public/refresh-token`,
        //         method: 'POST',
        //         body: {
        //             data: { refreshToken: refreshToken }
        //         },
        //         headers: {}
        //     },
        //     api,
        //     extraOptions
        // );

        // if (refreshResult.data) {
        //     localStorage.setItem('accessToken', accessToken);
        //     if (refreshResult.data?.error) {
        //         localStorage.clear();
        //         window.location.href = '/login';
        //     } else {
        //         localStorage.setItem('user', JSON.stringify(token));
        //         args.headers.authorization = `Bearer ${token?.token?.access_token}`;
        //         result = await baseQuery(args, api, extraOptions);
        //     }
        // } else if (refreshResult.error) {
        //     localStorage.clear();
        //     window.location.href = '/login';
        // }
    }
    return result;
};

const userInfo = (data) => {
    // const user = JSON.parse(localStorage.getItem('userInfo'));
    // if (data) {
    //     const result = {
    //         ...data,
    //         user: {
    //             id: user?.id,
    //             name: user?.name
    //         }
    //     };
    //     return result;
    // } else {
    return data;
    // }
};
export const apiQueryServices = createApi({
    reducerPath: 'apiQueryServices',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getApiCall: builder.query({
            query: (arg) => ({
                url: arg.endPoint,
                method: arg.method,
                body: userInfo(arg.data),
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    ...arg.headers
                },
                validateStatus: (response, result) => {
                    if (response.status) {
                    } else result = null;
                    return result;
                }
            })
        }),
        apiCall: builder.mutation({
            query: (arg) => ({
                url: arg.endPoint,
                method: arg.method,
                body: userInfo(arg.data),
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                    ...arg.headers
                }
            })
        })
    })
});

export const { useGetApiCallQuery, useApiCallMutation } = apiQueryServices;
