import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, Button, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const DialogContainer = styled(Dialog)(({ theme }) => ({
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5)
}));

const TitleContainer = styled(DialogTitle)({
    textAlign: 'center'
});

const ContentContainer = styled(DialogContent)({
    textAlign: 'center'
});

const ActionsContainer = styled(DialogActions)({
    justifyContent: 'center'
});

export default function ConfirmDialog(props) {
    const { loading } = props;
    const { confirmDialog, setConfirmDialog } = props;

    return (
        <DialogContainer open={confirmDialog.isOpen}>
            <TitleContainer></TitleContainer>
            <ContentContainer>
                <Typography variant="h6">{confirmDialog.title}</Typography>
                <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
            </ContentContainer>
            <ActionsContainer>
                <Button variant="contained" color="primary" style={{ color: '#fff' }} disabled={loading} onClick={confirmDialog.onConfirm}>
                    {loading && <CircularProgress size={20} style={{ color: '#000', marginRight: '5px' }} />}
                    {loading && (
                        <Box component="span" style={{ color: '#000' }}>
                            {' '}
                            please wait
                        </Box>
                    )}
                    {!loading && <Box>Yes</Box>}
                </Button>
                <Button variant="contained" onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}>
                    No
                </Button>
            </ActionsContainer>
        </DialogContainer>
    );
}
