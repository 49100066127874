import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allRepo: []
    // selectedAllLeads: null
};
export const userRepoSlice = createSlice({
    name: 'userRepo',
    initialState,
    reducers: {
        getRepo: (state, action) => {
            state.allRepo = action.payload;
        }
        // getSelectedAllLeads: (state, action) => {
        //     state.selectedAllLeads = action.payload;
        // }
    }
});

export const userRepoActions = userRepoSlice.actions;
