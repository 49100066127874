import React, { useRef } from 'react';
import {
    useState,
    Box,
    Grid,
    Button,
    moment,
    useTheme,
    AddOutlined,
    Typography,
    Divider,
    LoadingButton,
    PageHeader,
    Filters,
    useEffect,
    Autocomplete,
    TextField,
    useFormik,
    CommonAccordion,
    CommonChart,
    TablePagination,
    CircularLoadingProgress,
    apiEndPointsConfig,
    Chip,
    CheckBoxOutlineBlankIcon,
    CheckBoxIcon,
    Checkbox,
    createAgeRanges,
    createSalaryRanges,
    createLoanRanges,
    Yup,
    useDispatch,
    CommonDateRangePicker,
    CheckboxGroup,
    DateLeads,
    CommonDrawer,
    useSelector,
    staticRanges
} from '../../collections/Imports';
import { getDSAList, getDSAReport } from 'helpers/credmudra/dsa';
import { dsaReportSliceAction } from 'store/dsa-report/dsaReportSlice';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const DSAReport = () => {
    const childRef = useRef();
    const dispatch = useDispatch();
    const { allDSAList, dsaFilters, dsaReportTableData } = useSelector((state) => state.dsaReport);
    const [gridRef, setGridRef] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [controller, setController] = useState({
        page: 0,
        rowsPerPage: 1000
    });
    const [filterDSA, setFilterDSA] = useState([{ id: 'All', dsaId: 'All' }]);
    const [showAllChips, setShowAllChips] = useState(false);

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [selectedFilter, setSelectedFilter] = useState({
        startDate: new Date(),
        endDate: new Date()
    });
    const [isDateOpen, setIsDateOpen] = useState(false);
    const [isDSAOpen, setIsDSAOpen] = useState(false);

    useEffect(() => {
        getDSAlistOptions();
        if (Object.keys(dsaFilters).length) {
            const data = {
                fromDate: moment(dsaFilters.startDate).startOf('day').toISOString(),
                toDate: moment(dsaFilters?.endDate).endOf('day').toISOString(),
                dsaId: dsaFilters.dsaId
            };
            getDSAReportData(data);
        } else {
            getDSAReportData();
        }
    }, []);

    const getDSAlistOptions = async () => {
        const result = await getDSAList();
        if (result.data.length) {
            dispatch(dsaReportSliceAction.setAllDSAList([{ id: 'All', dsaId: 'All' }, ...result.data]));
        }
        console.log(result);
    };
    const getDSAReportData = async (data) => {
        const payload = {
            fromDate: data ? data.fromDate : moment().startOf('day').toISOString(),
            toDate: data ? data.toDate : moment().endOf('day').toISOString(),
            dsaIds: data ? data.dsaId.map((res) => res.dsaId) : ['All']
        };
        const result = await getDSAReport(payload);

        if (result?.data?.data?.length) {
            const customHeaders = [...result?.data?.headers];
            const dateIndex = customHeaders.findIndex((res) => res.field === 'date');
            customHeaders[dateIndex].pinned = 'left';
            const tabledata = {
                data: result?.data?.data,
                headers: customHeaders
            };
            dispatch(dsaReportSliceAction.setDSAReportTableData(tabledata));
        } else {
            dispatch(
                dsaReportSliceAction.setDSAReportTableData({
                    data: '',
                    headers: ''
                })
            );
        }
    };

    const inititalValues = {
        startDate: moment().startOf('day').toISOString(),
        endDate: moment().endOf('day').toISOString(),
        dsaId: [{ id: 'All', dsaId: 'All' }]
    };
    const validationSchema = Yup.object().shape({
        dsaId: Yup.array().min(1, 'Please select at least one DSA').required('required')
    });

    const handleSubmit = (values) => {
        dispatch(dsaReportSliceAction.setDSAFilters(values));
        setFilterDSA(values.dsaId);
        setSelectedFilter(dateRange[0]);
        getDSAReportData({
            fromDate: moment(values.startDate).startOf('day').toISOString(),
            toDate: moment(values.endDate).endOf('day').toISOString(),
            dsaId: values.dsaId
        });
        setIsOpen((prev) => !prev);
    };
    const formik = useFormik({
        initialValues: inititalValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });

    const toggleDrawer = (ele) => {
        ele === 'date' ? setIsDateOpen(true) : setIsDateOpen(false);
        ele === 'dsa' ? setIsDSAOpen(true) : setIsDSAOpen(false);
        setIsOpen((prev) => !prev);
    };
    const handleDateRangeChange = async (item) => {
        if (item && item.selection) {
            setDateRange([item.selection]);
            formik.setFieldValue('startDate', item.selection.startDate);
            formik.setFieldValue('endDate', item.selection.endDate);
        }
    };

    const handleClearFilter = () => {
        formik.resetForm();
        dispatch(dsaReportSliceAction.setDSAFilters({}));
        setDateRange([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ]);
        setSelectedFilter({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        });
        setFilterDSA([{ id: 'All', dsaId: 'All' }]);
        getDSAReportData();
    };
    const handleToggleFullscreen = () => {
        const element = childRef.current;
        if (!isFullscreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
                setIsFullscreen(true);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullscreen(false);
            }
        }
    };
    const onRefresh = () => {
        if (Object.keys(dsaFilters).length) {
            const data = {
                fromDate: moment(dsaFilters.startDate).startOf('day').toISOString(),
                toDate: moment(dsaFilters?.endDate).endOf('day').toISOString(),
                dsaId: dsaFilters.dsaId
            };
            getDSAReportData(data);
        } else {
            getDSAReportData();
        }
    };

    return (
        <>
            <CommonDrawer open={isOpen} onClose={toggleDrawer}>
                <Box minWidth="500px" maxWidth="500px">
                    <Box className="filter-header">
                        <Typography className="filter-title">Filters</Typography>
                        <Button onClick={handleClearFilter} variant="text" className="clear-filter">
                            Clear all
                        </Button>
                    </Box>
                    <Divider />
                    <form onSubmit={formik.handleSubmit}>
                        <Box mt={2}>
                            <CommonAccordion defaultExpanded={isDSAOpen} title={"DSA's"} count={filterDSA.length}>
                                <Autocomplete
                                    size="small"
                                    te
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={allDSAList || []}
                                    name="dsaId"
                                    value={formik.values.dsaId}
                                    onChange={(event, value) => {
                                        let newValue = [];

                                        if (value.some((option) => option.dsaId === 'All')) {
                                            newValue = [allDSAList[0]]; // Select only the 'All' option
                                        } else if (value.length === allDSAList.length - 1) {
                                            newValue = [allDSAList[0]]; // Select 'All' when all other options are selected
                                        } else {
                                            newValue = value.filter((option) => option.dsaId !== 'All'); // Remove 'All' if other options are selected
                                        }

                                        formik.setFieldValue('dsaId', newValue);
                                    }}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option?.dsaId?.charAt(0).toUpperCase() + option?.dsaId?.slice(1)}
                                    getOptionDisabled={(option) =>
                                        option?.dsaId === 'All' ? false : formik?.values?.dsaId?.some((option) => option?.dsaId === 'All')
                                    }
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={
                                                    selected ||
                                                    (option.dsaId === 'All' && formik.values.dsaId.some((option) => option.dsaId === 'All'))
                                                }
                                            />
                                            {option.dsaId}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            size="small"
                                            error={formik.touched.dsaId && formik.errors.dsaId}
                                            helperText={formik.touched.dsaId && formik.errors.dsaId}
                                            {...params}
                                            placeholder={formik.values.dsaId.length ? '' : 'Select DSA'}
                                        />
                                    )}
                                />
                            </CommonAccordion>

                            <CommonAccordion defaultExpanded={isDateOpen} title={'Date'}>
                                <CommonDateRangePicker
                                    staticRanges={staticRanges}
                                    maxDate={new Date()}
                                    ranges={dateRange}
                                    onChange={handleDateRangeChange}
                                />
                            </CommonAccordion>
                            <Divider />
                            <Grid container justifyContent="flex-end" align-items="center">
                                <Grid item sm={3} xs={4} mt={2}>
                                    <LoadingButton loading={false} type="submit" variant="contained" className="apply-button">
                                        Apply Filter
                                    </LoadingButton>
                                </Grid>
                                <Grid item sm={2} xs={4} mt={2}>
                                    <Button variant="outlined" onClick={toggleDrawer} className="cancel-button">
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Box>
            </CommonDrawer>
            <Box sx={{ marginTop: { xs: '25px', md: '0px' }, marginLeft: { xs: '-10%', md: '0px' } }}>
                <PageHeader title={'DSA-Report'} helpText={'DSA Lead Analytics Report'} />
                <Box
                    p={4}
                    width={'100%'}
                    sx={{
                        '@media (max-width: 900px)': { marginLeft: '-20%', paddingLeft: 0, width: '110%' }
                    }}
                >
                    <Grid container spacing={2} pl={1.5}>
                        <Filters title={<DateLeads />} onClick={() => toggleDrawer('date')}>
                            <Box>
                                <Chip label={moment(selectedFilter?.startDate).format('ll')} sx={{ m: '2px' }} size="small" /> -{' '}
                                <Chip label={moment(selectedFilter?.endDate).format('ll')} sx={{ m: '2px' }} size="small" />
                            </Box>
                        </Filters>
                        <Filters title="DSA :" onClick={() => toggleDrawer('dsa')}>
                            <Box>
                                {filterDSA.slice(0, 2).map((key) => (
                                    <span key={key}>
                                        <Chip label={key.dsaId} sx={{ m: '2px' }} size="small" />
                                    </span>
                                ))}
                                {filterDSA?.length > 2 && (
                                    <span style={{ cursor: 'pointer', color: '#000', paddingLeft: '4px' }}>
                                        {showAllChips ? 'Hide' : `+${filterDSA?.length - 2}`}
                                    </span>
                                )}
                            </Box>
                        </Filters>
                        <Filters onClick={toggleDrawer}>
                            <Button sx={{ color: 'gray', margin: 'auto 0', padding: 0 }} startIcon={<AddOutlined />}>
                                Filter
                            </Button>
                        </Filters>
                        <Filters>
                            <Button
                                onClick={handleClearFilter}
                                variant="text"
                                sx={{ textDecoration: 'underline', color: 'gray', margin: 'auto 0', width: '100%', p: 0 }}
                            >
                                Clear All
                            </Button>
                        </Filters>
                    </Grid>
                    <Box spacing={isFullscreen ? 0 : 2} ref={childRef} className={`child-component ${isFullscreen ? 'fullscreen' : ''}`}>
                        {true && (
                            <Box sx={{ width: { xs: '730px', md: '100%' } }}>
                                {false ? (
                                    <CircularLoadingProgress />
                                ) : (
                                    <CommonChart
                                        title={'DSA Report'}
                                        exportModuleName={'DSA Report'}
                                        onToggleFullscreen={handleToggleFullscreen}
                                        isFullscreen={isFullscreen}
                                        addActionIcon
                                        onRefresh={onRefresh}
                                        ref={gridRef}
                                        rowData={dsaReportTableData.data}
                                        columnDefs={dsaReportTableData.headers}
                                        isGridRequired={true}
                                    ></CommonChart>
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default DSAReport;
