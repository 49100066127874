import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    customerInfo: {},
    lenderStatus: []
};

export const customerInfoSlice = createSlice({
    name: 'customerInfo',
    initialState,
    reducers: {
        addCustomerInfo: (state, action) => {
            state.customerInfo = action.payload;
        },
        addLenderStatus: (state, action) => {
            state.lenderStatus = action.payload;
        },
        updateLenderStatus: (state, action) => {
            const index = state.lenderStatus.findIndex((res) => res.lenderId === action.payload.lenderId);
            state.lenderStatus[index].smsCount = state.lenderStatus[index].smsCount + 1;
        }
    }
});

export const customerInfoAction = customerInfoSlice.actions;
