import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    topPerfomerSource: []
};

export const topPerfomerSourceSlice = createSlice({
    name: 'topPerfomerSource',
    initialState,
    reducers: {
        getTopPerfomerSourceSuccess: (state, action) => {
            state.topPerfomerSource = action.payload;
        }
    }
});

export const topPerfomerSourceAction = topPerfomerSourceSlice.actions;
