import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    topPerfomerStatus: []
};

export const topPerfomerSlice = createSlice({
    name: 'topPerfomer',
    initialState,
    reducers: {
        getTopPerfomerSuccess: (state, action) => {
            state.topPerfomerStatus = action.payload;
        }
    }
});

export const topPerfomerAction = topPerfomerSlice.actions;
