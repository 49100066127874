import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allLeads: [],
    customerSelectedFilter: null
};

export const customerListSlice = createSlice({
    name: 'customerList',
    initialState,
    reducers: {
        getLeads: (state, action) => {
            state.allLeads = action.payload;
        },
        getSelectedCustomerList: (state, action) => {
            state.customerSelectedFilter = action.payload;
        }
    }
});

export const customerListAction = customerListSlice.actions;
