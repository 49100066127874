import ReactEcharts from 'echarts-for-react';
import { Grid, Typography, Box, CommonChart, useState, useRef, useEffect, CircularLoadingProgress } from '../../../collections/Imports';
import { useSelector } from 'react-redux';
import CommonPieChart from 'Components/ui-component/commonPieChart';

const AmountStatus = ({ onAmountRefresh, isSpLoading }) => {
    const { punchInKpi } = useSelector((state) => state.punchIn);
    const labels = punchInKpi?.map((item) => item.status);
    const values = punchInKpi?.map((item) => item.count);
    const temp = punchInKpi?.find((item) => item.status == 'total' && item.count); // Use the "total" count as the total number
    const total = temp?.count;
    const percentages = values.slice(1).map((value) => ((value / total) * 100).toFixed(2));
    const labelObj = labels.slice(1).map((label) => label?.charAt(0).toUpperCase() + label?.slice(1));

    const [isLoading, setIsLoading] = useState(true); // Added loading state

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 2000);
    }, []);

    // Full Screen
    const childRef = useRef();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const handleToggleFullscreen = () => {
        const element = childRef.current;
        if (!isFullscreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
                setIsFullscreen(true);
            }
        } else {
            onAmountRefresh();
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullscreen(false);
            }
        }
    };

    return (
        <Grid container spacing={2} className="child-component" sx={{ pl: '4px' }}>
            <Grid item sx={{ width: { xs: '740px', md: '100%' } }}>
                <Box
                    spacing={isFullscreen ? 0 : 2}
                    ref={childRef}
                    className={`child-component ${isFullscreen ? 'fullscreen' : ''}`}
                    sx={{ mt: 1 }}
                >
                    <CommonChart
                        title={'Status Percentage'}
                        onToggleFullscreen={handleToggleFullscreen}
                        isFullscreen={isFullscreen}
                        onRefresh={onAmountRefresh}
                    >
                        <Box p={3}>
                            {isSpLoading ? (
                                <Box sx={{ height: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <CircularLoadingProgress />
                                </Box>
                            ) : (
                                <>
                                    {total > 0 ? (
                                        <CommonPieChart
                                            title={''}
                                            data={{
                                                series: [
                                                    {
                                                        name: 'Punch In Percentage',
                                                        type: 'pie',
                                                        radius: '50%',
                                                        data: labelObj.map((label, index) => ({
                                                            value: parseFloat(percentages[index]),
                                                            name: label,
                                                            itemStyle: {
                                                                color: (() => {
                                                                    switch (label.toLowerCase()) {
                                                                        case 'duplicate':
                                                                            return '#3468eb';
                                                                        case 'accepted':
                                                                            return '#8dc572';
                                                                        case 'error':
                                                                            return '#ff0000';
                                                                        case 'rejected':
                                                                            return '#f8961a';
                                                                        default:
                                                                            return '#999999';
                                                                    }
                                                                })()
                                                            }
                                                        })),
                                                        emphasis: {
                                                            itemStyle: {
                                                                shadowBlur: 10,
                                                                shadowOffsetX: 0,
                                                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                                                            }
                                                        },
                                                        tooltip: {
                                                            trigger: 'item'
                                                        }
                                                    }
                                                ]
                                            }}
                                            height={isFullscreen ? '100vh' : 500}
                                        />
                                    ) : (
                                        <Box
                                            sx={{
                                                height: isFullscreen ? '100vh' : 500,
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                                No data available.
                                            </Typography>
                                        </Box>
                                    )}
                                </>
                            )}
                        </Box>
                    </CommonChart>
                </Box>
            </Grid>
        </Grid>
    );
};

export default AmountStatus;
