import apiEndPointsConfig from 'helpers/apiEndPointsConfig';
import apiService from 'helpers/apiService';

//API: This is get all the dsa list
export const getDsaService = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.GET_DSA, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const createDSAService = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.CREATE_DSA, { data: requestData });
        const responseData = response?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const updateDSAService = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.UPDATE_DSA, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const changePasswordService = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.CHANGE_PASSWORD, { data: requestData });
        const responseData = response?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const getDSAKpis = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.GETDSAKPIS, { data: requestData });
        const responseData = response?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const getDSALenderStatus = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.GETLEADPERSTATUS, { data: requestData });
        const responseData = response?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const getDSADisbursalReport = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.GetDSADisbursalReport, { data: requestData });
        const responseData = response?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const getTopDSALead = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.GETTOPDSALEAD, { data: requestData });
        const responseData = response?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const getBottomDSALead = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.GETBOTTOMDSALEAD, { data: requestData });
        const responseData = response?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const getDSAList = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.GetDSAList, {});
        const responseData = response?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const getDSAReport = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.GetDSAReport, { data: requestData });
        const responseData = response?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};
