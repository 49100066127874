// Note: This is common Component for leads list according to  status count || (lead-journey) - (leads-list-by-status-count) - index.js

import Grid from '@mui/system/Unstable_Grid/Grid.js';
import {
    useEffect,
    ArrowBack,
    useNavigate,
    useRef,
    useState,
    Box,
    CommonChart,
    useSelector,
    moment,
    useParams,
    PageHeader,
    CircularLoadingProgress,
    Tooltip,
    IconButton,
    Icon,
    mdiEyeArrowRightOutline,
    useCallback
} from '../../../collections/Imports.js';

const ListByStatusCount = () => {
    const childRef = useRef();
    const navigate = useNavigate();
    const [columnDefs, setColumnDefs] = useState([]);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const { GBStatusCount, GridName } = useSelector((state) => state.groupByLead);
    const [loading, setIsLoading] = useState(true);

    const params = useParams();

    const { id } = params;
    const capitalizedId = id.charAt(0).toUpperCase() + id.slice(1);

    const handleClick = useCallback(
        (data) => {
            navigate(`/leads/${data.leadId}`);
        },
        [navigate]
    );

    const actionButtons = useCallback(
        ({ data }) => {
            return (
                <span
                    style={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        justifyContent: 'center'
                    }}
                >
                    <Tooltip title="View Log">
                        <IconButton onClick={() => handleClick(data)}>
                            <Icon
                                color="secondary"
                                path={mdiEyeArrowRightOutline}
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                size={1}
                            />
                        </IconButton>
                    </Tooltip>
                </span>
            );
        },
        [handleClick]
    );

    useEffect(() => {
        setIsLoading(true);
        const timeout = setTimeout(() => {
            if (GBStatusCount && GBStatusCount?.userLeads?.length > 0) {
                const col = [...GBStatusCount?.headers].filter((res) => res?.field !== 'createdOn' && res.field !== 'marketPlaceLink');
                let newColumns = col;
                newColumns.push({
                    field: 'createdOn',
                    headerName: 'Created On',
                    textAlign: 'center',
                    minWidth: 200,
                    cellRenderer: ({ data }) => moment(data?.createdOn, 'DD-MM-YYYY hh:mm:ss A').utc().format('LLL')
                });

                newColumns.push({
                    field: 'action',
                    sortable: false,
                    headerName: 'Action',
                    pinned: 'right',
                    cellClass: 'my-class',
                    cellRenderer: ({ data }) => actionButtons({ data })
                });

                setColumnDefs(newColumns);
                setIsLoading(false);
            }
        }, 1000);

        // Clean up the timeout to prevent memory leaks
        return () => clearTimeout(timeout);
    }, [GBStatusCount]);

    const handleToggleFullscreen = () => {
        const element = childRef.current;
        if (!isFullscreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
                setIsFullscreen(true);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullscreen(false);
            }
        }
    };

    return (
        <Grid sx={{ marginLeft: { xs: '-12%', md: '0%' }, marginTop: { xs: '25px', md: '0' } }}>
            <PageHeader title={GridName} helpText={`${capitalizedId} Leads`} backIcon={<ArrowBack />} onBack={() => navigate(-1)} />
            <Box p={5} pt={2} marginLeft={'-23%'}>
                <Box
                    sx={{ marginTop: 2 }}
                    className={`child-component ${isFullscreen ? 'fullscreen' : ''}`}
                    spacing={isFullscreen ? 0 : 2}
                    ref={childRef}
                >
                    {loading ? (
                        <CircularLoadingProgress />
                    ) : (
                        <CommonChart
                            title={`${capitalizedId} Leads`}
                            exportModuleName={`${capitalizedId} Leads`}
                            isFullscreen={isFullscreen}
                            onToggleFullscreen={handleToggleFullscreen}
                            columnDefs={columnDefs}
                            rowData={GBStatusCount?.userLeads}
                            isGridRequired={true}
                        />
                    )}
                </Box>
            </Box>
        </Grid>
    );
};

export default ListByStatusCount;
