import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allLeads: [],
    groupByDateSelectedFilter: null,
    submitedLeads: [],
    marketPlaceClickData: [],
    marketPlaceImpressionData: [],
    marketPlaceElligible: [],
    marketPlaceDropOff: [],
    marketPlaceClickLenderData: [],
    mpClickSelectedFilterDate: null
};
export const groupByDateSlice = createSlice({
    name: 'groupByDate',
    initialState,
    reducers: {
        getAllLeads: (state, action) => {
            state.allLeads = action.payload;
        },
        getGBDateSelectedFilter: (state, action) => {
            state.groupByDateSelectedFilter = action.payload;
        },
        getSubmitedLeads: (state, action) => {
            state.submitedLeads = action.payload;
        },
        getMarketPlaceClickData: (state, action) => {
            state.marketPlaceClickData = action.payload;
        },
        getMarketPlaceImpressionData: (state, action) => {
            state.marketPlaceImpressionData = action.payload;
        },
        getMarketPlaceElligible: (state, action) => {
            state.marketPlaceElligible = action.payload;
        },
        getMarketPlaceDropOff: (state, action) => {
            state.marketPlaceDropOff = action.payload;
        },
        getMarketPlaceClickLenderData: (state, action) => {
            state.marketPlaceClickLenderData = action.payload;
        },
        getMpClickSelectedFilterDate: (state, action) => {
            state.mpClickSelectedFilterDate = action.payload;
        }
    }
});

export const groupByDateActions = groupByDateSlice.actions;
