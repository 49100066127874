import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dsaLiveData: []
};

export const DsaLiveSlice = createSlice({
    name: 'dasLive',
    initialState,
    reducers: {
        dasLiveData: (state, action) => {
            state.dsaLiveData = action.payload;
        }
    }
});

export const dasLiveActions = DsaLiveSlice.actions;
