import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    singleCustomerDetail: [],
    allLeads: [],
    loanApplicationTableData: [],
    allLoans: []
};

export const customerDetailSlice = createSlice({
    name: 'customerDetails',
    initialState,
    reducers: {
        getLeads: (state, action) => {
            state.allLeads = action.payload;
        },
        getloanApplication: (state, action) => {
            state.loanApplicationTableData = action.payload;
        },
        getSingleCustomerDetail: (state, action) => {
            state.singleCustomerDetail = action.payload;
        },
        getAppliedLoan: (state, action) => {
            state.allLoans = action.payload;
        }
    }
});

export const customerDetailAction = customerDetailSlice.actions;
