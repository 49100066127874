import { DataAnalyticsSvg } from 'Components/ui-component/svg-icons/commonSvgIcons';
import {
    Box,
    CalendarViewMonthOutlined,
    Divider,
    PageHeader,
    Tab,
    TabContext,
    TabList,
    TabPanel,
    useTheme
} from '../../collections/Imports';
import Analytics from './analytics';
import DataTable from './data-table';
import { useDispatch } from 'react-redux';
import { tabValuesPunchinSliceAction } from 'store/punchin/tabValuePunchinSlice';
import { useSelector } from 'react-redux';
import Grid from '@mui/system/Unstable_Grid/Grid';

function PunchinDashboard() {
    const { tabValuesPunchin } = useSelector((state) => state.tabValuesPunchin);
    const dispatch = useDispatch();

    const theme = useTheme();

    const handleChange = (event, newValue) => {
        dispatch(tabValuesPunchinSliceAction.getTabValuesPunchin(newValue));
    };

    const tabIconColorActive = '#97B7F9';
    const tabTextColorActive = '#3E7DFD';
    const tabIconColorInactive = '#525252';
    const tabTextColorInactive = '#525252';

    return (
        <Grid sx={{ marginTop: { xs: '30px', md: '0' }, marginLeft: { xs: '-10%', md: '0' } }}>
            <PageHeader title={'Punch In'} helpText={'Punch In Dashboard for tracking all the Punchc In informations'} />
            <TabContext value={tabValuesPunchin}>
                <Box pl={2} sx={{ '@media (max-width: 900px)': { marginLeft: '-22%', paddingLeft: 0, marginTop: '10px' } }}>
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        sx={{
                            p: 0,
                            m: 0,
                            height: '15px',
                            '& .MuiTabs-indicator': {
                                borderRadius: '4px 4px 0px 0px',
                                borderBottom: '3px solid #3E7DFD'
                            }
                        }}
                    >
                        <Tab
                            icon={<DataAnalyticsSvg color={tabValuesPunchin === 1 ? tabIconColorActive : tabIconColorInactive} />}
                            iconPosition="start"
                            label="Analytics"
                            value={1}
                            sx={{
                                color: tabValuesPunchin === 1 ? tabTextColorActive : tabTextColorInactive,
                                leadingTrim: 'both',
                                textEdge: 'cap',
                                fontFamily: 'Inter',
                                fontSize: { xs: '22px', md: '15px' },
                                fontStyle: 'normal',
                                fontWeight: tabValuesPunchin === 1 ? 600 : 400,
                                lineHeight: 'normal',
                                padding: 0,
                                margin: 0,
                                marginLeft: '15px',
                                marginTop: '-10px',
                                '& .Mui-selected': {
                                    borderBottom: '3px solid #3E7DFD',
                                    borderWidth: '2px',
                                    borderRadius: '4px 4px 0px 0px'
                                },
                                '& .MuiTabs-indicator': {
                                    borderRadius: '4px 4px 0px 0px',
                                    borderBottom: '3px solid #3E7DFD'
                                }
                            }}
                        />

                        <Tab
                            icon={
                                <CalendarViewMonthOutlined
                                    style={{ fill: tabValuesPunchin === 2 ? tabIconColorActive : tabIconColorInactive, marginRight: '4px' }}
                                />
                            }
                            iconPosition="start"
                            label="Data Grid"
                            value={2}
                            sx={{
                                color: tabValuesPunchin === 2 ? tabTextColorActive : tabTextColorInactive,
                                leadingTrim: 'both',
                                textEdge: 'cap',
                                fontFamily: 'Inter',
                                fontSize: { xs: '22px', md: '15px' },
                                fontStyle: 'normal',
                                fontWeight: tabValuesPunchin === 2 ? 600 : 400,
                                lineHeight: 'normal',
                                padding: 0,
                                margin: 0,
                                marginLeft: '15px',
                                marginTop: '-10px',
                                '&.Mui-selected': {
                                    borderBottom: '3px solid #3E7DFD',
                                    borderWidth: '2px',
                                    borderRadius: '4px 4px 0px 0px'
                                },
                                '& .MuiTabs-indicator': {
                                    borderRadius: '4px 4px 0px 0px',
                                    borderBottom: '3px solid #3E7DFD'
                                }
                            }}
                        />
                    </TabList>
                </Box>
                <Divider />
                <Box pl={0} pr={4} sx={{ '@media (max-width: 900px)': { marginLeft: '-25%', paddingLeft: 0 } }}>
                    <TabPanel value={1} dir={theme.direction} sx={{ paddingRight: '0' }}>
                        <Analytics />
                    </TabPanel>
                    <TabPanel value={2} dir={theme.direction} sx={{ paddingRight: '0' }}>
                        <DataTable />
                    </TabPanel>
                </Box>
            </TabContext>
        </Grid>
    );
}

export default PunchinDashboard;
