import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Box,
    List,
    ListItemButton,
    ListItemIcon,
    Typography,
    useNavigate,
    ListItem,
    ListItemText,
    Tooltip,
    CloseIcon,
    Button
} from '../../../collections/Imports';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import { eligibleLeadActions } from 'store/leads/eligibleLeadSlice';
import { useDispatch, useSelector } from 'react-redux';
import { punchinAction } from 'store/punchin/punchinSlice';
import { lendersKpiAction } from 'store/lenders/lendersKpiSlice';
import { marketPlaceActions } from 'store/lenders/marketPlaceTracker/marketPlaceSlice';
import { exitPageActions } from 'store/lenders/exitPage/exitPageSlice';
import { noneligibleLeadsAction } from 'store/leads/noneligibleLeadSlice';
import { dedupeLeadSliceAction } from 'store/leads/dedupeLeadSlice';
import { dropOffAction } from 'store/dropoff/dropOffSlice';
import Icon from '@mdi/react';
import { mdiBackburger } from '@mdi/js';

import { mdiChartBoxOutline, mdiViewDashboardVariantOutline } from '@mdi/js';

import './style.css';
import { dashboardFilterAction } from 'store/dashboard/dashboardSelectedFilter';
import {
    DashboardSvg,
    DropOffIconSvg,
    DsaSvg,
    LeadsSvg,
    LenderIconSvg,
    MarketPlaceIconSvg,
    MisIconSvg,
    PublisherIconSvg,
    PunchinIconSvg,
    ScheduleIconSvg
} from 'Components/ui-component/svg-icons/commonSvgIcons';

function SideBar({ openDrawer }) {
    const allNav = useSelector((state) => state?.userRepo?.allRepo);
    const dispatch = useDispatch();
    const [activeMenuItem, setActiveMenuItem] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const currentPath = location.pathname;

    const handleMenuItemClick = (to) => {
        setActiveMenuItem(to);

        dispatch(dashboardFilterAction.getSelectedFilter(null));
        dispatch(eligibleLeadActions.getSelectedAllLeads(null));
        dispatch(punchinAction.getSelectedPunchinFilter(null));
        dispatch(lendersKpiAction.getKpiSelectedFilter(null));
        dispatch(marketPlaceActions.getMpSelectedFilter(null));
        dispatch(exitPageActions.getExitPageSelected(null));
        dispatch(noneligibleLeadsAction.getSelectedNonEligible(null));
        dispatch(dedupeLeadSliceAction.getDedupeSelectedFilter(null));
        dispatch(dropOffAction.getSelectedDropOffFilter(null));
        dispatch(dropOffAction.getSelectedStageFilter(null));
        dispatch(dropOffAction.getSelectedDropOffTable(null));
    };

    const handlePopstate = () => {
        const activeIndex = allNav.findIndex((item) => item.to === currentPath);

        if (activeIndex !== -1) {
            setIsActive(true);
            setActiveMenuItem(currentPath);
        } else {
            setIsActive(false);
            setActiveMenuItem(allNav[0]?.to);
        }
    };

    useEffect(() => {
        handlePopstate();
        window.addEventListener('popstate', handlePopstate);
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, [location, allNav]);

    const dashboard = [];
    const clientManagement = [];
    const publisherManagement = [];
    const userManagement = [];
    const ReportsAndAnalytics = [];
    const Dsa = [];

    allNav.forEach((item) => {
        if (['Dashboard', 'Leads', 'DropOffs', 'MIS', 'Scheduler', 'PunchIn'].includes(item.name)) {
            dashboard.push({ item });
        } else if (['Lenders', 'MarketPlace', "Promotional-Offer"].includes(item.name)) {
            clientManagement.push({ item });
        } else if (['Publisher'].includes(item.name)) {
            publisherManagement.push({ item });
        } else if (['DSA', 'DSA-Dashboard', 'DSA-Report'].includes(item.name)) {
            Dsa.push({ item });
        } else if (['Settings'].includes(item.name)) {
            userManagement.push({ item });
        } else if (['Lead Journey'].includes(item.name)) {
            ReportsAndAnalytics.push({ item });
        }
    });
    // clientManagement.push({
    //     item: {
    //         name: 'Promotional Offer',
    //         to: '/promotional-offer',
    //         icon: 'LocalMallOutlinedIcon' // You can use any other icon if you have one
    //     }
    // });

    const textActiveStyle = {
        color: '#fff !important',
        fontSize: '10px',
        '& .MuiListItemText-primary': {
            color: '#fff !important'
        }
    };
    const textSimpleStyle = {
        color: '#000',
        fontSize: '10px'
    };

    const activeStyle = {
        width: '233px',
        height: '40px',
        margin: '0px 5px 5px 5px',
        background: '#2A4BC0',
        color: 'white',
        gap: '0px',
        borderRadius: '5px'
    };

    const simpleStyle = {
        minWidth: 0,
        width: '233px',
        height: '40px',
        margin: '0px 5px 5px 5px',
        gap: '0px',
        background: '#fff',
        color: '#000',
        borderRadius: '0px'
    };

    const isClientManagementNotEmpty = clientManagement.length > 0;
    const isPublisherManagementNotEmpty = publisherManagement.length > 0;
    const isReportsAndAnalyticsNotEmpty = ReportsAndAnalytics.length > 0;
    const isUserManagementNotEmpty = userManagement.length > 0;
    const isDsaNotEmpty = Dsa.length > 0;

    const DefaultIcon = ({ iconName, isActive }) => {
        switch (iconName) {
            case 'DashboardIcon':
                return <DashboardSvg color={isActive} />;
            case 'MisIcon':
                return <MisIconSvg color={isActive} />;
            case 'DropOffIcon':
                return <DropOffIconSvg color={isActive} />;
            case 'LeadsIcon':
                return <LeadsSvg color={isActive} />;
            case 'ScheduleIcon':
                return <ScheduleIconSvg color={isActive} />;
            case 'PunchinIcon':
                return <PunchinIconSvg color={isActive} />;
            case 'PublisherIcon':
                return <PublisherIconSvg color={isActive} />;
            case 'LenderIcon':
                return <LenderIconSvg color={isActive} />;
            case 'MarketPlaceIcon':
                return <MarketPlaceIconSvg color={isActive} />;
            case 'DsaIcon':
                return <DsaSvg color={isActive} />;
            case 'DsaReportIcon':
                return <Icon path={mdiChartBoxOutline} size={1} />;
            case 'DSADashboardIcons':
                return <Icon path={mdiViewDashboardVariantOutline} size={1} />;
                case 'LocalOfferOutlinedIcon':
                    return <LocalOfferOutlinedIcon sx={{ color: isActive }} />;
                
            default:
                return null;
        }
    };

    return (
        <Box
            sx={{
                overflow: 'hidden',
                mt: '4rem',
                backgroundColor: '#fff',
                minWidth: '100%'
            }}
        >
            <List>
                {window.innerWidth <= 900 && (
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button onClick={openDrawer}>
                            <Icon path={mdiBackburger} size={1} />
                        </Button>
                    </Box>
                )}
            </List>
            <List>
                {dashboard.map((text, index) => (
                    <ListItem key={text?.item?.name} disablePadding>
                        <ListItemButton
                            // sx={isActive && text?.item?.to === activeMenuItem ? activeStyle : simpleStyle}
                            sx={currentPath.includes(text?.item?.to) ? activeStyle : simpleStyle}
                            onClick={() => {
                                handleMenuItemClick(text?.item?.to);
                                navigate(text?.item?.to);
                            }}
                        >
                            <Tooltip title={text?.item?.name} arrow>
                                <ListItemIcon className="sideNavIcon">
                                    {text.item?.icon && (
                                        <ListItemIcon className="sideNavIcon">
                                            <DefaultIcon
                                                iconName={text.item?.icon}
                                                isActive={isActive && text?.item?.to === activeMenuItem ? '#fff' : '#000'}
                                            />
                                        </ListItemIcon>
                                    )}
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText
                                primary={text?.item?.name}
                                sx={currentPath.includes(text?.item?.to) ? textActiveStyle : textSimpleStyle}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            {isClientManagementNotEmpty && (
                <>
                    <li style={{ listStyleType: 'none' }}>
                        <Typography sx={{ mt: 0.5, ml: 2, display: { xs: 'none', md: 'block' } }} color="text.secondary" variant="caption">
                            Client Management
                        </Typography>
                        <Typography sx={{ mt: 0.5, ml: 2, display: { xs: 'block', md: 'none' } }} color="text.secondary" variant="caption">
                            C-M
                        </Typography>
                    </li>
                    <List>
                        {clientManagement.map((text, index) => (
                            <ListItem
                                key={text?.item?.name}
                                disablePadding
                                className="sideMenuItem"
                                onClick={() => navigate(text?.item?.to)}
                            >
                                <Tooltip title={text?.item?.name} arrow>
                                    <ListItemButton
                                        sx={isActive && text?.item?.to === activeMenuItem ? activeStyle : simpleStyle}
                                        onClick={() => {
                                            handleMenuItemClick(text?.item?.to);
                                            navigate(text?.item?.to);
                                        }}
                                    >
                                        <Tooltip title={text?.item?.name} arrow>
                                            <ListItemIcon className="sideNavIcon">
                                                {text.item?.icon && (
                                                    <ListItemIcon className="sideNavIcon">
                                                        <DefaultIcon
                                                            iconName={text.item?.icon}
                                                            isActive={isActive && text?.item?.to === activeMenuItem ? '#fff' : '#000'}
                                                        />
                                                    </ListItemIcon>
                                                )}
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText
                                            primary={text?.item?.name}
                                            sx={isActive && text?.item?.to === activeMenuItem ? textActiveStyle : textSimpleStyle}
                                        />
                                    </ListItemButton>
                                </Tooltip>
                            </ListItem>
                        ))}
                    </List>
                </>
            )}

            {isPublisherManagementNotEmpty && (
                <>
                    <li style={{ listStyleType: 'none' }}>
                        <Typography sx={{ mt: 0.5, ml: 2, display: { xs: 'none', md: 'block' } }} color="text.secondary" variant="caption">
                            Publisher Management
                        </Typography>
                        <Typography sx={{ mt: 0.5, ml: 2, display: { xs: 'block', md: 'none' } }} color="text.secondary" variant="caption">
                            P-M
                        </Typography>
                    </li>
                    <List>
                        {publisherManagement.map((text, index) => (
                            <ListItem
                                key={text?.item?.name}
                                disablePadding
                                className="sideMenuItem"
                                onClick={() => navigate(text?.item?.to)}
                            >
                                <ListItemButton
                                    sx={currentPath.includes(text?.item?.to) ? activeStyle : simpleStyle}
                                    onClick={() => {
                                        handleMenuItemClick(text?.item?.to);
                                        navigate(text?.item?.to);
                                    }}
                                >
                                    <ListItemIcon className="sideNavIcon">
                                        {text.item?.icon && (
                                            <ListItemIcon className="sideNavIcon">
                                                <DefaultIcon
                                                    iconName={text.item?.icon}
                                                    isActive={isActive && text?.item?.to === activeMenuItem ? '#fff' : '#000'}
                                                />
                                            </ListItemIcon>
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={text?.item?.name}
                                        sx={currentPath.includes(text?.item?.to) ? textActiveStyle : textSimpleStyle}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </>
            )}

            {isReportsAndAnalyticsNotEmpty && (
                <>
                    <li style={{ listStyleType: 'none' }}>
                        <Typography sx={{ mt: 0.5, ml: 2, display: { xs: 'none', md: 'block' } }} color="text.secondary" variant="caption">
                            Reports & Analytics
                        </Typography>
                        <Typography sx={{ mt: 0.5, ml: 2, display: { xs: 'block', md: 'none' } }} color="text.secondary" variant="caption">
                            R-A
                        </Typography>
                    </li>

                    <List>
                        {ReportsAndAnalytics.map((text, index) => (
                            <ListItem
                                key={text?.item?.name}
                                disablePadding
                                className="sideMenuItem"
                                onClick={() => navigate(text?.item?.to)}
                            >
                                <ListItemButton
                                    sx={currentPath.includes(text?.item?.to) ? activeStyle : simpleStyle}
                                    onClick={() => {
                                        handleMenuItemClick(text?.item?.to);
                                        navigate(text?.item?.to);
                                    }}
                                >
                                    <ListItemIcon className="sideNavIcon">
                                        {text.item?.icon &&
                                            (text.item?.icon === 'LeadJourneyIcon' ? (
                                                <LocalMallOutlinedIcon
                                                    sx={{
                                                        color: currentPath.includes(text?.item?.to) ? '#ffffff' : '#000'
                                                    }}
                                                />
                                            ) : null)}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={text?.item?.name}
                                        sx={currentPath.includes(text?.item?.to) ? textActiveStyle : textSimpleStyle}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </>
            )}

            {isDsaNotEmpty && (
                <>
                    <li style={{ listStyleType: 'none' }}>
                        <Typography sx={{ mt: 0.5, ml: 2, display: { xs: 'none', md: 'block' } }} color="text.secondary" variant="caption">
                            DSA
                        </Typography>
                        <Typography sx={{ mt: 0.5, ml: 2, display: { xs: 'block', md: 'none' } }} color="text.secondary" variant="caption">
                            DSA
                        </Typography>
                    </li>
                    <List>
                        {Dsa.map((text, index) => (
                            <ListItem
                                key={text?.item?.name}
                                disablePadding
                                className="sideMenuItem"
                                onClick={() => navigate(text?.item?.to)}
                            >
                                <ListItemButton
                                    sx={currentPath.includes(text?.item?.to) ? activeStyle : simpleStyle}
                                    onClick={() => {
                                        handleMenuItemClick(text?.item?.to);
                                        navigate(text?.item?.to);
                                    }}
                                >
                                    <ListItemIcon className="sideNavIcon">
                                        {text.item?.icon && (
                                            <ListItemIcon className="sideNavIcon">
                                                <DefaultIcon
                                                    iconName={text.item?.icon}
                                                    isActive={isActive && text?.item?.to === activeMenuItem ? '#fff' : '#000'}
                                                />
                                            </ListItemIcon>
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={text?.item?.name}
                                        sx={currentPath.includes(text?.item?.to) ? textActiveStyle : textSimpleStyle}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </>
            )}

            {isUserManagementNotEmpty && (
                <>
                    <li style={{ listStyleType: 'none' }}>
                        <Typography sx={{ mt: 0.5, ml: 2, display: { xs: 'none', md: 'block' } }} color="text.secondary" variant="caption">
                            User Management
                        </Typography>
                        <Typography sx={{ mt: 0.5, ml: 2, display: { xs: 'block', md: 'none' } }} color="text.secondary" variant="caption">
                            U-M
                        </Typography>
                    </li>

                    <List>
                        {userManagement.map((text, index) => (
                            <ListItem
                                key={text?.item?.name}
                                disablePadding
                                className="sideMenuItem"
                                onClick={() => navigate(text?.item?.to)}
                            >
                                <ListItemButton
                                    sx={currentPath.includes(text?.item?.to) ? activeStyle : simpleStyle}
                                    onClick={() => {
                                        handleMenuItemClick(text?.item?.to);
                                        navigate(text?.item?.to);
                                    }}
                                >
                                    <ListItemIcon className="sideNavIcon">
                                        {text.item?.icon &&
                                            (text.item?.icon === 'SettingIcon' ? (
                                                <AdminPanelSettingsOutlinedIcon
                                                    sx={{
                                                        color: currentPath.includes(text?.item?.to) ? '#fff' : '#000'
                                                    }}
                                                />
                                            ) : null)}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={text?.item?.name}
                                        sx={currentPath.includes(text?.item?.to) ? textActiveStyle : textSimpleStyle}
                                    />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </>
            )}
        </Box>
    );
}

export default SideBar;
