import { configureStore } from '@reduxjs/toolkit';
import { eligibleLeadSlice } from './leads/eligibleLeadSlice';
import { noneligibleLeadSlice } from './leads/noneligibleLeadSlice';
import { customerListSlice } from './leads/customerListSlice';
import { dedupeLeadsSlice } from './leads/dedupeLeadSlice';
import { leadLogSlice } from './leads/leadLogSlice';
import { leadLoadDetailSlice } from './leads/leadLogDetailsSlice';
import { customerDetailSlice } from './leads/customerDetailsSlice';
import { customerInfoSlice } from './leads/customerInfoSlice';
import { crmStatusSlice } from './crm/crmStatusSlice';
/**------------DASHBOARD----------------------------------- */
import { kpiSlice } from './dashboard/kpiSlice';
import { lenderLeadSlice } from './dashboard/lenderLeadSlice';
import { topPerfomerSlice } from './dashboard/topPerfomerSlice';
import { topPerfomerSourceSlice } from './dashboard/topPerfomingSourceSlice';
import { peakTimeSlice } from './dashboard/peakTimeSlice';
import { MisSlice } from './mis/MisSlice';
import { SchedulerSlice } from './Scheduler/SchedulerSlice';

// import { PlaypauseSlice } from './Scheduler/PlaypauseSlice';
import { lenderKpiSlice } from './lenders/lendersKpiSlice';
import { lenderStatsSlice } from './lenders/lenderStatsSplice';
import { lenderTimeResponseSlice } from './lenders/lendersTimeResponseSlice';
import { settingSlice } from './settings/settingSlice';
import { configurationSlice } from './lenders/configuration/configurationSlice';
import { exitPageSlice } from './lenders/exitPage/exitPageSlice';
import { exitPageDetailsSlice } from './lenders/exitPage/exitPageDetailsSlice';
import { dropOffSlice } from './dropoff/dropOffSlice';
import { punchinSlice } from './punchin/punchinSlice';
import { punchinDataTableSlice } from './punchin/punchinDataTable';
import { marketPlaceSlice } from './lenders/marketPlaceTracker/marketPlaceSlice';
import { userRepoSlice } from './userRepo/UserRepo';
import { permissionsSlice } from './permissionsState';
import { dashboardSelectedFilter } from './dashboard/dashboardSelectedFilter';
import { ImpressionPageDetailsSlice } from './lenders/impressionDetail/impressionDetailSlice';
import { clickCountPageDetailsSlice } from './lenders/clickCountDetail/clickCountDetailSlice';

import { googleExitPageSlice } from './leads/googleExitPageSlice';
import { MarketPlaceTreeSlice } from './market-place/MarketPlaceTreeSlice';
import { groupByPublisherSlice } from './market-place-analytics/groupByPublisher';

//Market-place-analytics
import { groupByDateSlice } from './market-place-analytics/groupByDateSlice';
import { lenderTrendReportSlice } from './lenders/trendReport/trendReportSlice';

import { GroupByLeadSlice } from './lead-journey/GroupByLeadSlice';
import { DsaSchedulerSlice } from './dsa/dsaScheduleSlice';

import { DsaLiveSlice } from './dsa/dsaLive/dsaLive';

import { dsaDashboardSlice } from './dsa-dashboard/dsaDashboardSlice';
import { dsaReportSlice } from './dsa-report/dsaReportSlice';
import { apiQueryServices } from 'helpers/apiServiceQuery';
import { tabValuesSlice } from './leads/tabHandlerSlice';
import { tabValuesDSASlice } from './dsa/tabValueDsaSlice';
import { tabValuesPunchinSlice } from './punchin/tabValuePunchinSlice';
import { tabValuesLendersSlice } from './lenders/tabValueLenders';
import { tabValuesDropOffSlice } from './dropoff/tabValueDropOffSlice';
import { creditPolicySlice } from './lenders/creditPolicySlice';
import { OfferSlice } from './offers/offerSlice';
/*-----------------------------------------------------------*/
const middlewares = [];

if (process.env.NODE_ENV === 'development') {
    const { createLogger } = require(`redux-logger`);
    const logger = createLogger({
        collapsed: (getState, action, logEntry) => !logEntry.error
    });

    middlewares.push(logger);
    middlewares.push(apiQueryServices.middleware);
}

/*-----------------------------------------------------------*/
const ldsStore = configureStore({
    reducer: {
        eligibleLeads: eligibleLeadSlice.reducer,
        noneligibleLead: noneligibleLeadSlice.reducer,
        customerList: customerListSlice.reducer,
        dedupeLeads: dedupeLeadsSlice.reducer,
        googleExitPage: googleExitPageSlice.reducer,
        leadLog: leadLogSlice.reducer,
        leadLogDetails: leadLoadDetailSlice.reducer,
        customerDetails: customerDetailSlice.reducer,
        customerInfo: customerInfoSlice.reducer,
        crmStatus: crmStatusSlice.reducer,
        /**-------------DASHBOARD REDUCERS--------- */
        kpiLead: kpiSlice.reducer,
        dashboardFilters: dashboardSelectedFilter.reducer,
        lenderLead: lenderLeadSlice.reducer,
        topPerfomer: topPerfomerSlice.reducer,
        topPerfomerSource: topPerfomerSourceSlice.reducer,
        peakTime: peakTimeSlice.reducer,
        /**--------------LENDERS REDUCERS------------------------- */
        lendersKpi: lenderKpiSlice.reducer,
        lenderStats: lenderStatsSlice.reducer,
        creditPolicy: creditPolicySlice.reducer,
        lenderTimeResponse: lenderTimeResponseSlice.reducer,
        configurationStats: configurationSlice.reducer,
        exitPage: exitPageSlice.reducer,
        marketPlace: marketPlaceSlice.reducer,
        exitPageDetail: exitPageDetailsSlice.reducer,
        impressionDetails: ImpressionPageDetailsSlice.reducer,
        clickCountDetails: clickCountPageDetailsSlice.reducer,
        lenderTrendReport: lenderTrendReportSlice.reducer,
        /**--------------DROP OFF REDUCERS-------------------------- */
        dropOff: dropOffSlice.reducer,
        /**--------------PunchIn Reducer----------------------------- */
        punchIn: punchinSlice.reducer,
        punchInTable: punchinDataTableSlice.reducer,
        /**---------------------------------------------------------- */
        mis: MisSlice.reducer,
        scheduler: SchedulerSlice.reducer,
        /** -------- */
        settings: settingSlice.reducer,
        /** -------- */
        userRepo: userRepoSlice.reducer,
        permissionsSlice: permissionsSlice.reducer,

        /*--------------------------------------------*/

        MarketPlaceTree: MarketPlaceTreeSlice.reducer,
        // ------market-place-analytics reducers
        groupByDate: groupByDateSlice.reducer,
        groupByPublisher: groupByPublisherSlice.reducer,

        //-----DAS----//
        dsaLiveData: DsaLiveSlice.reducer,
        dsaDashboard: dsaDashboardSlice.reducer,
        dsaReport: dsaReportSlice.reducer,
        //-----offers-----//
        offers: OfferSlice.reducer,

        // lead-journey
        groupByLead: GroupByLeadSlice.reducer,
        dsaSchedule: DsaSchedulerSlice.reducer,
        dsaDashboard: dsaDashboardSlice.reducer,
        dsaReport: dsaReportSlice.reducer,

        //tabValue
        tabValues: tabValuesSlice.reducer,
        tabValuesDSA: tabValuesDSASlice.reducer,
        tabValuesPunchin: tabValuesPunchinSlice.reducer,
        tabValuesLenders: tabValuesLendersSlice.reducer,
        tabValuesDropOff: tabValuesDropOffSlice.reducer,

        [apiQueryServices.reducerPath]: apiQueryServices.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false
        }).concat(middlewares),
    devTools: true
});
export default ldsStore;
