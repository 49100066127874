import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    Box,
    CommonChart,
    Grid,
    PageHeader,
    plus,
    Typography,
    Stack,
    TextField,
    useFormik,
    Yup,
    LoadingButton,
    Button,
    useSnackbar,
    moment,
    Icon,
    CommonDrawer,
    useSelector,
    ConfirmDialog,
    LogoImg,
    LoadingImg,
    apiEndPointsConfig,
    useDispatch,
    useNavigate
} from '../../collections/Imports';
import { DialogTitle, DialogContent, DialogActions, TablePagination, Tooltip, IconButton, InputAdornment, Switch } from '@mui/material';
import { Divider } from '@mui/material';
import 'moment-timezone';

import Modal from '@mui/material/Modal';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import { styled } from '@mui/system';
import { CloseIcon } from 'collections/Imports';
import { mdiLockOutline, mdiPencil, mdiTextBoxSearchOutline, mdiCogOutline } from '@mdi/js';
import { changePasswordService, createDSAService, getDsaService, updateDSAService } from 'helpers/credmudra/dsa';
import apiService from 'helpers/apiService';
import { dasLiveActions } from 'store/dsa/dsaLive/dsaLive';

// import { Visibility, VisibilityOff } from '@mui/icons-material';

const TitleContainer = styled(DialogTitle)({
    textAlign: 'center'
});

const ContentContainer = styled(DialogContent)({
    textAlign: 'center'
});

const ActionsContainer = styled(DialogActions)({
    justifyContent: 'center'
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    backgroundColor: 'background.paper',
    borderRadius: '5px',
    boxShadow: 20,
    p: 0
};

const DsaMasterPage = () => {
    const childRef = useRef();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const modulePermissions = useSelector((state) => state.permissionsSlice.moduleVisePermissions.find((res) => res.route === '/dsa'));
    const dataLive = useSelector((state) => state?.dsaLiveData);

    // console.log('--0-000-0-0-0-00-0', dataLive);

    const [isFullscreen, setIsFullscreen] = useState(false);

    const [openDrawer, setOpenDrawer] = useState(false);
    const [PopupData, setPopupData] = useState();
    const [data, setData] = useState();
    const [columnDef, setColumnDef] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isEmailCopied, setIsEmailCopied] = useState(false);
    const [isPasswordCopied, setIsPasswordCopied] = useState(false);

    const [isDsaId, setDsaId] = useState('');
    const [isEditDSA, setEditDsa] = useState(false);

    const [openPub, setOpenPub] = useState(false);
    const [tableDataLoading, setTableDataLoading] = useState(false);
    const [totalData, setTotalData] = useState();
    const [controller, setController] = useState({
        page: 0,
        rowsPerPage: 1000
    });
    const handleClosePub = () => setOpenPub(false);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        subTitle: ''
    });

    const [openChangePassword, setOpenChangePassword] = useState(false);
    // const [oldPassword, setOldPassword] = useState('');
    // const [newPassword, setNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    // Full Screen

    const handleToggleFullscreen = () => {
        const element = childRef.current;
        if (!isFullscreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
                setIsFullscreen(true);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullscreen(false);
            }
        }
    };

    // NOTE: Permissions

    useEffect(() => {
        getDsaData();
    }, [controller, setOpenPub, openPub]);

    // pagination Function

    const handlePageChange = (event, newPage) => {
        setController({
            ...controller,
            page: newPage
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setController({
            ...controller,
            rowsPerPage: parseInt(event.target.value),
            page: 0
        });
    };

    const handleOpenDrawer = () => {
        setOpenDrawer(true);
    };

    const handleCloseDrawer = () => {
        setOpenDrawer(false);
        setEditDsa(false);
        formik.resetForm();
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .trim('Name cannot include leading and trailing spaces')
            .strict(true)
            .matches(/^[a-zA-Z _.-]{1,20}$/, 'Invalid Name')
            .min(1, 'The contact name needs to be at least 1 char')
            .required('Name is required'),
        email: Yup.string()
            .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Invalid email address')
            .email('Invalid email address')
            .required('Email is required'),
        pocContact: Yup.string()
            .trim('POC Contact cannot include leading and trailing spaces')
            .strict(true)
            .matches(/^(?!0)\d{10,}$/, 'Invalid POC Contact')
            .required('POC Contact is required')
            .min(10, 'POC Contact must be at least 10 digits')
            .max(10, 'POC Contact must be at least 10 digits'),
        pocName: Yup.string()
            .trim('Name cannot include leading and trailing spaces')
            .strict(true)
            .matches(/^[a-zA-Z _.-]{1,20}$/, 'Invalid Name')
            .min(1, 'The contact name needs to be at least 1 char')
            .required('POC Name is required')
        // filePan: Yup.mixed()
        //     .test('fileSize', 'File too large', (value) => !value || value.size <= 5242880)
        //     .test(
        //         'fileType',
        //         'Unsupported file format',
        //         (value) =>
        //             !value ||
        //             [
        //                 'application/pdf',
        //                 'application/msword',
        //                 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        //             ].includes(value.type)
        //     )
        //     .required('Pan Card is required'),
        // fileAadhar: Yup.mixed()
        //     .test('fileSize', 'File too large', (value) => !value || value.size <= 5242880)
        //     .test(
        //         'fileType',
        //         'Unsupported file format',
        //         (value) =>
        //             !value ||
        //             [
        //                 'application/pdf',
        //                 'application/msword',
        //                 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        //             ].includes(value.type)
        //     )
        //     .required('Aadhar Card is required'),
        // fileBusiness: Yup.mixed()
        //     .test('fileSize', 'File too large', (value) => !value || value.size <= 5242880)
        //     .test(
        //         'fileType',
        //         'Unsupported file format',
        //         (value) =>
        //             !value ||
        //             [
        //                 'application/pdf',
        //                 'application/msword',
        //                 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        //             ].includes(value.type)
        //     )
        //     .required('Business Document is required'),
        // fileCheque: Yup.mixed()
        //     .test('fileSize', 'File too large', (value) => !value || value.size <= 5242880)
        //     .test(
        //         'fileType',
        //         'Unsupported file format',
        //         (value) =>
        //             !value ||
        //             [
        //                 'application/pdf',
        //                 'application/msword',
        //                 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        //             ].includes(value.type)
        //     )
        //     .required('Cheque Document is required'),
        // fileContract: Yup.mixed()
        //     .test('fileSize', 'File too large', (value) => !value || value.size <= 5242880)
        //     .test(
        //         'fileType',
        //         'Unsupported file format',
        //         (value) =>
        //             !value ||
        //             [
        //                 'application/pdf',
        //                 'application/msword',
        //                 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        //             ].includes(value.type)
        //     )
        //     .required('Contract Document is required')
    });

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            pocName: '',
            pocContact: '',
            filePan: '',
            fileAadhar: '',
            fileBusiness: '',
            fileCheque: '',
            fileContract: ''
        },
        validationSchema: validationSchema,

        onSubmit: async (values, formik) => {
            // setLoading(true);

            // console.log('/../.../././/./.', values);

            const createDas = new FormData();
            createDas.append('pan', values?.filePan);

            createDas.append('aadhar', values?.fileAadhar);

            createDas.append('certificateOfIncorporation', values?.fileBusiness);

            createDas.append('cancelledCheque', values?.fileCheque);

            createDas.append('contract', values?.fileContract);

            createDas.append(
                'data',
                JSON.stringify({
                    data: {
                        name: values.name,
                        email: values?.email,
                        pocName: values?.pocName,
                        pocContact: values?.pocContact
                    }
                })
            );

            const updateDsa = new FormData();
            updateDsa.append('pan', values?.filePan);

            updateDsa.append('aadhar', values?.fileAadhar);
            updateDsa.append('certificateOfIncorporation', values?.fileBusiness);

            updateDsa.append('cancelledCheque', values?.fileCheque);

            updateDsa.append('contract', values?.fileContract);

            updateDsa.append(
                'data',
                JSON.stringify({
                    data: {
                        id: isDsaId,
                        name: values?.name,
                        pocName: values?.pocName,
                        pocContact: values?.pocContact
                    }
                })
            );

            let res;
            if (isEditDSA) {
                // res = await updateDSAService(updateDsa);
                res = await apiService
                    .post(apiEndPointsConfig.SET_DSA_STATUS, updateDsa, {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((res) => {
                        getDsaData();
                        setLoading(false);
                    })
                    .catch((error) => {
                        enqueueSnackbar('Something went Wrong', { variant: 'error' });
                    });
            } else {
                apiService
                    .post(apiEndPointsConfig.CREATE_DSA, createDas, {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then((res) => {
                        setPopupData(res?.data);
                    })
                    .catch((error) => {
                        enqueueSnackbar('Something went Wrong', { variant: 'error' });
                    });
            }
            if (res?.error) {
                enqueueSnackbar(`${res?.data}`, {
                    variant: 'error'
                });
                setLoading(false);
                return;
            }

            if (!isEditDSA) {
                setOpenPub(true);
            }
            handleCloseDrawer();
            enqueueSnackbar(`DSA ${isEditDSA ? 'Updated' : 'Created'} Successfully`, {
                variant: 'success'
            });

            setLoading(false);
            formik.resetForm();
        }
    });

    // NOTE: This function is getting all the record

    const getDsaData = async () => {
        setTableDataLoading(true);
        const reqData = {
            index: controller?.page,
            itemPerIndex: controller?.rowsPerPage,
            status: 1
        };

        const res = await getDsaService(reqData); //API - here is api service for get list of all dsa
        setData(res?.data?.dsas);

        if (res !== undefined) {
            dispatch(dasLiveActions.dasLiveData(res?.data));
        }

        dispatch(dasLiveActions.dasLiveData(res?.data));

        setTotalData(res?.data?.total);
        setTableDataLoading(false);
    };

    // NOTE: REFRESH TABLE DATA CLICK ON REFRESH BUTTON

    async function refresh() {
        getDsaData();
    }
    const handleConfig = (data) => {
        navigate(`/dsa/${data?.dsaId}`);
    };
    const handleEdit = (data) => {
        // console.log('Data received for editing:', data);
        setEditDsa(true);
        handleOpenDrawer();
        setDsaId(data?.id);
        // Set values for non-file fields
        formik.setFieldValue('name', data.name, true);
        formik.setFieldValue('email', data.email, true);
        formik.setFieldValue('pocName', data.pocName, true);
        formik.setFieldValue('pocContact', data.pocContact, true);

        // Set values for file upload fields
        data.urls.forEach((urlObj) => {
            switch (urlObj.title) {
                case 'pan card':
                    formik.setFieldValue('filePan', urlObj.name);
                    break;
                case 'aadhar card':
                    formik.setFieldValue('fileAadhar', urlObj.name);
                    break;
                case 'certificateOfIncorporation':
                    formik.setFieldValue('fileBusiness', urlObj.name);
                    break;
                case 'cancelledCheque':
                    formik.setFieldValue('fileCheque', urlObj.name);
                    break;
                case 'contract':
                    formik.setFieldValue('fileContract', urlObj.name);
                    break;
                default:
                    console.warn(`Unhandled URL name: ${urlObj.name}`);
                    break;
            }
        });

        // console.log('Field values set for editing:', formik.values);
    };

    // pop up
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState([]);

    const handleDoc = (url) => {
        setModalData(url);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    // NOTE - THIS USE EFFECT IS RESPONSIBLE SHOW ALL THE DATA IN GRID WITH COLUMN AND HEADER

    useEffect(() => {
        // if (dataLive !== undefined) {
        //     setData(dataLive?.dsas);
        // }
        if (data && data?.length) {
            const allKeys = Object.keys(data[0])?.filter(
                (res) => res !== 'createdOn' && res !== 'id' && res !== 'active' && res !== 'urls'
            );
            const newColumns = allKeys?.map((key) => ({
                key: key,
                field: key,
                headerName: key?.charAt(0).toUpperCase() + key?.slice(1)
            }));

            const indexToUpdateID = newColumns.findIndex((column) => column.field === 'dsaId');
            newColumns[indexToUpdateID] = {
                key: 'dsaId',
                field: 'dsaId',
                headerName: 'DSA Id',
                cellRenderer: ({ data }) => data?.dsaId?.charAt(0).toUpperCase() + data?.dsaId?.slice(1)
            };

            const indexToUpdateName = newColumns.findIndex((column) => column.field === 'name');
            newColumns[indexToUpdateName] = {
                key: 'name',
                field: 'name',
                headerName: 'Name',
                cellRenderer: ({ data }) => data?.name?.charAt(0).toUpperCase() + data?.name?.slice(1)
            };

            const indexToUpdatePocName = newColumns.findIndex((column) => column.field === 'pocName');
            newColumns[indexToUpdatePocName] = {
                key: 'pocName',
                field: 'pocName',
                headerName: 'POC Name',
                cellRenderer: ({ data }) => data?.pocName?.charAt(0).toUpperCase() + data?.pocName?.slice(1)
            };

            const indexToUpdatePocContact = newColumns.findIndex((column) => column.field === 'pocContact');
            newColumns[indexToUpdatePocContact] = {
                key: 'pocContact',
                field: 'pocContact',
                headerName: 'POC Contact',
                cellRenderer: ({ data }) => data?.pocContact
            };

            const indexToUpdateStatus = newColumns.findIndex((column) => column.field === 'status');

            newColumns[indexToUpdateStatus] = {
                key: 'status',
                field: 'status',
                headerName: 'Status',
                // cellRenderer: ({ data }) => data?.pocContact
                cellRenderer: statusButtons
            };

            // newColumns[indexToUpdateUrls] = {
            //     key: 'urls',
            //     field: 'urls',
            //     headerName: 'URL',

            //     cellRenderer: ({ data }) => console.log('urls', data?.urls)
            //     // cellRenderer: statusButtons
            // };

            // newColumns.push({
            //     field: 'Status',
            //     cellRenderer: statusButtons,
            //     cellClass: 'my-class',
            //     filterParams: {
            //         buttons: ['apply', 'reset'],
            //         closeOnApply: true
            //     }
            // });

            newColumns.push({
                key: 'createdOn',
                field: 'createdOn',
                headerName: 'Created On',
                cellRenderer: ({ data }) => moment(data.createdOn).utc().tz('Asia/Kolkata').format('LLL')
            });

            newColumns?.push({
                key: 'action',
                field: 'action',
                headerName: 'Action',
                textAlign: 'center',
                sortable: false,
                pinned: 'right',
                cellRenderer: ({ data }) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '0.5rem' }}>
                            <Box px={1}>
                                <Tooltip
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                    title={'Edit'}
                                    arrow
                                >
                                    <Icon path={mdiPencil} color="#0C247B" size={1} onClick={() => handleEdit(data)} />
                                </Tooltip>
                            </Box>
                            <Box px={1}>
                                <Tooltip
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                    title={'View Doc'}
                                    arrow
                                >
                                    <Icon path={mdiTextBoxSearchOutline} color="#0C247B" size={1} onClick={() => handleDoc(data)} />
                                </Tooltip>
                            </Box>
                            <Box px={1}>
                                <Tooltip
                                    sx={{
                                        cursor: 'pointer'
                                    }}
                                    title={'Configuration'}
                                    arrow
                                >
                                    <Icon path={mdiCogOutline} color="#0C247B" size={1} onClick={() => handleConfig(data)} />
                                </Tooltip>
                            </Box>

                            {/* <Tooltip title={data?.lenderSequence?.length && 'Update Password'} arrow>
                                <Icon path={mdiLockOutline} size={1} onClick={() => handleChangePswrd(data)} />
                            </Tooltip> */}
                        </Box>
                    );
                }
            });
            // newColumns?.push({
            //     key: 'doc',
            //     field: 'doc',
            //     headerName: 'Doc',
            //     textAlign: 'center',
            //     sortable: false,
            //     // pinned: 'right',
            //     cellRenderer: ({ data }) => {
            //         return (
            //             <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '0.5rem' }}>
            //                 <Tooltip
            //                     sx={{
            //                         cursor: 'pointer'
            //                     }}
            //                     title={'Edit'}
            //                     arrow
            //                 >
            //                     <Icon path={mdiTextBoxSearchOutline} color="#0C247B" size={1} onClick={() => handleEdit(data)} />
            //                 </Tooltip>
            //                 {/* <Tooltip title={data?.lenderSequence?.length && 'Update Password'} arrow>
            //                     <Icon path={mdiLockOutline} size={1} onClick={() => handleChangePswrd(data)} />
            //                 </Tooltip> */}
            //             </Box>
            //         );
            //     }
            // });

            setColumnDef(newColumns);
        }
    }, [data]);

    const emailToCopy = PopupData?.data?.email;
    const passwordToCopy = PopupData?.data.password;

    const copyEmail = () => {
        if (emailToCopy) {
            navigator.clipboard
                .writeText(emailToCopy)
                .then(() => {
                    setIsEmailCopied(true);
                })
                .catch((err) => {
                    console.error('Unable to copy email: ', err);
                });
        }
    };

    const copyPassword = () => {
        if (passwordToCopy) {
            navigator.clipboard
                .writeText(passwordToCopy)
                .then(() => {
                    setIsPasswordCopied(true);
                })
                .catch((err) => {
                    console.error('Unable to copy password: ', err);
                });
        }
    };
    const handleClosePopup = () => {
        setOpenPub(false);
        setIsPasswordCopied(false);
        setIsEmailCopied(false);
    };

    const statusButtons = (params) => {
        return (
            <Switch
                sx={{
                    cursor:
                        modulePermissions?.permissions?.modifyContent ||
                        modulePermissions?.permissions?.publishContent ||
                        modulePermissions?.permissions?.admin
                            ? 'pointer'
                            : 'not-allowed',
                    opacity:
                        modulePermissions?.permissions?.modifyContent ||
                        modulePermissions?.permissions?.publishContent ||
                        modulePermissions?.permissions?.admin
                            ? 1
                            : 0.5
                }}
                disabled={
                    !modulePermissions?.permissions?.modifyContent &&
                    !modulePermissions?.permissions?.publishContent &&
                    !modulePermissions?.permissions?.admin
                }
                checked={params?.data?.status === 1 ? true : false}
                onClick={(event) => {
                    // console.log(
                    //     '-=-=-===-==-=-==',
                    //     params?.data?.status === 1 ? 3 : params?.data?.status === 2 || params?.data?.status === 1 ? false : false
                    // );

                    setConfirmDialog({
                        isOpen: true,
                        title: 'Are you sure to Update Lender Status?',
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                            handleSwitchChange(params?.data?.id, params?.data?.status === true ? 1 : 3);
                        }
                    });
                }}
            />
        );
    };

    const handleSwitchChange = useCallback(
        async (id, checked) => {
            setConfirmDialog({
                ...confirmDialog,
                isOpen: false
            });

            const formData = new FormData();

            formData.append(
                'data',
                JSON.stringify({
                    data: {
                        id: id,
                        status: checked
                    }
                })
            );

            apiService
                .post(apiEndPointsConfig.SET_DSA_STATUS, formData, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((res) => {
                    getDsaData();
                    enqueueSnackbar('Status Updated Successfully', { variant: 'success' });
                })
                .catch((error) => {
                    enqueueSnackbar('Something went Wrong', { variant: 'error' });
                });

            // const data = {
            //     id: id,
            //     status: checked === 1 ? 3 : checked === 2 || checked === 3 ? 1 : 3

            //     // email: email
            // };
            // try {
            //     const response = await apiService.post(apiEndPointsConfig.SET_DSA_STATUS, { data });
            //     if (response.data.status.code === 200) {
            //         enqueueSnackbar('Status Updated Successfully', { variant: 'success' });
            //         // getDsaData();
            //     } else {
            //         enqueueSnackbar('Something went Wrong', { variant: 'error' });
            //     }
            // } catch (error) {
            //     console.error('Error updating lender status:', error);
            // }
        },
        [apiService, apiEndPointsConfig, enqueueSnackbar, getDsaData]
        // },
        // [apiService, apiEndPointsConfig, enqueueSnackbar]
    );

    // apiService, apiEndPointsConfig, enqueueSnackbar, getPageData;

    const renderFile = (fileUrl) => {
        // debugger;
        return (
            <>
                {fileUrl && fileUrl.length > 0 ? (
                    <Grid container spacing={2} py={1}>
                        {fileUrl.map((imgData, index) => (
                            <Grid item sx={12} sm={12} md={6} lg={6} xl={12} key={index}>
                                <h2>{imgData.title}</h2>
                                {imgData.url && imgData.url.length > 0 ? (
                                    <embed src={imgData.url} width="100%" height={'500'} type="application/pdf" />
                                ) : (
                                    'Upload img'
                                )}
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    'Doc not found'
                )}
            </>
        );
    };

    return (
        <>
            <Modal open={openPub} onClose={handleClosePub} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <TitleContainer>
                        <Box display={'flex'} justifyContent={'end'}>
                            <CloseIcon onClick={handleClosePopup} variant="contained" style={{ cursor: 'pointer' }} />
                        </Box>
                    </TitleContainer>
                    <ContentContainer>
                        <Typography variant="subtitle3">DSA Created Successfully, Share Below Credentials With the DSA to Login</Typography>
                        {/* //////////////////////// */}
                        <Box>
                            <Typography variant="p" color={'#ee9329'} pt={1}>
                                <b>dsa.credmudra.com</b>
                            </Typography>
                        </Box>
                    </ContentContainer>
                    <Box bgcolor={'#e7e7e7'} borderRadius={'0px 0px 5px 5px'}>
                        <ActionsContainer>
                            <Box pb={2}>
                                <Box display={'flex'} alignItems={'center'}>
                                    <Typography pb={0}>
                                        <span style={{ color: '#000' }}>Email: </span>
                                        <span>{PopupData?.data?.email}</span>
                                        <Button
                                            variant="text"
                                            onClick={copyEmail}
                                            sx={{ mx: 2, p: 0 }}
                                            disabled={isEmailCopied} // Disable the button when email is already copied
                                        >
                                            {isEmailCopied ? 'Copied' : 'Copy'} {/* Toggle button text based on whether email is copied */}
                                        </Button>
                                    </Typography>
                                </Box>
                                <Typography>
                                    <span style={{ color: '#000' }}> Password: </span>
                                    <span> {PopupData?.data?.password}</span>
                                    <Button
                                        variant="text"
                                        onClick={copyPassword}
                                        sx={{ mx: 2, p: 0 }}
                                        disabled={isPasswordCopied} // Disable the button when email is already copied
                                    >
                                        {isPasswordCopied ? 'Copied' : 'Copy'} {/* Toggle button text based on whether email is copied */}
                                    </Button>
                                </Typography>
                            </Box>
                        </ActionsContainer>
                    </Box>
                </Box>
            </Modal>

            <Modal
                open={isModalOpen}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} // Centering the modal vertically and horizontally
            >
                <div
                    style={{
                        backgroundColor: 'white',
                        padding: 20,
                        width: '70%',
                        maxHeight: '90vh', // Limiting max height to 90% of viewport height
                        overflowY: 'auto', // Adding scrollbar when content overflows
                        outline: 'none' // Removing default focus border
                    }}
                >
                    {/* {modalData && ( */}
                    <div>
                        <h1>Document</h1>
                        {renderFile(modalData?.urls)}
                        {/* {console.log('---------modalData', modalData?.urls)} */}
                    </div>
                    {/* )} */}
                    <Divider />
                    <Box display={'flex'} alignItems={'flex-end'} justifyContent={'flex-end'} pt={2}>
                        <Button variant="contained" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Box>
                </div>
            </Modal>
            <CommonDrawer open={openDrawer} onClose={handleCloseDrawer}>
                <form onSubmit={formik.handleSubmit}>
                    <Box className="filter-header">
                        <Typography className="filter-title">{isEditDSA ? 'Edit DSA' : 'Add DSA'}</Typography>
                    </Box>
                    <Divider />
                    <Box minWidth="500px" maxWidth="500px">
                        <Stack spacing={2} marginTop={2} paddingX={1}>
                            <Stack spacing={2}>
                                <Box className="custom-textfield" marginTop={0}>
                                    <Typography mb={1}>DSA Name </Typography>
                                    <TextField
                                        size="small"
                                        name={'name'}
                                        placeholder={'Enter DSA Name'}
                                        value={formik?.values?.name}
                                        onChange={(e) => formik.setFieldValue('name', e.target.value)}
                                        fullWidth
                                        error={formik.touched.name && formik.errors.name}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </Box>

                                <Box className="custom-textfield">
                                    <Typography mb={1}>Email</Typography>
                                    <TextField
                                        size="small"
                                        disabled={isEditDSA && true}
                                        name={'email'}
                                        placeholder={'Enter DSA Email'}
                                        value={formik?.values?.email}
                                        onChange={(e) => formik.setFieldValue('email', e.target.value)}
                                        fullWidth
                                        error={formik.touched.email && formik.errors.email}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </Box>
                                <Box className="custom-textfield" marginTop={0}>
                                    <Typography mb={1}>POC Name </Typography>
                                    <TextField
                                        size="small"
                                        name={'pocName'}
                                        placeholder={'Enter POC Name'}
                                        value={formik?.values?.pocName}
                                        onChange={(e) => formik.setFieldValue('pocName', e.target.value)}
                                        fullWidth
                                        error={formik.touched.pocName && formik.errors.pocName}
                                        helperText={formik.touched.pocName && formik.errors.pocName}
                                    />
                                </Box>
                                <Box className="custom-textfield" marginTop={0}>
                                    <Typography mb={1}>POC Contact </Typography>
                                    <TextField
                                        size="small"
                                        name={'pocContact'}
                                        placeholder={'Enter POC Contact'}
                                        value={formik?.values?.pocContact}
                                        onChange={(e) => formik.setFieldValue('pocContact', e.target.value)}
                                        inputProps={{
                                            maxLength: 10
                                        }}
                                        fullWidth
                                        error={formik.touched.pocContact && formik.errors.pocContact}
                                        helperText={formik.touched.pocContact && formik.errors.pocContact}
                                    />
                                </Box>
                                {/* <Box>
                                    <Typography mb={1}>Upload Pan Card</Typography>

                                    <Box style={{ position: 'relative' }}>
                                        <TextField size="small"
                                            name={'filePan'}
                                            placeholder={'Upload Pan Card'}
                                            inputProps={{
                                                maxLength: 10,
                                                readOnly: true
                                            }}
                                            fullWidth
                                            value={JSON.stringify(formik?.values?.filePan?.name)}
                                            error={formik.touched.filePan && formik.errors.filePan}
                                            helperText={formik.touched.filePan && formik.errors.filePan}
                                        ></TextField>

                                        <Box>
                                            <input
                                                style={{ position: 'absolute', top: '7px', right: '0px' }}
                                                type="file"
                                                onChange={(e) => formik.setFieldValue('filePan', e.target.files[0])}
                                                accept=".pdf,.doc,.docx" // Specify accepted file types if needed
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography mb={1}>Upload Aadhar Card</Typography>

                                    <Box style={{ position: 'relative' }}>
                                        <TextField size="small"
                                            name={'fileAadhar'}
                                            placeholder={'Upload Aadhar Card'}
                                            inputProps={{
                                                maxLength: 10,
                                                readOnly: true
                                            }}
                                            fullWidth
                                            value={JSON.stringify(formik?.values?.fileAadhar?.name)}
                                            error={formik.touched.fileAadhar && formik.errors.fileAadhar}
                                            helperText={formik.touched.fileAadhar && formik.errors.fileAadhar}
                                        ></TextField>

                                        <Box>
                                            <input
                                                style={{ position: 'absolute', top: '7px', right: '0px' }}
                                                type="file"
                                                onChange={(e) => formik.setFieldValue('fileAadhar', e.target.files[0])}
                                                accept=".pdf,.doc,.docx" // Specify accepted file types if needed
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography mb={1}>Upload Business Doc</Typography>

                                    <Box style={{ position: 'relative' }}>
                                        <TextField size="small"
                                            name={'fileBusiness'}
                                            placeholder={'Upload Business Doc'}
                                            inputProps={{
                                                maxLength: 10,
                                                readOnly: true
                                            }}
                                            fullWidth
                                            value={JSON.stringify(formik?.values?.fileBusiness?.name)}
                                            error={formik.touched.fileBusiness && formik.errors.fileBusiness}
                                            helperText={formik.touched.fileBusiness && formik.errors.fileBusiness}
                                        ></TextField>

                                        <Box>
                                            <input
                                                style={{ position: 'absolute', top: '7px', right: '0px' }}
                                                type="file"
                                                onChange={(e) => formik.setFieldValue('fileBusiness', e.target.files[0])}
                                                accept=".pdf,.doc,.docx" // Specify accepted file types if needed
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography mb={1}>Upload Cancel Cheque</Typography>

                                    <Box style={{ position: 'relative' }}>
                                        <TextField size="small"
                                            name={'fileCheque'}
                                            placeholder={'Upload Cancel Cheque'}
                                            inputProps={{
                                                maxLength: 10,
                                                readOnly: true
                                            }}
                                            fullWidth
                                            value={JSON.stringify(formik?.values?.fileCheque?.name)}
                                            error={formik.touched.fileCheque && formik.errors.fileCheque}
                                            helperText={formik.touched.fileCheque && formik.errors.fileCheque}
                                        ></TextField>

                                        <Box>
                                            <input
                                                style={{ position: 'absolute', top: '7px', right: '0px' }}
                                                type="file"
                                                onChange={(e) => formik.setFieldValue('fileCheque', e.target.files[0])}
                                                accept=".pdf,.doc,.docx" // Specify accepted file types if needed
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography mb={1}>Upload Contract</Typography>

                                    <Box style={{ position: 'relative' }}>
                                        <TextField size="small"
                                            name={'fileContract'}
                                            placeholder={'Upload Contract'}
                                            inputProps={{
                                                maxLength: 10,
                                                readOnly: true
                                            }}
                                            fullWidth
                                            value={JSON.stringify(formik?.values?.fileContract?.name)}
                                            error={formik.touched.fileContract && formik.errors.fileContract}
                                            helperText={formik.touched.fileContract && formik.errors.fileContract}
                                        ></TextField>

                                        <Box>
                                            <input
                                                style={{ position: 'absolute', top: '7px', right: '0px' }}
                                                type="file"
                                                onChange={(e) => formik.setFieldValue('fileContract', e.target.files[0])}
                                                accept=".pdf,.doc,.docx" // Specify accepted file types if needed
                                            />
                                        </Box>
                                    </Box>
                                </Box> */}

                                <Box>
                                    <Typography mb={1}>upload Pan Card </Typography>

                                    <Box style={{ position: 'relative' }}>
                                        {/* {console.log('=--==-=-=-=-=-=-=-=-=-=-=-=-=-=-', formik?.values?.filePan)} */}
                                        <TextField
                                            size="small"
                                            name={'filePan'}
                                            placeholder={'Upload Pan Card'}
                                            // value={formik?.values?.filePan}
                                            value={
                                                formik.values.filePan instanceof File ? formik.values.filePan.name : formik.values.filePan
                                            }
                                            onChange={(e) => formik.setFieldValue('filePan', e.target.value)}
                                            inputProps={{
                                                maxLength: 10,
                                                readOnly: true
                                            }}
                                            fullWidth
                                            error={formik.touched.filePan && formik.errors.filePan}
                                            helperText={formik.touched.filePan && formik.errors.filePan}
                                        ></TextField>

                                        <Box>
                                            <input
                                                style={{ position: 'absolute', top: '7px', right: '0px' }}
                                                type="file"
                                                onChange={(e) => formik.setFieldValue('filePan', e.target.files[0])}
                                                accept=".pdf,.doc,.docx" // Specify accepted file types if needed
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography mb={1}>Upload AAdhar Card </Typography>

                                    <Box style={{ position: 'relative' }}>
                                        <TextField
                                            size="small"
                                            name={'fileAadhar'}
                                            placeholder={'Upload Aadhar Card'}
                                            value={
                                                formik.values.fileAadhar instanceof File
                                                    ? formik.values.fileAadhar.name
                                                    : formik.values.fileAadhar
                                            }
                                            onChange={(e) => formik.setFieldValue('fileAadhar', e.target.value)}
                                            inputProps={{
                                                maxLength: 10,
                                                readOnly: true
                                            }}
                                            fullWidth
                                            error={formik.touched.fileAadhar && formik.errors.fileAadhar}
                                            helperText={formik.touched.fileAadhar && formik.errors.fileAadhar}
                                        ></TextField>

                                        <Box>
                                            <input
                                                style={{ position: 'absolute', top: '7px', right: '0px' }}
                                                type="file"
                                                onChange={(e) => formik.setFieldValue('fileAadhar', e.target.files[0])}
                                                accept=".pdf,.doc,.docx" // Specify accepted file types if needed
                                            />
                                        </Box>
                                    </Box>
                                </Box>

                                <Box>
                                    <Typography mb={1}>Upload Business Doc </Typography>

                                    <Box style={{ position: 'relative' }}>
                                        <TextField
                                            size="small"
                                            name={'fileBusiness'}
                                            placeholder={'Upload Business Doc'}
                                            value={
                                                formik.values.fileBusiness instanceof File
                                                    ? formik.values.fileBusiness.name
                                                    : formik.values.fileBusiness
                                            }
                                            onChange={(e) => formik.setFieldValue('fileBusiness', e.target.value)}
                                            inputProps={{
                                                maxLength: 10,
                                                readOnly: true
                                            }}
                                            fullWidth
                                            error={formik.touched.fileBusiness && formik.errors.fileBusiness}
                                            helperText={formik.touched.fileBusiness && formik.errors.fileBusiness}
                                        ></TextField>

                                        <Box>
                                            <input
                                                style={{ position: 'absolute', top: '7px', right: '0px' }}
                                                type="file"
                                                onChange={(e) => formik.setFieldValue('fileBusiness', e.target.files[0])}
                                                accept=".pdf,.doc,.docx" // Specify accepted file types if needed
                                            />
                                        </Box>
                                    </Box>
                                </Box>

                                <Box>
                                    <Typography mb={1}>Upload Cheque Doc </Typography>

                                    <Box style={{ position: 'relative' }}>
                                        <TextField
                                            size="small"
                                            name={'fileCheque'}
                                            placeholder={'Upload Cancel Cheque'}
                                            value={
                                                formik.values.fileCheque instanceof File
                                                    ? formik.values.fileCheque.name
                                                    : formik.values.fileCheque
                                            }
                                            onChange={(e) => formik.setFieldValue('fileCheque', e.target.value)}
                                            inputProps={{
                                                maxLength: 10,
                                                readOnly: true
                                            }}
                                            fullWidth
                                            error={formik.touched.fileCheque && formik.errors.fileCheque}
                                            helperText={formik.touched.fileCheque && formik.errors.fileCheque}
                                        ></TextField>

                                        <Box>
                                            <input
                                                style={{ position: 'absolute', top: '7px', right: '0px' }}
                                                type="file"
                                                onChange={(e) => formik.setFieldValue('fileCheque', e.target.files[0])}
                                                accept=".pdf,.doc,.docx" // Specify accepted file types if needed
                                            />
                                        </Box>
                                    </Box>
                                </Box>

                                <Box>
                                    <Typography mb={1}>Upload Contract Doc </Typography>

                                    <Box style={{ position: 'relative' }}>
                                        <TextField
                                            size="small"
                                            name={'fileContract'}
                                            placeholder={'Upload Contract '}
                                            value={
                                                formik.values.fileContract instanceof File
                                                    ? formik.values.fileContract.name
                                                    : formik.values.fileContract
                                            }
                                            onChange={(e) => formik.setFieldValue('fileContract', e.target.value)}
                                            inputProps={{
                                                maxLength: 10,
                                                readOnly: true
                                            }}
                                            fullWidth
                                            error={formik.touched.fileContract && formik.errors.fileContract}
                                            helperText={formik.touched.fileContract && formik.errors.fileContract}
                                        ></TextField>

                                        <Box>
                                            <input
                                                style={{ position: 'absolute', top: '7px', right: '0px' }}
                                                type="file"
                                                onChange={(e) => formik.setFieldValue('fileContract', e.target.files[0])}
                                                accept=".pdf,.doc,.docx" // Specify accepted file types if needed
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Stack>
                        </Stack>
                    </Box>
                    <br />
                    <Divider />
                    <Box p={2}>
                        <Grid container spacing={2} display={'flex'} justifyContent="flex-end" align-items="center">
                            <Grid item sm={4} mt={2} display={'flex'} justifyContent={'flex-end'}>
                                <LoadingButton
                                    loading={loading}
                                    loadingPosition="end"
                                    type="submit"
                                    variant="contained"
                                    className="apply-button"
                                >
                                    {isEditDSA ? 'Update' : 'Create'}
                                </LoadingButton>
                            </Grid>
                            <Grid item sm={2} mt={2}>
                                <Button onClick={handleCloseDrawer} variant="outlined" className="cancel-button">
                                    Close
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </CommonDrawer>

            {/* SOUMYA CHANGES */}

            {/* <CommonDrawer open={openChangePassword} onClose={handleCloseChangePassword}>
                <form onSubmit={formik1.handleSubmit}>
                    <Box className="filter-header">
                        <Typography className="filter-title">Change Password</Typography>
                    </Box>
                    <Divider />
                    <Box minWidth="500px" maxWidth="500px">
                        <Grid container spacing={2} marginTop={2} paddingX={1}>
                            <Grid item xs={12}>
                                <Box className="custom-textfield">
                                    <Typography mb={1}>Old Password</Typography>
                                    <TextField size="small"
                                        name="oldPassword"
                                        type={showPassword ? 'text' : 'password'}
                                        value={formik1?.values?.oldPassword}
                                        onChange={(e) => formik1.setFieldValue('oldPassword', e.target.value)}
                                        error={formik1.touched.oldPassword && formik1.errors.oldPassword}
                                        helperText={formik1.touched.oldPassword && formik1.errors.oldPassword}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" sx={{ p: 1 }}>
                                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className="custom-textfield">
                                    <Typography mb={1}>New Password</Typography>
                                    <TextField size="small"
                                        name="newPassword"
                                        type={showPassword ? 'text' : 'password'}
                                        value={formik1?.values?.newPassword}
                                        onChange={(e) => formik1.setFieldValue('newPassword', e.target.value)}
                                        error={formik1.touched.newPassword && formik1.errors.newPassword}
                                        helperText={formik1.touched.newPassword && formik1.errors.newPassword}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end" sx={{ p: 1 }}>
                                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <br />
                    <Divider />
                    <Box p={2}>
                        <Grid container spacing={2} display={'flex'} justifyContent="flex-end" align-items="center">
                            <Grid item sm={4} mt={2} display={'flex'} justifyContent={'flex-end'}>
                                <LoadingButton
                                    loading={loading}
                                    loadingPosition="end"
                                    type="submit"
                                    variant="contained"
                                    className="apply-button"
                                    onClick={handleOpenChangePassword}
                                    sx={{ width: '400px' }}
                                >
                                    Change Password
                                </LoadingButton>
                            </Grid>
                            <Grid item sm={2} mt={2}>
                                <Button onClick={handleCloseChangePassword} variant="outlined" className="cancel-button">
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </form>
            </CommonDrawer> */}
            {/* <PageHeader title={'Live Dsa'} pageAction={'Export'} helpText={'Direct Selling Agent'} /> */}
            <Grid item sm={12}>
                <Grid item sm={12}>
                    <Box
                        sx={{ width: { xs: '730px', md: '100%' } }}
                        // pl={2}
                        pt={2}
                        spacing={isFullscreen ? 0 : 2}
                        ref={childRef}
                        className={`child-component ${isFullscreen ? 'fullscreen' : ''}`}
                    >
                        {data && (
                            <CommonChart
                                title={'Live Dsa'}
                                createButtonValue={'Create New DSA'}
                                createIcon={<img src={plus} width={20} alt={'Export Button'} />}
                                createOnClick={handleOpenDrawer}
                                createBgColor="#EE9329"
                                TextColor="#fff"
                                hoverColor="red"
                                onToggleFullscreen={handleToggleFullscreen}
                                ref={childRef}
                                className={`child-component ${isFullscreen ? 'fullscreen' : ''}`}
                                isFullscreen={isFullscreen}
                                columnDefs={columnDef}
                                rowData={data}
                                isGridRequired={true}
                                isServerPagination={true}
                                isLoading={tableDataLoading}
                                onRefresh={refresh}
                            >
                                <TablePagination
                                    component="div"
                                    onPageChange={handlePageChange}
                                    page={controller.page}
                                    count={totalData}
                                    rowsPerPage={controller.rowsPerPage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    rowsPerPageOptions={[1000, 2500, 5000, 10000]}
                                    style={{
                                        ...(isFullscreen && {
                                            position: 'absolute',
                                            bottom: '20px',
                                            right: 0,
                                            left: 0
                                        })
                                    }}
                                />
                            </CommonChart>
                        )}
                    </Box>
                    <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
                </Grid>
            </Grid>
        </>
    );
};

export default DsaMasterPage;

//ABHISHEK - @author ask me anything
