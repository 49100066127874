import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import { Grid, useTheme, ThemeProvider, createTheme, Tooltip, useMediaQuery } from '@mui/material';
import './progressBar.css';
import { InfoOutlined } from '@mui/icons-material';
import Icon from '@mdi/react';
import { mdiCloseCircleOutline, mdiAlertCircleOutline, mdiCheckCircleOutline, mdiProgressClock, mdiProgressClose } from '@mdi/js';
import { padding } from '@mui/system';

export default function HorizontalLinearStepper(status) {
    const steps = ['Eligible', 'Accepted', 'Registered', 'Processing', 'Disbursed'];
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const greenTheme = createTheme({
        palette: {
            primary: {
                main: '#03A400'
            }
        }
    });

    const handleIcons = (label, index) => {
        switch (label) {
            case 'Eligible': {
                return <Icon path={mdiCheckCircleOutline} color={'green'} size={1} />;
            }
            case 'Accepted': {
                if (status?.status[index].value === 'rejected' || status?.status[index].value === 'error') {
                    return <Icon path={mdiCloseCircleOutline} color={status?.status?.length > index ? '#d43d39' : '#b0b0b0'} size={1} />;
                } else if (status?.status[index].value === 'duplicate') {
                    return <Icon path={mdiAlertCircleOutline} color={'#fb9a36'} size={1} />;
                } else {
                    return <Icon path={mdiCheckCircleOutline} color={'green'} size={1} />;
                }
            }
            case 'Registered': {
                return <Icon path={mdiCheckCircleOutline} color={status?.status?.length > index ? 'green' : '#b0b0b0'} size={1} />;
            }
            case 'Processing': {
                if (status?.status.length - 1 === index) {
                    if (status?.status[index].value === 'Disbursed') {
                        return <Icon path={mdiCheckCircleOutline} color={'green'} size={1} />;
                    } else if (status?.status[index].value === 'Reject') {
                        return <Icon path={mdiProgressClose} color={'#d43d39'} size={1} />;
                    } else {
                        return <Icon path={mdiProgressClock} color={status?.status?.length + 1 > index ? '#fb9a36' : '#b0b0b0'} size={1} />;
                    }
                } else {
                    return <Icon path={mdiProgressClock} color={status?.status?.length + 1 > index ? '#fb9a36' : '#b0b0b0'} size={1} />;
                }
            }
            case 'Disbursed': {
                return (
                    <Icon
                        path={mdiCheckCircleOutline}
                        color={
                            status?.status?.length === index
                                ? status?.status[index - 1].value === 'Disbursed'
                                    ? 'green'
                                    : '#b0b0b0'
                                : '#b0b0b0'
                        }
                        size={1}
                    />
                );
            }
        }
    };
    const handleLabelColor = (label, index) => {
        switch (label) {
            case 'Eligible': {
                return { color: 'green' };
            }
            case 'Accepted': {
                if (status?.status[index].value === 'rejected' || status?.status[index].value === 'error') {
                    return { color: '#d43d39', padding: '0px 10px' };
                } else if (status?.status[index].value === 'duplicate') {
                    return { color: '#fb9a36' };
                } else {
                    return { color: 'green' };
                }
            }
            case 'Registered': {
                if (status?.status?.length > index) {
                    if (status?.status[index].value === 'registered') {
                        return { color: 'green' };
                    }
                } else {
                    return { color: '#b0b0b0' };
                }
            }
            case 'Processing': {
                if (status?.status.length - 1 === index) {
                    if (status?.status[index].value === 'Disbursed') {
                        return { color: 'green' };
                    } else if (status?.status[index].value === 'Reject') {
                        return { color: '#d43d39' };
                    } else {
                        return { color: '#fb9a36' };
                    }
                } else {
                    if (status?.status?.length + 1 > index) {
                        if (status?.status[index - 1].value !== 'registered') {
                            return { color: '#fb9a36' };
                        }
                    } else {
                        return { color: '#b0b0b0' };
                    }
                }
            }
            case 'Disbursed': {
                if (status?.status?.length === index) {
                    if (status?.status[index - 1].value === 'Disbursed') {
                        return { color: 'green' };
                    }
                } else {
                    return { color: '#b0b0b0' };
                }
            }
        }
    };
    const handleLabelText = (label, index) => {
        switch (label) {
            case 'Eligible': {
                return 'Eligible';
            }
            case 'Accepted': {
                if (status?.status[index].value === 'rejected') {
                    return 'Rejected';
                } else if (status?.status[index].value === 'error') {
                    return 'Error';
                } else if (status?.status[index].value === 'duplicate') {
                    return 'Duplicate';
                } else {
                    return 'Accepted';
                }
            }
            case 'Registered': {
                return 'Registered';
            }
            case 'Processing': {
                if (status?.status.length - 1 === index) {
                    if (status?.status[index].value === 'Disbursed') {
                        return 'Processed';
                    } else if (status?.status[index].value === 'Reject') {
                        return 'Failed';
                    } else {
                        return 'Processing';
                    }
                } else {
                    return 'Processing';
                }
            }
            case 'Disbursed': {
                return 'Disbursed';
            }
        }
    };

    return (
        <ThemeProvider theme={greenTheme}>
            <Grid
                container
                sx={{
                    outline: 'none',
                    textAlign: 'center'
                }}
            >
                <Grid
                    item
                    md={12}
                    sm={12}
                    sx={{
                        width: '100%',
                        mt: '30px'
                    }}
                    //  spacing={4}
                >
                    <Stepper alternativeLabel={isMobile}>
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel
                                        StepIconComponent={() => handleIcons(label, index)}
                                        sx={{
                                            fontSize: '0.8rem !important',
                                            display: 'flex !important',
                                            alignItems: 'center',
                                            textTransform: 'capitalize',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <span style={handleLabelColor(label, index)}>{handleLabelText(label, index)}</span>
                                        {/* {(label?.value === "error" ||
                      label?.value === "rejected" ||
                      label?.value === "duplicate") && (
                      <Tooltip
                        title={label?.reason}
                        variant="caption"
                        color="error"
                      >
                        <InfoOutlined sx={{ color: "#87909B", ml: 1 }} />
                      </Tooltip>
                    )} */}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
