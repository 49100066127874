// Note: (Lead Journey) - Group By Date

import {
    Autocomplete,
    Checkbox,
    Chip,
    Grid,
    TextField,
    useEffect,
    useRef,
    useState,
    useFormik,
    moment,
    LoadingButton,
    CheckBoxOutlineBlankIcon,
    CheckBoxIcon,
    useSnackbar,
    Box,
    CommonChart,
    Button,
    AddOutlined,
    Typography,
    Divider,
    CircularLoadingProgress,
    Filters,
    CommonAccordion,
    useDispatch,
    useSelector,
    DateLeads,
    CommonDateRangePicker,
    CommonDrawer,
    Yup,
    useNavigate,
    staticRanges,
    ActionSubmits
} from '../../../collections/Imports';
import { eligibleLeadActions } from 'store/leads/eligibleLeadSlice';

import { getGroupByDate, groupByDateLenders, groupByDateReport } from 'helpers/credmudra/market-place-analytics';
import { getLeadsOffUtmSource } from '../../../helpers/credmudra/leads';
import CapitalizeName from 'Components/ui-component/capitalize-name';
import { GroupByLeadSliceAction } from 'store/lead-journey/GroupByLeadSlice';
import useLenderFilters from 'hooks/useLendersFilters';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function GroupByDate() {
    const lendersList = useLenderFilters(); //this is hook for lender list

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const childRef = useRef();
    const [isFullscreen, setIsFullscreen] = useState(false);

    // Note: "GBDate" and "GBDateSelectedFilters" are used in "GroupByLead"

    const { GBDate, GBDateSelectedFilters } = useSelector((state) => state.groupByLead);

    const [loading, setLoading] = useState(false);
    const [loadingGif, setLoadingGif] = useState(true);
    const [columnDef, setColumnDef] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [isOpen, setIsOpen] = useState(false);
    const [APIData, setAPIData] = useState([]);
    const [selectedSources, setSelectedSources] = useState(['All']);
    const [selectedCampaigns, setSelectedCampaigns] = useState(['All']);
    const [selectedMediums, setSelectedMediums] = useState(['All']);

    const [defaultLender, setDefaultLender] = useState(false);

    const [campaignOptions, setCampaignOptions] = useState([]);
    const [mediumOptions, setMediumOptions] = useState([]);

    const [defaultSource, setDefaultSource] = useState(false);
    const [defaultCampaign, setDefaultCampaign] = useState(false);
    const [defaultMedium, setDefaultMedium] = useState(false);
    const [defaultDate, setDefaultDate] = useState(false);
    const [filterSources, setFilterSources] = useState(['All']);
    const [filterCampaign, setFilterCampaign] = useState(['All']);
    const [filterMedium, setFilterMedium] = useState(['All']);

    const [campaignCheckbox, setCampaignCheckbox] = useState(false);
    const [mediumCheckbox, setMediumCheckbox] = useState(false);
    const [sourceCheckbox, setSourceCheckbox] = useState(false);

    const [filterLenders, setFilterLenders] = useState(['All']);
    const [filterLendersId, setFilterLendersId] = useState(['All']);
    const [showAllChips, setShowAllChips] = useState(false);

    const startDate = new Date();
    const endDate = new Date();

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(startDate.setDate(endDate.getDate() - 6)),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    const [selectedDate, setSelectedDate] = useState({
        startDate: new Date(startDate.setDate(endDate.getDate() - 6)),
        endDate: new Date(),
        key: 'selection'
    });

    const toggleDrawer = (ele) => {
        ele === 'source' ? setDefaultSource(true) : setDefaultSource(false);
        ele === 'date' ? setDefaultDate(true) : setDefaultDate(false);
        setIsOpen((prev) => !prev);
    };

    const getPageData = async () => {
        setLoadingGif(true);
        try {
            if (GBDateSelectedFilters != null) {
                const { fromDate, toDate, lenderIds, lendersName, sources } = GBDateSelectedFilters;
                let data = {
                    fromDate: fromDate,
                    toDate: toDate,
                    lenderIds: lenderIds,
                    lendersName: lendersName,
                    sources: sources
                };

                dispatch(GroupByLeadSliceAction.getGBDateSelectedFilters(data));
                const response = await getGroupByDate(data);
                setLoadingGif(false);
                dispatch(GroupByLeadSliceAction.getGBDate(response));

                setSelectedDate({
                    startDate: new Date(fromDate),
                    endDate: new Date(toDate),
                    key: 'selection'
                });

                setSelectedSources([...sources]);
                setFilterSources([...sources]);
                formik.setFieldValue('sources', sources, true);

                setFilterLenders([...lendersName]);

                // const filteredLenders = [];
                // lenderIds.forEach((id) => {
                //     const foundLender = lendersList.find((lender) => lender.lenderId === id);
                //     if (foundLender) {
                //         filteredLenders.push(foundLender);
                //     }
                // });

                // formik.setFieldValue('loanlender', filteredLenders, true);
                setDateRange([
                    {
                        startDate: fromDate ? new Date(fromDate) : new Date(),
                        endDate: toDate ? new Date(toDate) : new Date(),
                        key: 'selection'
                    }
                ]);
            } else {
                let data = {
                    fromDate: moment().subtract(7, 'day').startOf('day').toISOString(),
                    toDate: moment().endOf('day').toISOString(),
                    lenderIds: ['All'],
                    sources: ['All'],
                    mediums: ['All'],
                    campaigns: ['All']
                };
                dispatch(GroupByLeadSliceAction.getGBDateSelectedFilters(data));
                const response = await getGroupByDate(data);
                setLoadingGif(false);
                dispatch(GroupByLeadSliceAction.getGBDate(response));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getPageData();
    }, []);

    const actionHandleSubmit = async (data, field) => {
        const tabMapping = {
            submits: 'MarketPlace_Submit',
            marketElegeble: 'MarketPlace_Eligible',
            marketPlaceImpression: 'MarketPlace_Impression',
            marketPlaceClick: 'MarketPlace_Clicked',
            marketPlaceDropOff: 'MarketPlace_DropOffs'
        };

        const tab = tabMapping[field] || 'MarketPlace_Default';

        const newData = {
            fromDate: moment(data?.date).startOf('day').toISOString(),
            toDate: moment(data?.date).endOf('day').toISOString(),
            sources: ['All'],
            mediums: ['All'],
            campaigns: ['All'],
            lenderIds: ['All'],
            tab
        };

        const response = await groupByDateReport(newData);

        if (response) {
            dispatch(GroupByLeadSliceAction.getGBStatusCount(response));
            dispatch(GroupByLeadSliceAction.getGridName('Group By Date'));
            navigate(`/lead-journey/${field}`);
        }
    };

    const actionHandleClick = async (data, field) => {
        dispatch(GroupByLeadSliceAction.getGBClickedLenderSelectedFilters(data));
        const newData = {
            fromDate: moment(data?.date).startOf('day').toISOString(),
            toDate: moment(data?.date).endOf('day').toISOString(),
            sources: ['All'],
            mediums: ['All'],
            campaigns: ['All'],
            lenderIds: ['All']
        };

        const response = await groupByDateLenders(newData);
        dispatch(GroupByLeadSliceAction.getGBClickedLender(response));
        navigate(`/lead-journey/mp-clicked-lenders/`);
    };

    useEffect(() => {
        if (GBDate?.headers?.length) {
            const col = [...GBDate?.headers];
            let newColumns = col;

            const dateIndex = newColumns.findIndex((item) => item.field === 'date');
            newColumns.splice(dateIndex, 1, {
                field: 'date',
                key: 'date',
                minWidth: 200,
                cellRenderer: ({ value }) => moment(value).utc().format('LL')
            });
            const submitsIndex = newColumns.findIndex((item) => item.field === 'submits');
            newColumns.splice(submitsIndex, 1, {
                field: 'submits',
                filter: 'agNumberColumnFilter',
                defaultSort: 'asc',
                headerName: 'Submits',
                cellRenderer: (params) => {
                    return <ActionSubmits data={params?.data} field={'submits'} actionHandleSubmit={actionHandleSubmit} />;
                },
                cellClass: 'my-class',
                filterParams: {
                    buttons: ['apply', 'reset'],
                    closeOnApply: true
                }
            });
            const marketElegebleIndex = newColumns.findIndex((item) => item.field === 'marketElegeble');
            newColumns.splice(marketElegebleIndex, 1, {
                field: 'marketElegeble',
                filter: 'agNumberColumnFilter',
                defaultSort: 'asc',
                headerName: 'MP Eligible',
                cellRenderer: (params) => {
                    return <ActionSubmits data={params?.data} field={'marketElegeble'} actionHandleSubmit={actionHandleSubmit} />;
                },
                cellClass: 'my-class',
                filterParams: {
                    buttons: ['apply', 'reset'],
                    closeOnApply: true
                }
            });
            const marketPlaceImpressionIndex = newColumns.findIndex((item) => item.field === 'marketPlaceImpression');
            newColumns.splice(marketPlaceImpressionIndex, 1, {
                field: 'marketPlaceImpression',
                filter: 'agNumberColumnFilter',
                defaultSort: 'asc',
                headerName: 'MP Impression',
                cellRenderer: (params) => {
                    return <ActionSubmits data={params?.data} field={'marketPlaceImpression'} actionHandleSubmit={actionHandleSubmit} />;
                },
                cellClass: 'my-class',
                filterParams: {
                    buttons: ['apply', 'reset'],
                    closeOnApply: true
                }
            });
            const marketPlaceClickIndex = newColumns.findIndex((item) => item.field === 'marketPlaceClick');
            newColumns.splice(marketPlaceClickIndex, 1, {
                field: 'marketPlaceClick',
                filter: 'agNumberColumnFilter',
                defaultSort: 'asc',
                headerName: 'MP Clicked',
                cellRenderer: (params) => {
                    return <ActionSubmits data={params?.data} field={'marketPlaceClick'} actionHandleSubmit={actionHandleClick} />;
                },
                cellClass: 'my-class',
                filterParams: {
                    buttons: ['apply', 'reset'],
                    closeOnApply: true
                }
            });

            setColumnDef(newColumns);
        }
    }, [GBDate]);

    useEffect(() => {
        getUtmSource();
    }, [GBDateSelectedFilters, dateRange]);

    const getUtmSource = async () => {
        if (GBDateSelectedFilters !== null) {
            const { fromDate, toDate } = GBDateSelectedFilters;
            const newData = {
                fromDate: moment(dateRange[0].startDate).startOf('day').toISOString() || fromDate,
                toDate: moment(dateRange[0]?.endDate).endOf('day').toISOString() || toDate
            };
            const response = await getLeadsOffUtmSource(newData);
            dispatch(eligibleLeadActions.getLeadsOffUtmSource(response));
            setAPIData(response || []);
        } else {
            const newData = {
                fromDate: moment(dateRange[0].startDate).startOf('day').toISOString(),
                toDate: moment(dateRange[0]?.endDate).endOf('day').toISOString()
            };
            const response = await getLeadsOffUtmSource(newData);
            dispatch(eligibleLeadActions.getLeadsOffUtmSource(response));
            setAPIData(response || []);
        }
    };

    const validationSchema = Yup.object().shape({
        loanlender: Yup.array().min(1, 'Please select at least one lender').required('required'),
        sources: Yup.array().min(1, 'Please select at least one Source').required('required'),
        campaigns: Yup.array().min(1, 'Please select at least one campaigns').required('required'),
        medium: Yup.array().min(1, 'Please select at least one medium').required('required')
    });

    const inititalValues = {
        loanlender: [{ lenderId: 'All', name: 'All' }],
        sources: ['All'],
        campaigns: ['All'],
        medium: ['All']
    };

    useEffect(() => {
        setCampaignOptions((prev) => {
            let spreadArray = [];

            const selectedData = APIData.filter((item) => selectedSources.includes(item.source));

            const campaignArray = selectedData.map((item) => item.campaigns);

            campaignArray.forEach((campaign) => {
                spreadArray = [...spreadArray, ...campaign];
            });
            return [...spreadArray];
        });

        setMediumOptions((prev) => {
            let spreadArray = [];
            const selectedData = APIData.filter((item) => selectedSources.includes(item.source));
            const mediumArray = selectedData.map((item) => item.mediums);
            mediumArray.forEach((medium) => {
                spreadArray = [...spreadArray, ...medium];
            });
            return [...spreadArray];
        });

        if (selectedSources.length === APIData.map((item) => item.source).length) {
            setSourceCheckbox(true);
        }

        if (selectedSources.length < APIData.map((item) => item.source).length) {
            setSourceCheckbox(false);
        }

        if (selectedMediums.length === mediumOptions.length) {
            setMediumCheckbox(true);
        }

        if (selectedMediums.length < mediumOptions.length) {
            setMediumCheckbox(false);
        }

        if (selectedCampaigns.length === campaignOptions.length) {
            setCampaignCheckbox(true);
        }

        if (selectedCampaigns.length < campaignOptions.length) {
            setCampaignCheckbox(false);
        }
    }, [selectedSources, selectedCampaigns, selectedMediums]);

    const handelSubmit = async (values) => {
        try {
            setLoadingGif(true);
            setLoading(true);
            setSelectedDate(dateRange[0]);
            const data = {
                fromDate: moment(dateRange[0].startDate).startOf('day').toISOString(),
                toDate: moment(dateRange[0]?.endDate).endOf('day').toISOString(),
                lenderIds: values?.loanlender?.map((item) => item?.lenderId),
                lendersName: values.loanlender.map((item) => item.name),
                sources: selectedSources,
                mediums: selectedMediums,
                campaigns: selectedCampaigns
            };
            dispatch(GroupByLeadSliceAction.getGBDateSelectedFilters(data));

            const response = await getGroupByDate(data);
            setLoadingGif(false);
            dispatch(GroupByLeadSliceAction.getGBDate(response));
            enqueueSnackbar('Updated Successfully', { variant: 'success' });
            setLoading(false);
            setIsOpen(false);
            setFilterSources(selectedSources);
            setFilterCampaign(selectedCampaigns);
            setFilterMedium(selectedMediums);
            setFilterLenders(values.loanlender.map((item) => item.name));
            setFilterLendersId(data?.lenderIds);
        } catch (err) {
            console.error('Error in handelSubmit:', err);
            setLoading(false);
            enqueueSnackbar('Something went Wrong', { variant: 'error' });
        }
    };

    const formik = useFormik({
        initialValues: inititalValues,
        validationSchema: validationSchema,
        onSubmit: handelSubmit
    });

    const handleClearFilter = async () => {
        setLoadingGif(true);
        formik.resetForm();
        setFilterSources(['All']);
        setFilterLenders(['All']);
        setLoading(false);

        dispatch(GroupByLeadSliceAction.getGBDateSelectedFilters(null));

        let data = {
            fromDate: moment().subtract(7, 'day').startOf('day').toISOString(),
            toDate: moment().endOf('day').toISOString(),
            lenderIds: ['All'],
            sources: ['All'],
            mediums: ['All'],
            campaigns: ['All']
        };
        const response = await getGroupByDate(data);

        dispatch(GroupByLeadSliceAction.getGBDate(response));
        setDateRange([
            {
                startDate: new Date(startDate.setDate(endDate.getDate() - 6)),
                endDate: new Date()
            }
        ]);

        setSelectedDate({
            startDate: new Date(startDate.setDate(endDate.getDate() - 6)),
            endDate: new Date()
        });
        setSelectedSources(['All']);
        setSelectedCampaigns(['All']);
        setSelectedMediums(['All']);
        setLoadingGif(false);
    };

    const handleToggleFullscreen = () => {
        const element = childRef.current;
        if (!isFullscreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
                setIsFullscreen(true);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullscreen(false);
            }
        }
    };

    const onRefresh = () => {
        getPageData();
    };

    //Note: using staticRanges from utils

    const handleDateRangeChange = (item) => {
        if (item && item.selection) {
            setDateRange([item.selection]);
        }
    };

    return (
        <>
            <CommonDrawer onClose={toggleDrawer} open={isOpen}>
                <Box minWidth="500px" maxWidth="500px">
                    <Box px={1} component={'form'} onSubmit={formik.handleSubmit}>
                        <Box className="filter-header">
                            <Typography className="filter-title">Filters</Typography>
                            <Button onClick={handleClearFilter} variant="text" className="clear-filter">
                                Clear all
                            </Button>
                        </Box>
                        <Divider />
                        <Box mt={2}>
                            <CommonAccordion defaultExpanded={defaultDate} title={'Date'}>
                                <CommonDateRangePicker
                                    staticRanges={staticRanges}
                                    maxDate={new Date()}
                                    ranges={dateRange}
                                    onChange={handleDateRangeChange}
                                />
                            </CommonAccordion>
                            <CommonAccordion defaultExpanded={defaultSource} title={'Source'} count={selectedSources?.length}>
                                <Autocomplete
                                    size="small"
                                    te
                                    multiple
                                    limitTags={2}
                                    id="checkboxes-tags-demo"
                                    options={['All', ...APIData.map((item) => item.source)]}
                                    name="source"
                                    value={formik.values.sources}
                                    onChange={(event, value) => {
                                        let newValue = [];
                                        if (value.includes('All')) {
                                            newValue = ['All'];
                                        } else if (value?.length === APIData?.length) {
                                            newValue = ['All'];
                                        } else {
                                            newValue = value.filter((item) => item !== 'All');
                                        }

                                        formik.setFieldValue('sources', newValue, true);

                                        setSelectedSources(newValue);
                                    }}
                                    getOptionDisabled={(option) => (option === 'All' ? false : selectedSources.includes('All'))}
                                    disableCloseOnSelect
                                    disableClearable
                                    getOptionLabel={(option) => option}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                            <CapitalizeName value={option} />
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            size="small"
                                            error={formik.touched.sources && formik.errors.sources}
                                            helperText={formik.touched.sources && formik.errors.sources}
                                            {...params}
                                            placeholder="Source"
                                        />
                                    )}
                                />
                            </CommonAccordion>
                            <CommonAccordion defaultExpanded={defaultCampaign} title={'Campaign'} count={selectedCampaigns?.length}>
                                <Autocomplete
                                    size="small"
                                    te
                                    multiple
                                    limitTags={2}
                                    id="checkboxes-tags-demo"
                                    options={['All', ...campaignOptions]}
                                    value={selectedCampaigns}
                                    onChange={(event, value) => {
                                        let newValue = [];
                                        if (value.includes('All')) {
                                            newValue = ['All'];
                                        } else if (value?.length === campaignOptions?.length) {
                                            newValue = ['All'];
                                        } else {
                                            newValue = value.filter((item) => item !== 'All');
                                        }

                                        formik.setFieldValue('campaigns', newValue, true);
                                        setSelectedCampaigns(newValue);
                                    }}
                                    getOptionDisabled={(option) => (option === 'All' ? false : selectedCampaigns.includes('All'))}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                            <CapitalizeName value={option} />
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            size="small"
                                            error={formik.touched.campaigns && formik.errors.campaigns}
                                            helperText={formik.touched.campaigns && formik.errors.campaigns}
                                            {...params}
                                            placeholder="Campaign"
                                        />
                                    )}
                                />
                            </CommonAccordion>
                            <CommonAccordion defaultExpanded={defaultMedium} title={'Medium'} count={selectedMediums?.length}>
                                <Autocomplete
                                    size="small"
                                    te
                                    multiple
                                    id="checkboxes-tags-demo"
                                    limitTags={2}
                                    options={['All', ...mediumOptions]}
                                    value={selectedMediums}
                                    onChange={(event, value) => {
                                        let newValue = [];
                                        if (value.includes('All')) {
                                            newValue = ['All'];
                                        } else if (value?.length === mediumOptions?.length) {
                                            newValue = ['All'];
                                        } else {
                                            newValue = value.filter((item) => item !== 'All');
                                        }

                                        setSelectedMediums(newValue);
                                        formik.setFieldValue('medium', newValue, true);
                                    }}
                                    getOptionDisabled={(option) => (option === 'All' ? false : selectedMediums.includes('All'))}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                            <CapitalizeName value={option} />
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            size="small"
                                            error={formik.touched.medium && formik.errors.medium}
                                            helperText={formik.touched.medium && formik.errors.medium}
                                            {...params}
                                            placeholder="Medium"
                                        />
                                    )}
                                />
                            </CommonAccordion>
                            <CommonAccordion defaultExpanded={defaultLender} title={'Lenders'} count={filterLenders?.length}>
                                <Autocomplete
                                    size="small"
                                    te
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={lendersList}
                                    name="loanlender"
                                    value={formik.values.loanlender}
                                    onChange={(event, value) => {
                                        let newValue = [];
                                        if (value.some((option) => option.name === 'All')) {
                                            newValue = [lendersList[0]]; // Select only the 'All' option
                                        } else if (value.length === lendersList.length - 1) {
                                            newValue = [lendersList[0]]; // Select 'All' when all other options are selected
                                        } else {
                                            newValue = value.filter((option) => option.name !== 'All'); // Remove 'All' if other options are selected
                                        }

                                        formik.setFieldValue('loanlender', newValue);
                                    }}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option?.name?.charAt(0).toUpperCase() + option?.name?.slice(1)}
                                    getOptionDisabled={(option) =>
                                        option?.name === 'All'
                                            ? false
                                            : formik?.values?.loanlender?.some((option) => option?.name === 'All')
                                    }
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={
                                                    selected ||
                                                    (option.name === 'All' &&
                                                        formik.values.loanlender.some((option) => option.name === 'All'))
                                                }
                                            />
                                            {option.name.charAt(0).toUpperCase() + option.name.slice(1)}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            size="small"
                                            error={formik.touched.loanlender && formik.errors.loanlender}
                                            helperText={formik.touched.loanlender && formik.errors.loanlender}
                                            {...params}
                                            placeholder="Select Lenders"
                                        />
                                    )}
                                />
                            </CommonAccordion>

                            <Box
                                sx={{
                                    borderTop: '1px solid #DDE1E6',
                                    backgroundColor: '#fff'
                                }}
                            >
                                <Grid container justifyContent="flex-end" align-items="center">
                                    <Grid item sm={3} mt={2}>
                                        <LoadingButton loading={loading} type="submit" variant="contained" className="apply-button">
                                            Apply Filter
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item sm={2} mt={2}>
                                        <Button onClick={toggleDrawer} variant="outlined" className="cancel-button">
                                            Close
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </CommonDrawer>
            <Grid container>
                <Grid item sm={12}>
                    {loadingGif ? (
                        <CircularLoadingProgress />
                    ) : (
                        <Box>
                            <Box pl={1.5}>
                                <Grid container spacing={2}>
                                    <Filters title={<DateLeads />} onClick={() => toggleDrawer('date')}>
                                        <Box>
                                            <Chip label={moment(selectedDate?.startDate).format('ll')} sx={{ m: '2px' }} size="small" />
                                            {'-'} <Chip label={moment(selectedDate?.endDate).format('ll')} sx={{ m: '2px' }} size="small" />
                                        </Box>
                                    </Filters>
                                    <Filters title="Sources :" onClick={() => toggleDrawer('source')}>
                                        {filterSources.slice(0, 1).map((key) => (
                                            <span key={key}>
                                                <Chip label={key} sx={{ m: '2px' }} size="small" />
                                            </span>
                                        ))}
                                        {filterSources?.length > 1 && (
                                            <span style={{ cursor: 'pointer', color: '#000', paddingLeft: '4px' }}>
                                                {showAllChips ? 'Hide' : `+${filterSources?.length - 1}`}
                                            </span>
                                        )}
                                    </Filters>
                                    <Filters title="Lenders :" onClick={() => toggleDrawer('lenders')}>
                                        {filterLenders.slice(0, 2).map((key) => (
                                            <span key={key}>
                                                <Chip label={key} sx={{ m: '2px' }} size="small" />
                                            </span>
                                        ))}
                                        {filterLenders?.length > 2 && (
                                            <span style={{ cursor: 'pointer', color: '#000', paddingLeft: '4px' }}>
                                                {showAllChips ? 'Hide' : `+${filterLenders?.length - 1}`}
                                            </span>
                                        )}
                                    </Filters>
                                    <Filters>
                                        <Button
                                            sx={{ color: 'gray', margin: 'auto 0', padding: 0 }}
                                            startIcon={<AddOutlined />}
                                            onClick={toggleDrawer}
                                        >
                                            Filter
                                        </Button>
                                    </Filters>
                                    <Filters>
                                        <Button
                                            onClick={handleClearFilter}
                                            variant="text"
                                            sx={{ textDecoration: 'underline', color: 'gray', margin: 'auto 0', width: '100%', p: 0 }}
                                        >
                                            Clear All
                                        </Button>
                                    </Filters>
                                </Grid>
                            </Box>
                            <Box
                                spacing={isFullscreen ? 0 : 2}
                                ref={childRef}
                                className={`child-component ${isFullscreen ? 'fullscreen' : ''}`}
                            >
                                <CommonChart
                                    title={'Group By Date'}
                                    exportModuleName={'Group By Date Leads'}
                                    onToggleFullscreen={handleToggleFullscreen}
                                    isFullscreen={isFullscreen}
                                    onRefresh={onRefresh}
                                    rowData={GBDate?.groupbydate || []}
                                    columnDefs={columnDef || []}
                                    isGridRequired={true}
                                ></CommonChart>
                            </Box>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </>
    );
}
