import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    singleLeadLogDetail: [],
    allLeads: [],
    eligibleLenderTableData: [],
    filteredLogsData: [],
    postedLogsData: []
};

export const leadLoadDetailSlice = createSlice({
    name: 'leadlogDetails',
    initialState,
    reducers: {
        getLeads: (state, action) => {
            state.allLeads = action.payload;
        },
        getEligibleLender: (state, action) => {
            state.eligibleLenderTableData = action.payload;
        },
        getSingleLeadLogDetail: (state, action) => {
            state.singleLeadLogDetail = action.payload;
        },
        getFilteredLogData: (state, action) => {
            state.filteredLogsData = action.payload;
        },
        getPostedLogData: (state, action) => {
            state.postedLogsData = action.payload;
        }
    }
});

export const leadLogDetailAction = leadLoadDetailSlice.actions;
