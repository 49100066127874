import {
    Grid,
    useEffect,
    useState,
    useFormik,
    moment,
    useSnackbar,
    apiEndPointsConfig,
    Yup,
    Box,
    FilterComponent
} from '../../../collections/Imports';
import api from 'helpers/apiService';
import Kpi from './Kpi';
import PunchinStatsTable from './PunchinStatsTable';
import { getPunchInKpiCall, getPunchInLenderStatusCall } from 'helpers/credmudra/punchin';
import AmountStatus from './AmountStatus';
import LeadStatus from './LeadStatus';
import { useDispatch, useSelector } from 'react-redux';
import { punchinAction } from 'store/punchin/punchinSlice';
import FiltersForm from './FiltersForm';

export default function Anaytics() {
    const dispatch = useDispatch();
    const { selectedPunchinFilter } = useSelector((state) => state.punchIn);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [isOpen, setIsOpen] = useState(false);
    const [filterLenders, setFilterLenders] = useState(['All']);
    const [filterStatus, setFilterStatus] = useState(['All']);

    const [isSpLoading, setSpLoading] = useState(false);
    const [isLscLoading, setLscLoading] = useState(false);
    const [isPisLoading, setPisLoading] = useState(false);

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [selectedFilter, setSelectedFilter] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    });

    const [filters, setFilters] = useState([]);
    const [openElement, setOpenElement] = useState();

    const toggleDrawer = (ele) => {
        setOpenElement(ele);
        setIsOpen((prev) => !prev);
    };

    useEffect(() => {
        setFilters([
            {
                title: 'Date',
                data: [moment(selectedFilter?.startDate).format('LL'), moment(selectedFilter?.endDate).format('LL')],
                onClick: () => toggleDrawer('Date')
            },
            {
                title: 'Lenders:',
                data: [...filterLenders],
                onClick: () => toggleDrawer('lenders')
            },
            {
                title: 'Status:',
                data: [...filterStatus],
                onClick: () => toggleDrawer('status')
            }
        ]);
    }, [selectedFilter, filterLenders, filterStatus]);

    useEffect(() => {
        getPunchInData();
        getPunchInDataTableKpi();
    }, [selectedPunchinFilter]);

    useEffect(() => {
        fetchStatusFilters();
    }, []);

    const fetchStatusFilters = () => {
        api.get(apiEndPointsConfig.getLenderStatusFilters)
            .then((response) => {
                dispatch(punchinAction.getPunchInStatus(response.data.data));
            })
            .catch((error) => {
                console.error(error);
                enqueueSnackbar('Something went Wrong', { variant: 'error' });
            });
    };

    const getPunchInData = async () => {
        setSpLoading(true);
        setLscLoading(true);
        setPisLoading(true);
        if (selectedPunchinFilter !== null) {
            const { fromDate, toDate, lenderIds, status, lenderName } = selectedPunchinFilter;

            const reqData = {
                fromDate: fromDate,
                toDate: toDate,
                lenderIds: lenderIds,
                status: status,
                lenderName: lenderName
            };
            try {
                setSelectedFilter({
                    startDate: fromDate,
                    endDate: toDate,
                    key: 'selection'
                });

                setDateRange([
                    {
                        startDate: fromDate ? new Date(fromDate) : new Date(),
                        endDate: toDate ? new Date(toDate) : new Date(),
                        key: 'selection'
                    }
                ]);

                setFilterLenders([...lenderName]);
                setFilterStatus([...status]);
                const response1 = await getPunchInKpiCall(reqData);
                const response2 = await getPunchInLenderStatusCall(reqData);

                dispatch(punchinAction.getPunchInKpi(response1));
                dispatch(punchinAction.getPunchInLenderStats(response2));
                setSpLoading(false);
                setLscLoading(false);
                setPisLoading(false);
            } catch (error) {
                console.error('Error fetching lead status:', error);
                setSpLoading(false);
                setLscLoading(false);
                setPisLoading(false);
            }
        } else {
            const requestData = {
                fromDate: moment().startOf('day').toISOString(),
                toDate: moment().endOf('day').toISOString(),
                lenderIds: ['All'],
                status: ['All'],
                lenderName: ['All']
            };
            try {
                const response1 = await getPunchInKpiCall(requestData);
                dispatch(punchinAction.getPunchInKpi(response1));
                const response2 = await getPunchInLenderStatusCall(requestData);
                dispatch(punchinAction.getPunchInLenderStats(response2));
                dispatch(punchinAction.getSelectedPunchinFilter(requestData));
                setSpLoading(false);
                setLscLoading(false);
                setPisLoading(false);
            } catch (error) {
                console.error('Error fetching lead status:', error);
                setSpLoading(false);
                setLscLoading(false);
                setPisLoading(false);
            }
        }
    };

    const getPunchInDataTableKpi = async () => {
        const requestData = {
            fromDate: moment().startOf('day').toISOString(),
            toDate: moment().endOf('day').toISOString(),
            lenderIds: ['All'],
            status: ['All'],
            lenderName: ['All']
        };
        try {
            const response = await getPunchInLenderStatusCall(requestData);
            dispatch(punchinAction.getPunchInLenderStats(response));
            setLoading(false);
        } catch (error) {
            console.error('Error fetching lead status:', error);
            setLoading(false);
        }
    };

    const validationSchema = Yup.object().shape({
        loanlender: Yup.array().min(1, 'Please select at least one lender').required('required'),
        loanstatus: Yup.array().min(1, 'Please select at least one status').required('required')
    });

    const inititalValues = {
        loanstatus: ['All'],
        loanlender: [{ lenderId: 'All', name: 'All' }],
        startDate: moment().startOf('day').toISOString(),
        endDate: moment().endOf('day').toISOString()
    };

    const formik = useFormik({
        initialValues: inititalValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setLoading(true);
            setSelectedFilter(dateRange[0]);
            const requestData = {
                lenderIds: values.loanlender.map((item) => item.lenderId),
                lenderName: values.loanlender.map((item) => item.name),
                status: values.loanstatus,
                fromDate: moment(dateRange[0].startDate).startOf('day').toISOString(),
                toDate: moment(dateRange[0]?.endDate).endOf('day').toISOString()
            };

            setFilterLenders(values.loanlender.map((item) => item.name));
            setFilterStatus(values.loanstatus.map((item) => item));
            dispatch(punchinAction.getSelectedPunchinFilter(requestData));
            enqueueSnackbar('Updated Successfully', { variant: 'success' });
            setLoading(false);
            setIsOpen(false);
        }
    });

    const onAmountRefresh = async () => {
        setSpLoading(true);
        if (selectedPunchinFilter !== null) {
            try {
                const response = await getPunchInKpiCall(selectedPunchinFilter);
                dispatch(punchinAction.getPunchInKpi(response));
                enqueueSnackbar('Updated Successfully', { variant: 'success' });
                setSpLoading(false);
            } catch (error) {
                console.error('Error fetching lead status:', error);
                enqueueSnackbar('Something went Wrong', { variant: 'error' });
                setSpLoading(false);
            }
        } else {
            enqueueSnackbar('You Need to select Filter First', { variant: 'info' });
        }
    };
    const onLeadStatusRefresh = async () => {
        setLscLoading(true);
        if (selectedPunchinFilter !== null) {
            try {
                const response = await getPunchInLenderStatusCall(selectedPunchinFilter);
                dispatch(punchinAction.getPunchInLenderStats(response));
                enqueueSnackbar('Updated Successfully', { variant: 'success' });
                setLscLoading(false);
            } catch (error) {
                console.error('Error fetching lead status:', error);
                enqueueSnackbar('Something went Wrong', { variant: 'error' });
                setLscLoading(false);
            }
        } else {
            enqueueSnackbar('You Need to select Filter First', { variant: 'info' });
        }
    };
    const onStatsTableRefresh = async () => {
        setPisLoading(true);
        if (selectedPunchinFilter !== null) {
            try {
                const response = await getPunchInLenderStatusCall(selectedPunchinFilter);
                dispatch(punchinAction.getPunchInLenderStats(response));
                enqueueSnackbar('Updated Successfully', { variant: 'success' });
                setPisLoading(false);
            } catch (error) {
                console.error('Error fetching lead status:', error);
                enqueueSnackbar('Something went Wrong', { variant: 'error' });
                setPisLoading(false);
            }
        } else {
            enqueueSnackbar('You Need to select Filter First', { variant: 'info' });
        }
    };

    const handleClearFilter = () => {
        formik.resetForm();
        setDateRange([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ]);
        setSelectedFilter([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ]);
        setFilterLenders(['All']);
        setFilterStatus(['All']);
        const requestData = {
            fromDate: moment().startOf('day').toISOString(),
            toDate: moment().endOf('day').toISOString(),
            lenderIds: ['all'],
            status: ['All'],
            lenderName: ['All']
        };
        dispatch(punchinAction.getSelectedPunchinFilter(requestData));
    };

    const handleDateRangeChange = (item) => {
        if (item && item.selection) {
            setDateRange([item.selection]);
        }
    };

    return (
        <>
            <FiltersForm
                isOpen={isOpen}
                toggleDrawer={toggleDrawer}
                handleClearFilter={handleClearFilter}
                filterLenders={filterLenders}
                filterStatus={filterStatus}
                dateRange={dateRange}
                handleDateRangeChange={handleDateRangeChange}
                loading={loading}
                formik={formik}
                openElement={openElement}
            />
            <Grid container spacing={2} pl={'5px'}>
                <Grid item sm={12}>
                    <Box sx={{ marginTop: { xs: '20px', md: '0' } }}>
                        <FilterComponent toggleDrawer={toggleDrawer} handleClearFilter={handleClearFilter} filters={filters} />
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <Box mt={0}>
                        <Kpi />
                    </Box>
                </Grid>
                <Grid item sm={6}>
                    <Box mt={0}>
                        <AmountStatus onAmountRefresh={onAmountRefresh} isSpLoading={isSpLoading} />
                    </Box>
                </Grid>
                <Grid item sm={6}>
                    <Box mt={0}>
                        <LeadStatus onLeadStatusRefresh={onLeadStatusRefresh} isLscLoading={isLscLoading} />
                    </Box>
                </Grid>
                <Grid item sm={12}>
                    <Box mt={1}>
                        <PunchinStatsTable onStatsTableRefresh={onStatsTableRefresh} isPisLoading={isPisLoading} />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
