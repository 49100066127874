import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allLeads: [],
    SelectedGBPFilter: null,
    submitedLeads: [],
    marketPlaceClickData: [],
    marketPlaceImpressionData: [],
    marketPlaceElligible: [],
    marketPlaceDropOff: [],
    gBPMPClickedLender: []
};
export const groupByPublisherSlice = createSlice({
    name: 'groupByPublisher',
    initialState,
    reducers: {
        getAllLeads: (state, action) => {
            state.allLeads = action.payload;
        },
        getSelectedGBPFilter: (state, action) => {
            state.SelectedGBPFilter = action.payload;
        },
        getSubmitedLeads: (state, action) => {
            state.submitedLeads = action.payload;
        },
        getMarketPlaceClickData: (state, action) => {
            state.marketPlaceClickData = action.payload;
        },
        getMarketPlaceImpressionData: (state, action) => {
            state.marketPlaceImpressionData = action.payload;
        },
        getMarketPlaceElligible: (state, action) => {
            state.marketPlaceElligible = action.payload;
        },
        getMarketPlaceDropOff: (state, action) => {
            state.marketPlaceDropOff = action.payload;
        },
        getGBPMPClickedLender: (state, action) => {
            state.gBPMPClickedLender = action.payload;
        }
    }
});

export const groupByPublisherAction = groupByPublisherSlice.actions;
