import React from 'react';
import {
    useState,
    Box,
    Grid,
    Button,
    moment,
    useTheme,
    AddOutlined,
    Typography,
    Divider,
    LoadingButton,
    PageHeader,
    Filters,
    useEffect,
    Autocomplete,
    TextField,
    useFormik,
    CommonAccordion,
    apiEndPointsConfig,
    Chip,
    CheckBoxOutlineBlankIcon,
    CheckBoxIcon,
    Checkbox,
    createAgeRanges,
    createSalaryRanges,
    createLoanRanges,
    Yup,
    useDispatch,
    CommonDateRangePicker,
    CheckboxGroup,
    DateLeads,
    CommonDrawer,
    useSelector,
    staticRanges
} from '../../collections/Imports';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Popover from '@mui/material/Popover';
import DSAKpis from './components/kpis';
import LeadPerStatus from './components/leadPerStatus';
import TrendOfDisbursal from './components/trendOfDisbursal';
import TopDSALeads from './components/topDSALeads';
import TopDSADisbursal from './components/topDSADisbursal';
import BottomDSALeads from './components/bottomDSALeads';
import BottomDSADisbursal from './components/bottomDSADisbursal';
import { getBottomDSALead, getDSADisbursalReport, getDSAKpis, getDSALenderStatus, getTopDSALead } from 'helpers/credmudra/dsa';
import { dsaDashboardSliceAction } from 'store/dsa-dashboard/dsaDashboardSlice';

const DSADashboard = () => {
    const dispatch = useDispatch();
    const [isApply, setIsApply] = useState(true);

    const startDate = new Date();
    const endDate = new Date();

    const [selectedFilter, setSelectedFilter] = useState({
        startDate: new Date(startDate.setDate(endDate.getDate() - 6)),
        endDate: new Date(),
        key: 'selection'
    });
    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(startDate.setDate(endDate.getDate() - 6)),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    useEffect(() => {
        const payload = {
            fromDate: moment(dateRange[0].startDate).startOf('day').toISOString(),
            toDate: moment(dateRange[0]?.endDate).endOf('day').toISOString()
        };
        getKpisData(payload);
    }, []);
    const getKpisData = async (data) => {
        const kpiResult = await getDSAKpis(data);
        const dsaLenderStatusResult = await getDSALenderStatus(data);
        const dsaTrendOfDisbursalResult = await getDSADisbursalReport(data);
        // const topDSALeadResult = await getTopDSALead(data);
        // const bottomDSALeadResult = await getBottomDSALead(data);
        if (!kpiResult.error) {
            dispatch(dsaDashboardSliceAction.setDSAKPIs(kpiResult.data));
        }
        if (!dsaLenderStatusResult.error) {
            dispatch(dsaDashboardSliceAction.setLeadPerStatus(dsaLenderStatusResult.data));
        }
        if (!dsaTrendOfDisbursalResult.error) {
            dispatch(dsaDashboardSliceAction.setTrendOfDisbursal(dsaTrendOfDisbursalResult.data));
        }
        // if (topDSALeadResult?.data.length) {
        //     dispatch(dsaDashboardSliceAction.setTopDSALeads(topDSALeadResult.data));
        // }
        // if (bottomDSALeadResult?.data.length) {
        //     dispatch(dsaDashboardSliceAction.setBottomDSALeads(bottomDSALeadResult.data));
        // }
    };
    const handleDateRangeChange = (item) => {
        setIsApply(false);
        if (item && item.selection) {
            setDateRange([item.selection]);
            setSelectedFilter(item.selection);
        }
    };
    const applyFilter = async () => {
        setIsApply(true);
        const payload = {
            fromDate: moment(dateRange[0].startDate).startOf('day').toISOString(),
            toDate: moment(dateRange[0]?.endDate).endOf('day').toISOString()
        };
        getKpisData(payload);
    };
    const handleClearFilter = () => {
        setIsApply(true);

        setDateRange([
            {
                startDate: new Date(startDate.setDate(endDate.getDate() - 6)),
                endDate: new Date(),
                key: 'selection'
            }
        ]);
        setSelectedFilter({
            startDate: new Date(startDate.setDate(endDate.getDate() - 6)),
            endDate: new Date(),
            key: 'selection'
        });
        const payload = {
            fromDate: moment(new Date(startDate.setDate(endDate.getDate() - 6)))
                .startOf('day')
                .toISOString(),
            toDate: moment(new Date(startDate.setDate(endDate.getDate() - 6)))
                .endOf('day')
                .toISOString()
        };
        getKpisData(payload);
    };
    return (
        <>
            <Box sx={{ marginTop: { xs: '25px', md: '0' }, marginLeft: { xs: '-55px', md: '0' } }}>
                <PageHeader title={'DSA-Dashboard'} helpText={'DSA Lead Analytics Dashboard'} />
                <Box
                    p={4}
                    width={'100%'}
                    sx={{
                        '@media (max-width: 900px)': { marginLeft: '-20%', paddingLeft: 0, width: '750px' }
                    }}
                >
                    <Grid container spacing={2} pl={1.5}>
                        <PopupState variant="popover" popupId="demo-popup-popover">
                            {(popupState) => (
                                <div>
                                    <Filters title={<DateLeads />} {...bindTrigger(popupState)}>
                                        <Box>
                                            <Chip label={moment(selectedFilter?.startDate).format('ll')} sx={{ m: '2px' }} size="small" />
                                            {'-'}{' '}
                                            <Chip label={moment(selectedFilter?.endDate).format('ll')} sx={{ m: '2px' }} size="small" />
                                        </Box>
                                    </Filters>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center'
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center'
                                        }}
                                    >
                                        <CommonDateRangePicker
                                            staticRanges={staticRanges}
                                            maxDate={new Date()}
                                            ranges={dateRange}
                                            onChange={handleDateRangeChange}
                                        />
                                    </Popover>
                                </div>
                            )}
                        </PopupState>
                        <Filters onClick={applyFilter}>
                            <Button disabled={isApply} sx={{ color: isApply ? 'gray' : '#2a4bc0', margin: 'auto 0', padding: 0 }}>
                                Apply
                            </Button>
                        </Filters>
                        <Filters onClick={handleClearFilter}>
                            <Button
                                variant="text"
                                sx={{ textDecoration: 'underline', color: 'gray', margin: 'auto 0', width: '100%', p: 0 }}
                            >
                                Clear All
                            </Button>
                        </Filters>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} justifyContent="center" display="flex" sx={{ mt: '8px' }}>
                            <DSAKpis />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} justifyContent="center" display="flex" sx={{ height: '700px' }}>
                            <LeadPerStatus />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} justifyContent="center" display="flex" lg={6}>
                            <TrendOfDisbursal />
                        </Grid>
                        {/* <Grid item xs={12} md={6} lg={6} justifyContent="center" display="flex">
                            <TopDSALeads />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} justifyContent="center" display="flex">
                            <TopDSADisbursal />
                        </Grid>

                        <Grid item xs={12} md={6} lg={6} justifyContent="center" display="flex">
                            <BottomDSALeads />
                        </Grid>
                        <Grid item xs={12} md={6} lg={6} justifyContent="center" display="flex">
                            <BottomDSADisbursal />
                        </Grid> */}
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default DSADashboard;
