import apiEndPointsConfig from 'helpers/apiEndPointsConfig';
import apiService from 'helpers/apiService';
export const getGroupByDate = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.GROUP_BY_DATE, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const groupByDateReport = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.GROUP_BY_DATE_REPORT, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const groupByPublisherReport = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.GROUP_BY_PUBLISHERS_REPORT, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const groupByPublisher = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.GROUP_BY_PUBLISHERS, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const getGBPMPClickedLender = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.getGBPMPClickedLender, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const groupByDateLenders = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.GROUP_BY_DATE_ClICK_LENDER, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const groupByDateMpClicks = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.DATE_WISE_MP_CLICK, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};
export const groupMpLenderLeadClickLog = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.MP_LENDER_LEAD_CLICK_LOG, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};
