import { useRoutes } from '../collections/Imports';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { useSelector } from 'react-redux';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const allNav = useSelector((state) => state?.userRepo?.allRepo);

    return useRoutes([MainRoutes(allNav), AuthenticationRoutes]);
}
