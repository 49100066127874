import React, { useEffect } from 'react';
import {
    Autocomplete,
    Checkbox,
    Chip,
    Grid,
    TextField,
    LoadingButton,
    Box,
    Button,
    Typography,
    Divider,
    CommonAccordion,
    CommonDateRangePicker,
    staticRanges,
    CommonDrawer,
    CheckBoxOutlineBlankIcon,
    CheckBoxIcon
} from 'collections/Imports';
import useLenderFilters from 'hooks/useLendersFilters';
import CapitalizeName from 'Components/ui-component/capitalize-name';
import { useSelector } from 'react-redux';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FiltersForm = ({
    isOpen,
    toggleDrawer,
    handleClearFilter,
    filterLenders,
    filterStatus,
    dateRange,
    handleDateRangeChange,
    loading,
    formik,
    openElement
}) => {
    const lender = useLenderFilters();
    const { selectedPunchinFilter, status } = useSelector((state) => state.punchIn);

    useEffect(() => {
        if (selectedPunchinFilter) {
            const { status, lenderIds, empType } = selectedPunchinFilter;

            if (lender?.length) {
                const filteredLenderObjects = lender.filter((lender) => lenderIds.includes(lender.lenderId));
                formik.setFieldValue('loanlender', filteredLenderObjects);
            }

            formik.setFieldValue('loanstatus', status, true);
            formik.setFieldValue('empType', empType, true);
        }
    }, [lender, selectedPunchinFilter]);

    return (
        <CommonDrawer anchor="right" open={isOpen} onClose={toggleDrawer}>
            <Box minWidth="500px" maxWidth="500px">
                <Box px={1} component={'form'} onSubmit={formik.handleSubmit}>
                    <Box className="filter-header">
                        <Typography className="filter-title">Filters</Typography>
                        <Button onClick={handleClearFilter} variant="text" className="clear-filter">
                            Clear all
                        </Button>
                    </Box>
                    <Divider />
                    <Box mt={2}>
                        <CommonAccordion defaultExpanded={openElement === 'lenders'} title={'Lenders'} count={filterLenders?.length}>
                            <Autocomplete
                                size="small"
                                multiple
                                id="checkboxes-tags-demo"
                                options={lender}
                                name="loanlender"
                                value={formik.values.loanlender}
                                onChange={(event, value) => {
                                    let newValue = [];
                                    if (value.some((option) => option.name === 'All')) {
                                        newValue = [lender[0]]; // Select only the 'All' option
                                    } else if (value.length === lender.length - 1) {
                                        newValue = [lender[0]]; // Select 'All' when all other options are selected
                                    } else {
                                        newValue = value.filter((option) => option.name !== 'All'); // Remove 'All' if other options are selected
                                    }
                                    formik.setFieldValue('loanlender', newValue); // Update formik values
                                }}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option?.name?.charAt(0).toUpperCase() + option?.name?.slice(1)}
                                getOptionDisabled={(option) =>
                                    option?.name === 'All' ? false : formik?.values?.loanlender?.some((option) => option?.name === 'All')
                                }
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={
                                                selected ||
                                                (option.name === 'All' && formik.values.loanlender.some((option) => option.name === 'All'))
                                            }
                                        />
                                        <CapitalizeName value={option.name.charAt(0).toUpperCase() + option.name.slice(1)} />
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        error={formik.touched.loanlender && formik.errors.loanlender}
                                        helperText={formik.touched.loanlender && formik.errors.loanlender}
                                        {...params}
                                        placeholder="Select Lenders"
                                    />
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            size="small"
                                            variant="outlined"
                                            label={option.name.charAt(0).toUpperCase() + option.name.slice(1)}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                            />
                        </CommonAccordion>

                        <CommonAccordion defaultExpanded={openElement === 'status'} title={'Status'} count={filterStatus?.length}>
                            <Autocomplete
                                size="small"
                                multiple
                                id="checkboxes-tags-demo"
                                name="loanstatus"
                                options={status}
                                value={formik.values.loanstatus}
                                onChange={(event, value) => {
                                    let newValue = [];
                                    if (value.includes('All')) {
                                        newValue = ['All']; // Select only the 'All' option
                                    } else if (value.length === status.length - 1) {
                                        newValue = ['All']; // Select 'All' when all other options are selected
                                    } else {
                                        newValue = value.filter((option) => option !== 'All'); // Remove 'All' if other options are selected
                                    }

                                    formik.setFieldValue('loanstatus', newValue);
                                }}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option?.charAt(0).toUpperCase() + option.slice(1)}
                                getOptionDisabled={(option) => (option === 'All' ? false : formik.values.loanstatus.includes('All'))}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props} style={{ borderBottom: '1px solid #ccc', padding: '8px' }}>
                                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                                        {option.charAt(0).toUpperCase() + option.slice(1)}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        size="small"
                                        error={formik.touched.loanstatus && formik.errors.loanstatus}
                                        helperText={formik.touched.loanstatus && formik.errors.loanstatus}
                                        {...params}
                                        placeholder="Select Status"
                                    />
                                )}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            size="small"
                                            variant="outlined"
                                            label={option.charAt(0).toUpperCase() + option.slice(1)}
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                            />
                        </CommonAccordion>

                        <CommonAccordion defaultExpanded={openElement === 'Date'} title={'Date'}>
                            <CommonDateRangePicker
                                staticRanges={staticRanges}
                                maxDate={new Date()}
                                ranges={dateRange}
                                onChange={handleDateRangeChange}
                            />
                        </CommonAccordion>

                        <Box
                            sx={{
                                borderTop: '1px solid #DDE1E6',
                                backgroundColor: '#fff'
                            }}
                        >
                            <Grid container justifyContent="flex-end" align-items="center">
                                <Grid item sm={3} mt={2}>
                                    <LoadingButton loading={loading} type="submit" variant="contained" className="apply-button">
                                        Apply Filter
                                    </LoadingButton>
                                </Grid>
                                <Grid item sm={2} mt={2}>
                                    <Button onClick={toggleDrawer} variant="outlined" className="cancel-button">
                                        Close
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </CommonDrawer>
    );
};

export default FiltersForm;
