import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tabValuesPunchin: 1
};

export const tabValuesPunchinSlice = createSlice({
    name: 'tabValuesPunchin',
    initialState,
    reducers: {
        getTabValuesPunchin: (state, action) => {
            state.tabValuesPunchin = action.payload;
        }
    }
});

export const tabValuesPunchinSliceAction = tabValuesPunchinSlice.actions;
