import { createTheme, ThemeProvider } from '@mui/material/styles';
import api from '../../helpers/apiService';
import apiEndPointsConfig from 'helpers/apiEndPointsConfig';
import LoginLogo from '../../assets/images/login-logo.png';
import LoginBackground from '../../assets/images/login-bg.png';
import {
    Box,
    Typography,
    FormGroup,
    FormLabel,
    TextField,
    Button,
    LoadingButton,
    useSnackbar,
    useNavigate,
    Yup,
    useFormik,
    useState
} from '../../collections/Imports';
import logo from '../../assets/images/logo-cred.png';
import { useDispatch } from 'react-redux';
import { userRepoActions } from 'store/userRepo/UserRepo';
import { addAllPermissionsStatusActions } from 'store/permissionsState';
const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required')
});

const defaultTheme = createTheme();
const Login = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const onSubmit = (values) => {
        setLoading(true);
        const data = {
            emailId: values.email,
            password: values.password
        };
        api.post(apiEndPointsConfig.login, { data })
            .then((res) => {
                if (res?.data?.data?.token?.roles?.includes('lds') && !res?.data?.error) {
                    localStorage.setItem('accessToken', res.data.data.token.accessToken);
                    localStorage.setItem('username', res.data.data.token.username);
                    localStorage.setItem('refressToken', res.data.data.token.refreshToken);
                    setLoading(false);
                    enqueueSnackbar('Login Successfully', { variant: 'success' });

                    api.get(apiEndPointsConfig.loginRepo, { data: '' }).then((res) => {
                        const permissionData = [...res.data.data];
                        const data = [];

                        permissionData.map((res) => {
                            return data.push({
                                route: res?.to,
                                permissions: {
                                    readContent: !!res?.permissions.includes('Read Content'),
                                    writeContent: !!res?.permissions.includes('Write Content'),
                                    modifyContent: !!res?.permissions.includes('Modify Content'),
                                    publishContent: !!res?.permissions.includes('Publish Content'),
                                    admin: !!res?.permissions.includes('Admin')
                                }
                            });
                        });
                        dispatch(userRepoActions.getRepo(res.data.data));

                        if (res) {
                            navigate(`${res.data.data[0]?.to}`);
                        } else {
                            enqueueSnackbar('Something went wrong', { variant: 'error' });
                        }

                        dispatch(addAllPermissionsStatusActions.addAllPermissionsStatus(data));
                    });
                } else {
                    setLoading(false);
                    enqueueSnackbar(res?.data?.error ? res?.data?.data : 'Not Authorized ', { variant: 'error' });
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                enqueueSnackbar('Failed to Login', { variant: 'error' });
            });
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            password: ''
        },
        validationSchema: validationSchema,
        onSubmit: onSubmit
    });

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex', position: 'fixed', width: '100%', height: '100%' }}>
                <Box sx={{ width: '20px', backgroundColor: '#0e356cd9' }}></Box>
                <Box sx={{ width: '100%' }} bgcolor="#0E356C" position="relative">
                    <Box position="absolute" height="100%" right={1} bottom={1}>
                        <img src={LoginBackground} alt="Login Background" width="100%" height="100%" />
                    </Box>
                    <Box display="flex" alignItems="center" mx={4} gap={2} sx={{ position: 'absolute', top: '2rem' }}>
                        <img src={LoginLogo} alt="Login Logo" />
                        <Typography variant="p" color="white" fontWeight="700" fontSize="20px">
                            Lead Distribution System
                        </Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" alignItems="center" height={'100%'}>
                        <Box width="550px" bgcolor="white" py={6} px={4} m={'10px'} borderRadius={3}>
                            <form
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '20px'
                                }}
                                onSubmit={formik.handleSubmit}
                            >
                                <img style={{ width: '50%', margin: '0 auto' }} src={logo} alt="logo" />

                                <Typography color="#0E356C" fontWeight="600" fontSize="24px">
                                    Log in
                                </Typography>
                                <FormGroup>
                                    <FormLabel htmlFor="email">Username</FormLabel>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        id="email"
                                        name="email"
                                        // label="Email"
                                        onChange={formik.handleChange}
                                        error={!!(formik.touched.email && formik.errors.email)}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <FormLabel htmlFor="password">Password</FormLabel>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        id="password"
                                        name="password"
                                        // label="Password"
                                        type="password"
                                        onChange={formik.handleChange}
                                        error={!!(formik.touched.password && formik.errors.password)} //NOTE: This simple i am using formik.touched.password && formik.errors.password ? true : false
                                        helperText={formik.touched.password && formik.errors.password}
                                    />
                                </FormGroup>
                                <Box display="flex" gap={2}>
                                    <LoadingButton
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            backgroundColor: '#EE9329',
                                            textTransform: 'capitalize'
                                        }}
                                        loading={loading}
                                        loadingPosition="start"
                                        disableElevation
                                    >
                                        {!loading ? 'Log in' : ''}
                                    </LoadingButton>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            textTransform: 'capitalize',
                                            border: '1px solid gray',
                                            color: 'gray'
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </ThemeProvider>
    );
};
export default Login;
