import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lenderOptions: [],
    trendReportData: [],
    trendReportFilters: null
};

export const lenderTrendReportSlice = createSlice({
    name: 'lenderTrendReportLenders',
    initialState,
    reducers: {
        getTrendReportData: (state, action) => {
            state.trendReportData = action.payload;
        },

        getLenderOptions: (state, action) => {
            state.lenderOptions = action.payload;
        },
        getTrendReportFilters: (state, action) => {
            state.trendReportFilters = action.payload;
        }
    }
});

export const trendReportSliceAction = lenderTrendReportSlice.actions;
