import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    callDispositions: [],
    cmCrmStatus: [],
    allData: []
};
export const crmStatusSlice = createSlice({
    name: 'crmStatus',
    initialState,
    reducers: {
        getCallDispositions: (state, action) => {
            state.callDispositions = action.payload;
        },
        getCmCrmStatus: (state, action) => {
            state.cmCrmStatus = action.payload;
        },
        getCrmReportTable: (state, action) => {
            state.allData = action.payload;
        }
    }
});

export const crmStatusAction = crmStatusSlice.actions;
