import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allLeads: [],
    selectedAllLeads: null,
    leadsOffUtmSource: [],
    lender: [],
    status: []
};
export const eligibleLeadSlice = createSlice({
    name: 'eligibleLeads',
    initialState,
    reducers: {
        getLeads: (state, action) => {
            state.allLeads = action.payload;
        },
        getSelectedAllLeads: (state, action) => {
            state.selectedAllLeads = action.payload;
        },
        getLeadsOffUtmSource: (state, action) => {
            state.leadsOffUtmSource = action.payload;
        },
        getLenders: (state, action) => {
            state.lender = action.payload;
        },
        getStatus: (state, action) => {
            state.status = action.payload;
        }
    }
});

export const eligibleLeadActions = eligibleLeadSlice.actions;
