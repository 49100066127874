import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lenderStats: []
};

export const lenderStatsSlice = createSlice({
    name: 'lenderStats',
    initialState,
    reducers: {
        getLenderStats: (state, action) => {
            state.lenderStats = action.payload;
        },
        removeLenderStats: (state, action) => {
            state.lenderStats = [];
        }
    }
});

export const lenderStatsAction = lenderStatsSlice.actions;
