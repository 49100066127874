const apiEndPointsConfig = {
  //NOTE --  lenders

  getLenderNameFilters: "/lds/lenders/name-filters",
  getLenderStatusFilters: "/lds/lenders/status-filters",
  updateLender: "/lds/lenders/update-poc-info",
  addLender: "/lds/lenders/add-lender",
  lenderfilter: "/lds/lenders/get-all-poc",

  //NOTE --getPunchinFilters: '/lds/leads/lead-report',

  getLenders: "/lds/lenders/get-lenders",
  getLender: "/lds/lenders/get-lender",
  setLenderPriority: "/lds/lenders/set-lender-priority",
  setLenderStatus: "/lds/lenders/set-lender-status",
  LenderKpi: "/lds/lenders/lender-kpi",
  ResponseTime: "/lds/lenders/lender-response-time",
  LenderStatsGrid: "/lds/lenders/lender-stats",
  LenderMarketPlaceImpression: "/lds/lenders/mp-impression-report",
  LenderMarketPlaceClickCount: "/lds/leads/mp-click-report",
  GetLendersTrendReport: "/lds/lenders/trend-report",

  //NOTE -- leads

  getFlexMonster: "/lds/leads/flex-monster",
  getLeadReport: "/lds/leads/lead-report",
  nonEligibleLeads: "/lds/leads/non-eligible-leads",
  DEDUPE_LEADS: "/lds/leads/dedupe-leads",
  LEAD_LOGS: "/lds/leads/lead-logs",
  LEAD_LOGS_DETAILS: "/lds/leads/lead-log-detail",
  Get_Profile_Details: "/lds/leads/get-profile-details",
  Get_Applied_Loans: "/lds/leads/get-applied-loans",
  Get_Lander_Status: "/lds/leads/user-kpi",
  LEAD_LOGS_DETAILS_Eligible_Lenders: "/lds/leads/eligible-lenders",
  Get_Customers: "/lds/leads/get-customers",
  GOOGLE_EXIT_PAGE: "/lds/leads/get-google-exit-page-impression",
  GET_TOTAL_LEAD: "/lds/lenders/total-leads",
  GET_FILTERED_LEAD_LOG: "/lds/leads/get-filtered-lead-log", // based on "status": filtered and posted in lead log

//Promotional Offers
 
addOffers: "/lds/offers/add-offers",
Get_Offers: "/lds/offers/get-offers",
Update_Offers: "/lds/offers/update-offers",
SET_Offer_STATUS: "/lds/offers/update-offer-status",

  //NOTE --insights

  LenderLeadStatus: "/lds/insights/lender-lead-status",
  LenderPerformingStatus: "/lds/insights/lender-performing-status",
  PeekAndMap: "/lds/insights/peek-and-map",
  LeadsStats: "/lds/insights/leads-stats",
  sourcePerformance: "/lds/insights/sources-performance",
  TotalLeads: "/lds/insights/get-user-leads-count",

  //NOTE --public

  login: "/lds/public/agent-login",
  loginRepo: "/lds/users/get-repository",

  //NOTE -- status

  getStatus: "/lds/status/get-status",
  createStatus: "/lds/status/create-status",
  misupload: "/lds/uploads/mis-upload",
  updateStatus: "/lds/status/update-status",

  //NOTE --drop-off

  getdropoffs: "/lds/marketing/get-drop-offs",
  getDropOffKPI: "/lds/marketing/get-dropoff-kpi",
  getUtmSource: "/lds/marketing/get-utm-sources",
  getUtmWiseStats: "/lds/marketing/utm-wise-stats",
  getStageWiseStats: "/lds/marketing/stage-wise-stats",
  gridStages: "/lds/marketing/stage-drop-offs",
  // Lander credit policy
  getCreditPolicy: "/lds/lenders/get-credit-policy",
  updateCreditPolicy: "/lds/lenders/update-credit-policy",
  //NOTE --exit-page-tracker

  getExitPagelenders: "/lds/lenders/get-exit-page-lenders",
  setExitPageStatus: "/lds/lenders/set-exit-lender-status",
  getClickDetails: "/lds/lenders/get-click-details",
  getImpression: "/lds/lenders/get-impression",

  //NOTE --settings
  getUnSubscriberUser: "/lds/users/get-unSubs-User", //NOTE-- all unsub users
  createUnSubscriberUser: "/lds/users/unSubs-user", //NOTE -- create selected user
  getUserlistByContacts: "/lds/users/get-user-byContact", // NOTE -- get by contact
  removeUnsubscribeUSer: "/lds/users/remove-UnSubs-user", //NOTE -- remove

  //NOTE -- Schedule
  getSchedulerData: "/lds/scheduler/get-scheduler",
  playPause: "/lds/scheduler/play-pause",
  getSchedulerModules: "/lds/scheduler/scheduler-modules",
  createScheduler: "/lds/scheduler/create-scheduler",
  updateScheduler: "/lds/scheduler/update-scheduler",

  //NOTE -- Punchin
  PunchInKPI: "/lds/punchin/punchin-kpi",
  PunchInLenderStatus: "lds/punchin/punchin-lender-status",

  //NOTE -- crm---
  getCallDispositions: "/lds/crm/get-call-dispositions",
  getCmCrm: "/lds/crm/get-cm-crm-status",
  createCmCrmStatus: "/lds/crm/create-cm-crm-status",
  createNewCallDisposition: "/lds/crm/add-call-disposition",
  updateCallDispositions: "/lds/crm/update-call-disposition",
  updateCmCrmStatus: "/lds/crm/update-cm-crm-status",
  crmupload: "/lds/uploads/crm-upload",
  crmReport: "/lds/crm/get-crm-report",

  //NOTE -- market place
  getMpLender: "/lds/lenders/mp-lenders-kpi",
  GET_Mp_KPI: "/lds/lenders/mp-kpis",
  getLenderNameId: "/lds/lenders/get-lender-name-id",
  //NOTE -- publisher
  getPublisher: "/lds/publisher/get-all-publishers",
  addPublisher: "/lds/publisher/add-publisher",
  updatePublisher: "/lds/publisher/update-publisher",
  publisherRule: "/lds/publisher/add-rules",
  getPublisherRule: "/lds/publisher/get-publisher-rules",
  updatePublisherRule: "/lds/publisher/update-rules",
  leadsUtmSource: "/lds/leads/get-utms",

  //NOTE -- Market Place Tree
  getAllMarketPlaceTrees: "/lds/market-place/get-market-place-trees",
  addMarketPlaceTree: "/lds/market-place/add-marketplacetree",
  updateMarketPlaceTree: "/lds/market-place/update-mptree",
  setMarketPlaceTree: "/lds/market-place/set-mp-tree-config",
  getLendersList: "/lds/marketing/get-lender-emptype",
  deleteMarketPlaceTree: "/lds/market-place/delete-market-place-tree",
  getMpName: "/lds/market-place/get-mpTree-names",

  //NOTE -- market-place-analytics

  GROUP_BY_DATE: "lds/market-place/group-by-date",

  //NOTE --Market-Place-aNALYTICS
  GROUP_BY_PUBLISHERS: "lds/market-place/group-by-publisher",
  GROUP_BY_PUBLISHERS_REPORT:
    "lds/market-place/getMp-eligible-impression-clicks",
  getGBPMPClickedLender: "lds/market-place/mp-lender-click-Log",
  GROUP_BY_DATE_REPORT: "lds/market-place/getMp-groupByDate-report",
  GROUP_BY_DATE_ClICK_LENDER: "/lds/market-place/get-lenders-gbd",
  DATE_WISE_MP_CLICK: "/lds/market-place/date-wise-mp-click",
  MP_LENDER_LEAD_CLICK_LOG: "/lds/market-place/mp-lender-cLick-lead-log",

  //NOTE -- unique-by
  UNIQUE_BY: "/lds/leads/unique-by",
  UNIQUE_CLICK_DATA: "/lds/leads/get-unique-leads",

  //APIENDPOINT: DSA

  CREATE_DSA: "lds/dsa/create-dsa",
  UPDATE_DSA: "lds/dsa/update-dsa",
  GET_DSA: "lds/dsa/get-dsas",
  CHANGE_PASSWORD: "lds/dsa/change-password",

  // DSA Config

  addLenderSequencing: "lds/dsa/add-lender-sequencing",
  getLenderSequencing: "lds/dsa/lender-sequence-by-dsa-id",
  updateLenderSequencing: "lds/dsa/update-lender-sequencing",
  SET_DSA_STATUS: "/lds/dsa/update-dsa",

  // DSA Schedule

  addDsaSchedule: "/lds/dsa/add-dsa-schedule",
  updateDsaSchedule: "/lds/dsa/update-dsa-schedule",
  getDsaSchedule: "/lds/dsa/get-dsa-schedule",
  deleteDsaSchedule: "/lds/dsa/delete-dsa-schedule",
  //DSA - Dashboard
  GETDSAKPIS: "lds/dsa/get-dsa-kpi",
  GETLEADPERSTATUS: "lds/dsa/get-status-graph",
  GETTOPDSALEAD: "lds/dsa/top-performing-dsa",
  GETBOTTOMDSALEAD: "lds/dsa/bottom-performing-dsa",

  //DSA - Dashboard
  GetDSAList: "lds/dsa/get-active-dsa",
  GetDSADisbursalReport: "lds/dsa/dsa-disbursal",
  GetDSAReport: "lds/dsa/dsa-report",
};

export default apiEndPointsConfig;
