import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    kpis: [],
    leadPerStatus: [],
    trendOfDisbursal: [],
    topDSALeads: [],
    bottomDSALeads: []
};
export const dsaDashboardSlice = createSlice({
    name: 'dsaDashboard',
    initialState,
    reducers: {
        setDSAKPIs: (state, action) => {
            state.kpis = action.payload;
        },
        setLeadPerStatus: (state, action) => {
            state.leadPerStatus = action.payload;
        },
        setTrendOfDisbursal: (state, action) => {
            state.trendOfDisbursal = action.payload;
        },
        setTopDSALeads: (state, action) => {
            state.topDSALeads = action.payload;
        },
        setBottomDSALeads: (state, action) => {
            state.bottomDSALeads = action.payload;
        }
    }
});

export const dsaDashboardSliceAction = dsaDashboardSlice.actions;
