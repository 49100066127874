export const staticRanges = [
    {
        label: 'Today',
        range: () => ({
            startDate: new Date(),
            endDate: new Date()
        })
    },
    {
        label: 'Yesterday',
        range: () => {
            const endDate = new Date();
            endDate.setDate(endDate.getDate() - 1);
            const startDate = new Date(endDate);
            startDate.setHours(0, 0, 0, 0);
            return { startDate, endDate };
        }
    },
    {
        label: 'Last 7 Days',
        range: () => {
            const endDate = new Date();
            const startDate = new Date();
            startDate.setDate(endDate.getDate() - 6);
            return { startDate, endDate };
        }
    },
    {
        label: 'Last 30 Days',
        range: () => {
            const endDate = new Date();
            const startDate = new Date();
            startDate.setDate(endDate.getDate() - 29);
            return { startDate, endDate };
        }
    },
    {
        label: 'This Month',
        range: () => {
            const endDate = new Date();
            const startDate = new Date(endDate.getFullYear(), endDate.getMonth(), 1);
            return { startDate, endDate };
        }
    }
];
