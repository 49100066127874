import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    configStats: [],
    selectedFilter: null,
    filterOptions: null
};

export const configurationSlice = createSlice({
    name: 'configuration',
    initialState,
    reducers: {
        getConfigurationStats: (state, action) => {
            state.configStats = action.payload;
        },
        getSelectedFilter: (state, action) => {
            state.selectedFilter = action.payload;
        },
        getFilterOptions: (state, action) => {
            state.filterOptions = action.payload;
        }
    }
});

export const configurationAction = configurationSlice.actions;
