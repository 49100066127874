import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allUsers: []
};
export const settingSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        getUnsubscribeUsers: (state, action) => {
            state.allUsers = action.payload;
        }
    }
});

export const settingAction = settingSlice.actions;
