import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dropOffKpiData: null,
    dropOffUtmWise: null,
    dropOffStageWiseStats: null,
    dropOffUtmSource: [],
    dropOffGridData: null,
    dropOffStageGrid: [],
    selectedDropOffFilter: null,
    selectedFilterStage: null,
    selectedDropOffTable: null,
    selectedDropOffDashboardFilters: null
};

export const dropOffSlice = createSlice({
    name: 'dropOff',
    initialState,
    reducers: {
        getDropOffKpiData: (state, action) => {
            state.dropOffKpiData = action.payload;
        },
        getDropOffUtmWise: (state, action) => {
            state.dropOffUtmWise = action.payload;
        },
        getDropOffStageWiseStats: (state, action) => {
            state.dropOffStageWiseStats = action.payload;
        },
        getDropOffUtmSource: (state, action) => {
            state.dropOffUtmSource = action.payload;
        },
        getDropOffData: (state, action) => {
            state.dropOffGridData = action.payload;
        },
        getDropOffStageGridData: (state, action) => {
            state.dropOffStageGrid = action.payload;
        },
        getSelectedDropOffFilter: (state, action) => {
            state.selectedDropOffFilter = action.payload;
        },
        getSelectedStageFilter: (state, action) => {
            state.selectedFilterStage = action.payload;
        },
        getSelectedDropOffTable: (state, action) => {
            state.selectedDropOffTable = action.payload;
        },
        getSelectedDropOffFilters: (state, action) => {
            state.selectedDropOffDashboardFilters = action.payload;
        }
    }
});

export const dropOffAction = dropOffSlice.actions;
