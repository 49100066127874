import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    AllData: []
};

export const MisSlice = createSlice({
    name: 'misData',
    initialState,
    reducers: {
        getMis: (state, action) => {
            state.AllData = action.payload;
        }
    }
});

export const misActions = MisSlice.actions;
