import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack'; // Assuming you are using notistack for notifications
import apiService from 'helpers/apiService';
import apiEndPointsConfig from 'helpers/apiEndPointsConfig';
import { eligibleLeadActions } from 'store/leads/eligibleLeadSlice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const useLenderFilters = () => {
    const { lender } = useSelector((state) => state.eligibleLeads);
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchLenderFilters = async () => {
            try {
                const response = await apiService.get(apiEndPointsConfig.getLenderNameFilters);
                dispatch(eligibleLeadActions.getLenders(response?.data?.data));
            } catch (error) {
                console.error(error);
                enqueueSnackbar('Something went wrong', { variant: 'error' });
            }
        };

        fetchLenderFilters();

        // Clean-up function
        return () => {
            // Any clean-up code if needed
        };
    }, [enqueueSnackbar]);

    return lender;
};

export default useLenderFilters;
