import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    clickCountDetailsTableData: []
};

export const clickCountPageDetailsSlice = createSlice({
    name: 'clickCountDetails',
    initialState,
    reducers: {
        getIClickCountDetails: (state, action) => {
            state.clickCountDetailsTableData = action.payload;
        }
    }
});

export const clickCountPageDetailsAction = clickCountPageDetailsSlice.actions;
