import { Box, Button } from "@mui/material";
import {
  ArrowBack,
  CommonChart,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  apiEndPointsConfig,
  PageHeader,
  LinkOutlined,
  moment,
} from "collections/Imports";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import HorizontalLinearStepper from "./progressBar";
// import { getLink, getleadLogs, sendLenderSMS } from 'helpers/credmudra/dashBoard-insights';
import { enqueueSnackbar } from "notistack";
import { mdiWhatsapp, mdiMessageProcessingOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { customerInfoAction } from "store/leads/customerInfoSlice";
import apiService from "helpers/apiService";

const useChartFullscreen = () => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const chartRef = useRef(null);

  const handleToggleFullscreen = () => {
    const element = chartRef.current;

    if (!isFullscreen) {
      if (element) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        }
        setIsFullscreen(true);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  return { isFullscreen, chartRef, handleToggleFullscreen };
};

const CustomerListDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const buttonRefs = useRef([]);
  const [updatedLeadInfo, setUpdatedLeadInfo] = useState([]);
  const [disableBTN, setDisableBTN] = useState([]);
  const [timer, setTimer] = useState([]);
  const customerInfo = useSelector((state) => state.customerInfo?.customerInfo);
  const lenderStatus = useSelector((state) => state.customerInfo?.lenderStatus);

  const { id } = useParams();

  useEffect(() => {
    getLeadLogs();
  }, []);

  useEffect(() => {
    if (customerInfo && Object.keys(customerInfo).length) {
      const data = [];

      for (let key in customerInfo) {
        data.push({
          key: key,
          value: customerInfo[key ]  ,
        });
      }
      // if (customerInfo.createdOn) {
      //   const createdOnUTC = moment(customerInfo.createdOn).utc().format('LLL')
      //   data.push({
      //     key: "createdOn",
      //     value: createdOnUTC,
      //   });
      // }
      
      setUpdatedLeadInfo(data);
    }
  }, [customerInfo]);

  const {
    isFullscreen: isFullscreen1,
    chartRef: chart1Ref1,
    handleToggleFullscreen: handleToggleFullscreen1,
  } = useChartFullscreen();

  const getLeadLogs = async () => {
    const data = {
      leadId: id,
    };

    try {
      const res = await apiService.post(apiEndPointsConfig.Get_Lander_Status, {
        data,
      });
      dispatch(customerInfoAction.addCustomerInfo(res?.data?.data?.leadInfo));
      dispatch(
        customerInfoAction.addLenderStatus(res?.data?.data?.lenderStatus)
      );
    } catch (error) {
      console.error("Error fetching lead status:", error);
    }
  };

  // const sendSMS = async (reqData, index) => {
  //   buttonRefs.current[index].disabled = true;

  //   setTimer([...timer, { key: index, value: 60 }]);
  //   setDisableBTN([...disableBTN, index]);

  //   let timerId = setTimeout(() => {
  //     buttonRefs.current[index].disabled = false;
  //     setDisableBTN(disableBTN.filter((i) => i !== index));
  //   }, 60000);

  //   let countdown = 60;

  //   const intervalId = setInterval(() => {
  //     countdown -= 1;
  //     setTimer((prev) => {
  //       console.log(prev);
  //       const updatedTimers = [...prev];
  //       const timerObjIndex = updatedTimers.findIndex(
  //         (obj) => obj.key === index
  //       );
  //       if (timerObjIndex !== -1) {
  //         updatedTimers[timerObjIndex].value = countdown;
  //       }

  //       return updatedTimers;
  //     });

  //     if (countdown <= 0) {
  //       clearInterval(intervalId);
  //       buttonRefs.current[index].disabled = false;
  //       setDisableBTN(disableBTN.filter((i) => i !== index));
  //     }
  //   }, 1000);
  //   const data = {
  //     leadId: id,
  //     lenderId: reqData?.lenderId,
  //   };
  //   const res = await sendLenderSMS(data);

  //   if (res.data === "success") {
  //     enqueueSnackbar("Message Sent Successfully", { variant: "success" });
  //     dispatch(customerInfoAction.updateLenderStatus(reqData));
  //   }
  //   // setTimeout(() => {
  //   //   buttonRefs.current[index].disabled = false;
  //   //   setDisableBTN(disableBTN.filter((i) => i !== index));
  //   // }, 60000);
  // };

  // const handleSendSMSBtn = (res, index) => {
  //   if (res.statusList.length === 2 && res.statusList[1].value === "accepted") {
  //     return !!(buttonRefs.current[index]?.disabled || res?.smsCount > 1);
  //   } else {
  //     return true;
  //   }
  // };

  // const sendWhatsappMSG = async (res, index) => {
  //   if (res?.link !== "") {
  //     openWhatsapp(res?.link, res?.lenderName);
  //   } else {
  //     const data = {
  //       leadId: customerInfo?.leadId,
  //       lenderId: res?.lenderId,
  //     };
  //     const result = await getLink(data);
  //     if (result?.data?.url) {
  //       openWhatsapp(result?.data?.url, res?.lenderName);
  //     } else {
  //       enqueueSnackbar("There is an issue in generating links.", {
  //         variant: "error",
  //       });
  //     }
  //   }
  // };
  // const openWhatsapp = (url, lenderName) => {
  //   const message = `Hi ${customerInfo?.firstName}, exciting update! We've matched you with a new lender ${lenderName}. Click here to complete the form now- ${url} -Credmudra`;
  //   const number = customerInfo?.contactNo;
  //   const windowWidth = window.innerWidth;
  //   if (windowWidth >= 991) {
  //     window.open(
  //       `https://web.whatsapp.com/send?phone=91${number}&text=${message}`,
  //       "_blank"
  //     );
  //   } else {
  //     window.open(`https://wa.me/91${number}?text=${message}`, "_blank");
  //   }
  // };
  return (
    <>
      <PageHeader
        title={"Lead Details"}
        helpText={"Lead Details"}
        backIcon={<ArrowBack />}
        onBack={() => navigate(-1)}
      />

      <Box p={5}>
        <Box ref={chart1Ref1}>
          <CommonChart title={"Lead Info"}>
            <Grid container>
              {updatedLeadInfo &&
                updatedLeadInfo.map((res) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={res?.key}
                    sx={{ border: "1px solid #d3d6dd80" }}
                  >
                    <Box m={2} p={0.1}>
                      <strong>
                        {res?.key.charAt(0).toUpperCase() + res?.key.slice(1)} :{" "}
                      </strong>{" "}
                      {res?.key === "marketPlaceLink" ? (
                        <IconButton
                          component="a"
                          href={res.value}
                          target="_blank"
                          rel="noopener noreferrer"
                          sx={{
                            color: "#fff",
                            fontSize: "15px",
                            backgroundColor: "#0276FF",
                            borderRadius: "5px",
                            p: 0.5,
                            "&:hover": {
                              backgroundColor: "#0154A0",
                            },
                          }}
                        >
                          <LinkOutlined />{" "}
                          <span style={{ paddingLeft: "10px" }}>
                            {" "}
                            Market Place Link
                          </span>
                        </IconButton>
                      )  : res?.key === 'createdOn' ? (
                          <span>{moment(res?.value).utc().format('LLL')}</span>
                      ) : (
                        
                        res?.value
                      )}
                    </Box>
                  </Grid>
                ))}
            </Grid>
            {/* )} */}
          </CommonChart>
        </Box>
      </Box>
      <Box p={5}>
        <Box ref={chart1Ref1}>
          <CommonChart title={"Lender Status"}>
            <Box sx={{ p: { md: 5, xs: 3 } }} width={"100%"}>
              {lenderStatus.map((res, index) => (
                <Grid
                  container
                  spacing={2}
                  mt={2}
                  sx={{
                    display: "flex",
                    alignContent: { md: "center", xs: "space-between" },
                    justifyContent: "center",
                    border: "1px solid #dadada",
                    borderRadius: "10px",
                    height: { md: "120px", xs: "240px" },
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                      height: { md: "100%", xs: "50px" },

                      bgcolor: "#f0fdff",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      borderRight: { md: "1px dashed #dadada" },
                      borderBottom: { xs: "1px dashed #dadada" },
                      paddingBottom: "16px",
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {res.lenderName}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={8}
                    sx={{ padding: "10px", overflowX: { xs: "auto" } }}
                  >
                    <HorizontalLinearStepper status={res.statusList} />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingBottom: { xs: "20px" },
                    }}
                  >
                    {/* <Tooltip
                      title={res?.smsCount > 1 ? "Limit Exceeded" : "Send SMS"}
                    >
                      <Box>
                        <Button
                          disabled={handleSendSMSBtn(res, index)}
                          id={res.lenderName}
                          ref={(ele) => (buttonRefs.current[index] = ele)}
                          onClick={() => sendSMS(res, index)}
                        >
                          <Icon path={mdiMessageProcessingOutline} size={1} />
                        </Button>

                        {handleSendSMSBtn(res, index) && res?.smsCount < 2 && (
                          <Box
                            sx={{
                              width: "fit-content",
                              margin: "auto",
                              color: "blue",
                              paddingTop: "5px",
                            }}
                          >
                            {timer.findIndex((obj) => obj.key === index) !==
                              -1 &&
                            timer[timer.findIndex((obj) => obj.key === index)]
                              ?.value !== 0
                              ? `00:${
                                  timer.find((obj) => obj.key === index)?.value
                                }`
                              : ""}
                          </Box>
                        )}
                      </Box>
                    </Tooltip>

                    <Tooltip
                      title={
                        res.statusList.length === 2 &&
                        res.statusList[1].value === "accepted"
                          ? "Send Whatsapp Message"
                          : ""
                      }
                    >
                      <Box>
                        <Icon
                          style={{
                            color:
                              res.statusList.length === 2 &&
                              res.statusList[1].value === "accepted"
                                ? "green"
                                : "rgba(0, 0, 0, 0.26)",
                            cursor:
                              res.statusList.length === 2 &&
                              res.statusList[1].value === "accepted"
                                ? "pointer"
                                : "default",
                          }}
                          path={mdiWhatsapp}
                          size={1}
                          onClick={() => {
                            if (
                              res.statusList.length === 2 &&
                              res.statusList[1].value === "accepted"
                            ) {
                              sendWhatsappMSG(res, index);
                            }
                          }}
                        />
                      </Box>
                    </Tooltip> */}
                  </Grid>
                </Grid>
              ))}

              {!lenderStatus.length && (
                <Box
                  sx={{
                    margin: "auto",
                    width: "fit-content",
                  }}
                >
                  No lenders are available to show.
                </Box>
              )}
            </Box>
          </CommonChart>
        </Box>
      </Box>
    </>
  );
};

export default CustomerListDetails;
