import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lendersKpi: [],
    KpiSelectedFilter: null
};

export const lenderKpiSlice = createSlice({
    name: 'lendersKpi',
    initialState,
    reducers: {
        getLendersKpi: (state, action) => {
            state.lendersKpi = action.payload;
            // console.log(state.lendersKpi);
        },
        removeLendersKpi: (state, action) => {
            state.lendersKpi = [];
        },
        getKpiSelectedFilter: (state, action) => {
            state.KpiSelectedFilter = action.payload;
        }
    }
});

export const lendersKpiAction = lenderKpiSlice.actions;
