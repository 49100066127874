import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allData: [],
    mpTreeSelectedFilter: {}
};
export const MarketPlaceTreeSlice = createSlice({
    name: 'MarketPlaceTree',
    initialState,
    reducers: {
        getAllData: (state, action) => {
            state.allData = action.payload;
        },
        getMPTreeSelectedFilter: (state, action) => {
            state.mpTreeSelectedFilter = action.payload;
        },
        setSingleRowData: (state, action) => {
            const rowdata = action.payload;
            const alldata = state.allData;
            const marketPlaceData = state.allData?.marketplacetree;
            const headerData = state.allData?.headers;
            const index = marketPlaceData.findIndex((res) => res.id === rowdata?.id);
            const result = (marketPlaceData[index]['lenderSequence'] = rowdata?.lenderSequence);
            const finalData = {
                marketplacetree: result,
                headers: headerData
            };
            state.allData = finalData;
        }
    }
});

export const MarketPlaceTreeSliceAction = MarketPlaceTreeSlice.actions;
