import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tabValuesLenders: 0
};

export const tabValuesLendersSlice = createSlice({
    name: 'tabValuesLenders',
    initialState,
    reducers: {
        getTabValuesLenders: (state, action) => {
            state.tabValuesLenders = action.payload;
        }
    }
});

export const tabValuesLendersSliceAction = tabValuesLendersSlice.actions;
