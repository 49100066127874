import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allDSAList: [],
    dsaReportTableData: {
        data: '',
        headers: ''
    },
    dsaFilters: {}
};
export const dsaReportSlice = createSlice({
    name: 'dsaReport',
    initialState,
    reducers: {
        setAllDSAList: (state, action) => {
            state.allDSAList = action.payload;
        },
        setDSAReportTableData: (state, action) => {
            state.dsaReportTableData = action.payload;
        },
        setDSAFilters: (state, action) => {
            state.dsaFilters = action.payload;
        }
    }
});

export const dsaReportSliceAction = dsaReportSlice.actions;
