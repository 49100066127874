import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedFilter: {},
    loadingState: false
};
export const dashboardSelectedFilter = createSlice({
    name: 'dashboardFilters',
    initialState,
    reducers: {
        getSelectedFilter: (state, action) => {
            state.selectedFilter = action.payload;
        },
        getLoadingState: (state, action) => {
            state.loadingState = action.payload;
        }
    }
});

export const dashboardFilterAction = dashboardSelectedFilter.actions;
