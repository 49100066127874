import CommonBarChart from 'Components/ui-component/commonBarCharts';
import {
    Grid,
    Typography,
    Box,
    useEffect,
    useRef,
    Chart,
    moment,
    CommonChart,
    useState,
    CircularLoadingProgress
} from '../../../collections/Imports';
import { useSelector } from 'react-redux';
import CommonStackBarChart from 'Components/ui-component/commonStackBarComponent';
const LeadStatus = ({ onLeadStatusRefresh, isLscLoading }) => {
    const { punchinLenderStats } = useSelector((state) => state.punchIn);
    const lenderName = punchinLenderStats.map((obj) => {
        const name = obj.lenderName;
        return name.charAt(0).toUpperCase() + name.slice(1);
    });

    const childRef = useRef();
    const [isFullscreen, setIsFullscreen] = useState(false);

    const handleToggleFullscreen = () => {
        if (childRef.current) {
            if (!isFullscreen) {
                if (childRef.current.requestFullscreen) {
                    childRef.current.requestFullscreen();
                }
            } else {
                onLeadStatusRefresh();
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                }
            }
            setIsFullscreen(!isFullscreen);
        }
    };
    return (
        <>
            <Grid container spacing={2} className="child-component">
                <Grid item sx={{ width: { xs: '740px', md: '100%' } }}>
                    <Box
                        spacing={isFullscreen ? 0 : 2}
                        ref={childRef}
                        className={`child-component ${isFullscreen ? 'fullscreen' : ''}`}
                        sx={{ mt: 1 }}
                    >
                        <CommonChart
                            title={'Lender Status Count'}
                            onToggleFullscreen={handleToggleFullscreen}
                            isFullscreen={isFullscreen}
                            onRefresh={onLeadStatusRefresh}
                        >
                            <Box p={3}>
                                {isLscLoading ? (
                                    <Box sx={{ height: 500, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <CircularLoadingProgress />
                                    </Box>
                                ) : (
                                    <>
                                        {punchinLenderStats?.length > 0 ? (
                                            <CommonStackBarChart
                                                title={''}
                                                data={{
                                                    chart: {
                                                        stacked: true
                                                    },
                                                    plotOptions: {
                                                        bar: {
                                                            horizontal: true,
                                                            barHeight: '80%'
                                                        }
                                                    },
                                                    dataLabels: {
                                                        enabled: false
                                                    },
                                                    yaxis: lenderName,
                                                    series: [
                                                        {
                                                            type: 'bar',
                                                            stack: 'total',
                                                            label: {
                                                                show: false
                                                            },
                                                            emphasis: {
                                                                focus: 'series'
                                                            },
                                                            name: 'Accepted',
                                                            data: punchinLenderStats.map(
                                                                (lender) => lender.status.find((s) => s.status === 'accepted').count || 0
                                                            ),
                                                            itemStyle: {
                                                                color: '#00b300'
                                                            }
                                                        },
                                                        {
                                                            name: 'Duplicate',
                                                            type: 'bar',
                                                            stack: 'total',
                                                            label: {
                                                                show: false
                                                            },
                                                            emphasis: {
                                                                focus: 'series'
                                                            },
                                                            data: punchinLenderStats.map(
                                                                (lender) => lender.status.find((s) => s.status === 'duplicate').count || 0
                                                            ),

                                                            itemStyle: {
                                                                color: '#3468eb'
                                                            }
                                                        },
                                                        {
                                                            name: 'Rejected',
                                                            type: 'bar',
                                                            stack: 'total',
                                                            label: {
                                                                show: false
                                                            },
                                                            emphasis: {
                                                                focus: 'series'
                                                            },
                                                            data: punchinLenderStats.map(
                                                                (lender) => lender.status.find((s) => s.status === 'rejected').count || 0
                                                            ),
                                                            itemStyle: {
                                                                color: '#f8961a'
                                                            }
                                                        },
                                                        {
                                                            name: 'Error',
                                                            type: 'bar',
                                                            stack: 'total',
                                                            label: {
                                                                show: false
                                                            },
                                                            emphasis: {
                                                                focus: 'series'
                                                            },
                                                            data: punchinLenderStats.map(
                                                                (lender) => lender.status.find((s) => s.status === 'error').count || 0
                                                            ),
                                                            itemStyle: {
                                                                color: '#ff0000'
                                                            }
                                                        }
                                                    ]
                                                }}
                                                height={isFullscreen ? '90vh' : 500}
                                            />
                                        ) : (
                                            <Box
                                                sx={{
                                                    height: isFullscreen ? '90vh' : 500,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <Typography variant="body1" sx={{ textAlign: 'center' }}>
                                                    No data available.
                                                </Typography>
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Box>
                        </CommonChart>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default LeadStatus;
