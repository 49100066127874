import React from 'react';
import { Box, Button, Chip, Grid } from '@mui/material';
import { AddOutlined } from '@mui/icons-material';
import { Filters, PropTypes, DateLeads } from '../../../collections/Imports';

const FilterComponent = ({ filters, toggleDrawer, handleClearFilter }) => {
    return (
        <Box pl={1.5}>
            <Grid container spacing={2}>
                {filters.map(({ title, data, onClick }) => (
                    <Filters key={title} title={title === 'Date' ? <DateLeads /> : title} onClick={onClick}>
                        {title === 'Unique By:' && <Chip key={title} label={data} sx={{ m: '2px' }} size="small" />}
                        {title === 'Date' && <Chip key={title} label={`${data[0]} - ${data[1]}`} sx={{ m: '2px' }} size="small" />}
                        {title !== 'Unique By:' &&
                            title !== 'Date' &&
                            data.slice(0, 2).map((item, index) => (
                                <span key={index}>
                                    <Chip label={item} sx={{ m: '2px' }} size="small" />
                                    {data.length > 2 && index === 1 && (
                                        <Chip label={`+${data.length - 2}`} sx={{ m: '2px' }} size="small" />
                                    )}
                                </span>
                            ))}
                    </Filters>
                ))}

                <Filters onClick={toggleDrawer}>
                    <Button sx={{ color: 'gray', margin: 'auto 0', padding: 0 }} startIcon={<AddOutlined />}>
                        Filter
                    </Button>
                </Filters>
                <Filters>
                    <Button
                        onClick={handleClearFilter}
                        variant="text"
                        sx={{ textDecoration: 'underline', color: 'gray', margin: 'auto 0', width: '100%', p: 0 }}
                    >
                        Clear All
                    </Button>
                </Filters>
            </Grid>
        </Box>
    );
};

FilterComponent.propTypes = {
    filters: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            data: PropTypes.array.isRequired,
            onClick: PropTypes.func.isRequired
        })
    ).isRequired,
    toggleDrawer: PropTypes.func.isRequired,
    handleClearFilter: PropTypes.func.isRequired
};

export default FilterComponent;
