import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    lenderLeadStatus: []
};

export const lenderLeadSlice = createSlice({
    name: 'lenderLeadStatus',
    initialState,
    reducers: {
        getLenderLeadStatusSuccess: (state, action) => {
            state.lenderLeadStatus = action.payload;
        }
    }
});

export const lenderLeadStatusAction = lenderLeadSlice.actions;
