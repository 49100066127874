import {
    Chip,
    Grid,
    useEffect,
    useRef,
    useState,
    useFormik,
    moment,
    useSnackbar,
    apiEndPointsConfig,
    Yup,
    Box,
    CommonChart,
    InfoOutlined,
    FilterComponent
} from '../../../collections/Imports';
import api from 'helpers/apiService';
import { useDispatch, useSelector } from 'react-redux';
import { punchInDataTableAction } from 'store/punchin/punchinDataTable';
import { TablePagination, Tooltip } from '@mui/material';
import FiltersForm from './FiltersForm';

export default function DataTable() {
    const dispatch = useDispatch();
    const childRef = useRef();
    const [isFullscreen, setIsFullscreen] = useState(false);

    const { gridData, selectedPunchinFilter } = useSelector((state) => state.punchInTable);
    const [loading, setLoading] = useState(false);
    const [loadingGif, setLoadingGif] = useState(true);
    const [columnDef, setColumnDef] = useState([]);
    const [empType, setEmpType] = useState('All');
    const { enqueueSnackbar } = useSnackbar();
    const [isOpen, setIsOpen] = useState(false);

    const [filterLenders, setFilterLenders] = useState(['All']);
    const [filterLendersId, setFilterLendersId] = useState(['All']);
    const [filterStatus, setFilterStatus] = useState(['All']);

    const [totalData, setTotalData] = useState();
    const [controller, setController] = useState({
        page: 0,
        rowsPerPage: 1000
    });

    useEffect(() => {
        getPageData();
    }, [controller, selectedPunchinFilter]);

    useEffect(() => {
        fetchStatusFilters();
    }, []);

    // pagination Function

    const handlePageChange = (event, newPage) => {
        setController({
            ...controller,
            page: newPage
        });
    };

    const handleChangeRowsPerPage = (event) => {
        setController({
            ...controller,
            rowsPerPage: parseInt(event.target.value),
            page: 0
        });
    };

    const [dateRange, setDateRange] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    const [selectedFilter, setSelectedFilter] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    });

    const [filters, setFilters] = useState([]);
    const [openElement, setOpenElement] = useState();

    const toggleDrawer = (ele) => {
        setOpenElement(ele);
        setIsOpen((prev) => !prev);
    };

    useEffect(() => {
        setFilters([
            {
                title: 'Date',
                data: [moment(selectedFilter?.startDate).format('LL'), moment(selectedFilter?.endDate).format('LL')],
                onClick: () => toggleDrawer('Date')
            },
            {
                title: 'Lenders:',
                data: [...filterLenders],
                onClick: () => toggleDrawer('lenders')
            },
            {
                title: 'Status:',
                data: [...filterStatus],
                onClick: () => toggleDrawer('status')
            },
            {
                title: 'Emp Type:',
                data: [empType],
                onClick: () => toggleDrawer('empType')
            }
        ]);
    }, [selectedFilter, filterLenders, filterStatus, empType]);

    const validationSchema = Yup.object().shape({
        loanlender: Yup.array().min(1, 'Please select at least one lender').required('required'),
        loanstatus: Yup.array().min(1, 'Please select at least one status').required('required'),
        punchIn: Yup.string().required('required').nullable(),
        empType: Yup.string().min(1, 'Please select at least one emp type').required('required')
    });

    const inititalValues = {
        loanstatus: ['All'],
        loanlender: [{ lenderId: 'All', name: 'All' }],
        startDate: moment().startOf('day').toISOString(),
        endDate: moment().endOf('day').toISOString(),
        punchIn: 'OnlyPunchIn',
        empType: 'All'
    };

    const formik = useFormik({
        initialValues: inititalValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            setSelectedFilter(dateRange[0]);
            const data = {
                index: controller?.page,
                itemsPerIndex: controller?.rowsPerPage,
                fromDate: moment(dateRange[0].startDate).startOf('day').toISOString(),
                toDate: moment(dateRange[0]?.endDate).endOf('day').toISOString(),
                lenderIds: values.loanlender.map((item) => item.lenderId),
                lendersName: values.loanlender.map((item) => item.name),
                status: values.loanstatus,
                punchIn: 'OnlyPunchIn',
                empType: values?.empType
            };
            setFilterLenders(values.loanlender.map((item) => item.name));
            setFilterLendersId(data?.lenderIds);
            setFilterStatus(values.loanstatus.map((item) => item));
            setEmpType(values?.empType);

            dispatch(punchInDataTableAction.getSelectedPunchinFilter(data));
            setIsOpen(false);
            setLoading(false);
            enqueueSnackbar('Updated Successfully', { variant: 'success' });
        }
    });

    const getPageData = async () => {
        if (selectedPunchinFilter != null) {
            const { fromDate, toDate, lenderIds, status, empType, lendersName } = selectedPunchinFilter;
            const data = {
                index: controller?.page,
                itemsPerIndex: controller?.rowsPerPage,
                fromDate: fromDate,
                toDate: toDate,
                lenderIds: lenderIds,
                lendersName: lendersName,
                punchIn: 'OnlyPunchIn',
                status: status,
                empType: empType
            };

            setSelectedFilter({
                startDate: fromDate,
                endDate: toDate,
                key: 'selection'
            });

            setFilterLenders(lendersName);
            setFilterLendersId(lenderIds);
            setFilterStatus(status);
            setEmpType(empType);

            setDateRange([
                {
                    startDate: fromDate ? new Date(fromDate) : new Date(),
                    endDate: toDate ? new Date(toDate) : new Date(),
                    key: 'selection'
                }
            ]);

            api.post(apiEndPointsConfig.getLeadReport, { data })
                .then((response) => {
                    dispatch(punchInDataTableAction.getPunchInDataTableGridData(response?.data?.data?.leads));
                    setTotalData(response?.data?.data?.total);
                    setLoadingGif(false);
                })
                .catch((error) => {
                    console.error(error);

                    enqueueSnackbar('Something went Wrong', { variant: 'error' });
                    setLoadingGif(false);
                });
        } else {
            const data = {
                index: controller?.page,
                itemsPerIndex: controller?.rowsPerPage,
                fromDate: moment().startOf('day').toISOString(),
                toDate: moment().endOf('day').toISOString(),
                lenderIds: filterLendersId,
                lendersName: ['All'],
                status: ['All'],
                punchIn: 'OnlyPunchIn',
                empType: 'All'
            };

            dispatch(punchInDataTableAction.getSelectedPunchinFilter(data));

            api.post(apiEndPointsConfig.getLeadReport, { data })
                .then((response) => {
                    dispatch(punchInDataTableAction.getPunchInDataTableGridData(response?.data?.data?.leads));
                    setTotalData(response?.data?.data?.total);
                    setLoadingGif(false);
                })
                .catch((error) => {
                    console.error(error);

                    enqueueSnackbar('Something went Wrong', { variant: 'error' });
                    setLoadingGif(false);
                });
        }
    };

    const tooltipDup = () => {
        return (
            <div>
                <h3>Quality indicates the number of time the same lead has previously recorded in the system.</h3>
                <br></br>
                <ul>
                    <li>Within the last 1 day (1st left bubble)</li>
                    <li>Within the past 7 days (2nd left bubble)</li>
                    <li>Within the past 30 days (3rd left bubble)</li>
                    <li>Within the past 45 days (4th left bubble)</li>
                </ul>
            </div>
        );
    };

    const renderDup = (data) => {
        const values = data?.value;

        const coloredValues = Object.entries(values).map(([value, count]) => {
            let backgroundColor;
            if (count === 0) {
                backgroundColor = 'green'; // Green
            } else if (count >= 1 && count <= 9) {
                backgroundColor = 'orange'; // Yellow
            } else if (count >= 10) {
                backgroundColor = 'red'; // Red
            } else {
                backgroundColor = 'white'; // Default color
            }
            return { value, backgroundColor };
        });

        return (
            <div>
                {coloredValues.map((item, index) => (
                    <Chip
                        key={index}
                        size={'small'}
                        label={`${values[item.value]}`} // Display both value and count
                        variant="outlined"
                        style={{
                            backgroundColor: item.backgroundColor,
                            marginRight: '5px',
                            color: 'white'
                        }}
                    />
                ))}
            </div>
        );
    };

    useEffect(() => {
        if (gridData && gridData.length) {
            const allKeys = Object.keys(gridData[0]).filter(
                (res) => res !== 'createdOn' && res !== 'isPreApproved' && res !== 'punchedOn' && res !== 'dup(1/2/30/45)'
            );

            const columnModification = {
                dup: {
                    key: 'dup(1/2/30/45)',
                    field: 'dup(1/2/30/45)',
                    cellClass: 'my-class',
                    headerName: 'Dupe(1/7/30/45)',
                    textAlign: 'center',
                    editable: false,
                    defaultWidth: 200,
                    cellRenderer: renderDup,
                    headerTooltip: '',
                    headerComponent: () => (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                            <span>Dupe(1/7/30/45)</span>
                            <Tooltip title={tooltipDup()} placement="bottom" arrow>
                                <InfoOutlined style={{ fontSize: 18, color: '#888888', marginLeft: 4 }} />
                            </Tooltip>
                        </div>
                    )
                }
            };

            let newColumns = allKeys.map((key) => {
                return {
                    field: key,
                    headerName: key.charAt(0).toUpperCase() + key.slice(1),
                    defaultSort: 'asc',
                    cellClass: 'my-class',
                    filterParams: {
                        buttons: ['apply', 'reset'],
                        closeOnApply: true
                    }
                };
            });

            newColumns.push({
                key: 'isPreApproved',
                field: 'isPreApproved',
                headerName: 'IsPreApproved',
                cellRenderer: (data) => (
                    <Chip
                        size={'small'}
                        label={<span>{data.value.toString()}</span>}
                        color={data.value === true ? 'success' : data.value === 'rejected' ? 'error' : undefined}
                        variant="outlined"
                    />
                )
            });
            newColumns.push({
                key: 'status',
                field: 'status',
                headerName: 'Status',
                cellRenderer: (data) => (
                    <Chip
                        size={'small'}
                        label={data.value.charAt(0).toUpperCase() + data.value.slice(1)}
                        color={
                            data.value === 'error'
                                ? 'error'
                                : data.value === 'accepted'
                                ? 'success'
                                : data.value === 'rejected'
                                ? 'error'
                                : data.value === 'duplicate'
                                ? 'secondary'
                                : undefined
                        }
                        variant="outlined"
                    />
                )
            });

            newColumns.push({
                field: 'punchedOn',
                key: 'punchedOn',
                headerName: 'Punched On',
                editable: false,
                defaultWidth: 200,
                cellRenderer: ({ data }) => <span>{data?.punchedOn == null ? 'null' : moment(data.punchedOn).utc().format('LLL')}</span>
            });
            newColumns.push({
                field: 'createdOn',
                key: 'createdOn',
                headerName: 'Created On',
                editable: false,
                defaultWidth: 200,
                cellRenderer: ({ data }) => moment(data.createdOn).utc().format('LLL')
            });
            newColumns.push(...Object.values(columnModification));
            setColumnDef(newColumns);
        }
    }, [gridData]);

    const fetchStatusFilters = () => {
        api.get(apiEndPointsConfig.getLenderStatusFilters)
            .then((response) => {
                dispatch(punchInDataTableAction.getPunchInStatus(response.data.data));
                setTotalData(response?.data?.data?.total);
            })
            .catch((error) => {
                console.error(error);
                enqueueSnackbar('Something went Wrong', { variant: 'error' });
            });
    };

    const handleClearFilter = () => {
        formik.resetForm();
        setDateRange([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ]);
        setSelectedFilter([
            {
                startDate: new Date(),
                endDate: new Date(),
                key: 'selection'
            }
        ]);
        setFilterLenders(['All']);
        setFilterStatus(['All']);
        setEmpType('All');

        const data = {
            index: controller?.page,
            itemsPerIndex: controller?.rowsPerPage,
            fromDate: moment().startOf('day').toISOString(),
            toDate: moment().endOf('day').toISOString(),
            lenderIds: ['All'],
            lendersName: ['All'],
            status: ['All'],
            punchIn: 'OnlyPunchIn',
            empType: 'All'
        };

        dispatch(punchInDataTableAction.getSelectedPunchinFilter(data));
    };

    // Full Screen

    const handleToggleFullscreen = () => {
        const element = childRef.current;
        if (!isFullscreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
                setIsFullscreen(true);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullscreen(false);
            }
        }
    };

    const handleDateRangeChange = (item) => {
        if (item && item.selection) {
            setDateRange([item.selection]);
        }
    };
    return (
        <>
            <FiltersForm
                isOpen={isOpen}
                toggleDrawer={toggleDrawer}
                handleClearFilter={handleClearFilter}
                filterLenders={filterLenders}
                filterStatus={filterStatus}
                dateRange={dateRange}
                handleDateRangeChange={handleDateRangeChange}
                loading={loading}
                formik={formik}
                openElement={openElement}
            />
            <Grid container>
                <Grid item sm={12}>
                    <Box>
                        <FilterComponent toggleDrawer={toggleDrawer} handleClearFilter={handleClearFilter} filters={filters} />
                        <Box
                            spacing={isFullscreen ? 0 : 2}
                            ref={childRef}
                            sx={{ marginTop: { xs: '20px', md: '0' } }}
                            className={`child-component ${isFullscreen ? 'fullscreen' : ''}`}
                        >
                            {gridData && (
                                <CommonChart
                                    title={'Punch In Leads'}
                                    exportModuleName={'Punchin-users'}
                                    onToggleFullscreen={handleToggleFullscreen}
                                    isServerPagination={true}
                                    rowData={gridData || []}
                                    columnDefs={columnDef || []}
                                    isGridRequired={true}
                                    isLoading={loadingGif}
                                    isFullscreen={isFullscreen}
                                >
                                    <TablePagination
                                        component="div"
                                        onPageChange={handlePageChange}
                                        page={controller.page}
                                        count={totalData}
                                        rowsPerPage={controller.rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                        rowsPerPageOptions={[1000, 2500, 5000, 10000]}
                                        style={{
                                            ...(isFullscreen && {
                                                position: 'absolute',
                                                bottom: '20px',
                                                right: 0,
                                                left: 0
                                            })
                                        }}
                                    />
                                </CommonChart>
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
