import React from 'react';
import {
    Typography,
    Button,
    Card,
    useEffect,
    useState,
    Box,
    apiEndPointsConfig,
    useSelector,
    useNavigate,
    Stack,
    PageHeader,
    useParams,
    ConfirmDialog,
    Grid,
    TextField,
    Autocomplete,
    FormLabel,
    useFormik,
    Yup
} from '../../../collections/Imports';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CardList from 'Components/ui-component/commondragdrop';
import Icon from '@mdi/react';
import { mdiPlusCircleOutline } from '@mdi/js';
import { enqueueSnackbar } from 'notistack';
import img from '../../../assets/images/Frame 427318704.jpg';
import apiService from 'helpers/apiService';

function DsaLenderSequencing() {
    const [isUpdate, setIsUpdate] = useState(false);
    const [ruleRowData, setRuleRowData] = useState({});
    const [allLender, setAllLender] = useState([]);
    const [lender, setLender] = useState([]);
    const [cardListData, setCardListData] = useState([]);
    const [changedSourceId, setChangedSourceId] = useState([]);
    const navigate = useNavigate();
    const { id } = useParams();
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        subTitle: ''
    });
    useEffect(() => {
        getAllFormData();
        //fetchLenderFilters();
    }, []);
    const getAllFormData = async () => {
        apiService
            .post(apiEndPointsConfig.getLenderSequencing, { data: { dsaId: id } })
            .then((response) => {
                if (response?.data?.error) {
                    enqueueSnackbar(response?.data?.data, { variant: 'error' });
                } else {
                    setRuleRowData(response.data);
                    setIsUpdate(true);
                    // enqueueSnackbar('Lender Sequencing get Successfully', { variant: 'success' });
                }
            })
            .catch((error) => {
                enqueueSnackbar('Something went Wrong', { variant: 'error' });
            });
    };
    useEffect(() => {
        //if (ruleRowData?.data) {
        fetchLenderFilters();
        //}
    }, []);

    const fetchLenderFilters = () => {
        const data = {
            empType: 'All'
        };
        apiService
            .post(apiEndPointsConfig.getLendersList, { data })
            .then((response) => {
                const removeAll = response?.data?.data.filter((res) => res.lenderName !== 'All');
                setAllLender(removeAll);
                setLender(removeAll);
            })
            .catch((error) => {
                console.error(error);
                enqueueSnackbar('Something went Wrong', { variant: 'error' });
            });
    };

    const validationSchema = Yup.object().shape({
        lenderVisible: Yup.string().required('required')
    });
    const inititalValues = {
        lenderVisible: '',
        dsaId: 'DSA_9',
        status: true
    };
    const handleSubmit = async (values) => {};
    const formik = useFormik({
        initialValues: inititalValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });
    useEffect(() => {
        if (allLender?.length) {
            if (ruleRowData && Object.keys(ruleRowData).length) {
                const lenderIds = [];
                ruleRowData?.data?.orders?.map((item) => {
                    const xyz = allLender.find((res) => res.lenderId === item?.lenderId);
                    lenderIds.push(xyz);
                });
                const lenderlist = allLender.filter((item) => {
                    return !ruleRowData?.data?.orders.some((lender) => {
                        return lender?.lenderId === item?.lenderId;
                    });
                });
                formik.setFieldValue('lenderVisible', ruleRowData.data.lenderVisible, true);
                setCardListData(lenderIds);
                setLender(lenderlist);
                setChangedSourceId(ruleRowData?.data?.orders);
            }
        }
    }, [allLender, , ruleRowData]);

    const setCardListDataOnTop = (data) => {
        setCardListData((prev) => [data, ...prev]);
        const filterLender = lender.filter((res) => res.lenderName !== data.lenderName);
        setLender(filterLender);
    };

    const setCardListDataOnBottom = (data) => {
        setCardListData((prev) => [...prev, data]);
        const filterLender = lender.filter((res) => res.lenderName !== data.lenderName);
        setLender(filterLender);
    };

    const getLendersCard = (data, i) => {
        return (
            <Card
                key={i}
                sx={{
                    border: '1px solid #0C247B',
                    borderRadius: '10px',
                    marginBottom: '8px'
                }}
            >
                <Box padding={1}>
                    <Typography variant="h4">{data?.lenderName.charAt(0).toUpperCase() + data?.lenderName.slice(1)}</Typography>
                </Box>
                <Grid container>
                    <Grid item sm={6}>
                        <Box onClick={() => setCardListDataOnTop(data)} sx={{ cursor: 'pointer' }}>
                            <Stack
                                spacing={1}
                                direction={'row'}
                                padding={'0.2rem'}
                                sx={{
                                    background: '#0C247B',
                                    color: '#ffffff',
                                    borderRadius: '10px',
                                    fontSize: '10px',
                                    alignItems: 'center',
                                    justifyContent: 'space-evenly',
                                    padding: '8px',
                                    margin: '5px'
                                }}
                            >
                                <Icon
                                    style={{
                                        marginLeft: '0.5rem'
                                    }}
                                    path={mdiPlusCircleOutline}
                                    size={'20px'}
                                />
                                <Typography
                                    sx={{
                                        color: '#ffffff',
                                        fontSize: '0.7rem',
                                        paddingRight: '0.5rem'
                                    }}
                                >
                                    Add To Top
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                    <Grid item sm={6}>
                        <Box onClick={() => setCardListDataOnBottom(data)} sx={{ cursor: 'pointer' }}>
                            <Stack
                                spacing={1}
                                direction={'row'}
                                padding={'0.2rem'}
                                sx={{
                                    background: '#0C247B',
                                    color: '#ffffff',
                                    borderRadius: '10px',
                                    fontSize: '10px',
                                    alignItems: 'center',
                                    justifyContent: 'space-evenly',
                                    padding: '8px',
                                    margin: '5px'
                                }}
                            >
                                <Icon
                                    style={{
                                        marginLeft: '0.5rem'
                                    }}
                                    path={mdiPlusCircleOutline}
                                    size={'20px'}
                                />
                                <Typography
                                    sx={{
                                        color: '#ffffff',
                                        fontSize: '0.7rem',
                                        paddingRight: '0.5rem'
                                    }}
                                >
                                    Add To Bottom
                                </Typography>
                            </Stack>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        );
    };

    const handleCardPositionChange = (result) => {
        let customSourceId = [];

        if (changedSourceId.length) {
            customSourceId = [...changedSourceId];
        } else {
            customSourceId = [...cardListData];
        }

        const element = customSourceId[result.source.index];
        customSourceId.splice(result.source.index, 1);
        customSourceId.splice(result.destination.index, 0, element);

        setChangedSourceId(customSourceId);
    };

    const handleRemoveCard = (cardList) => {
        if (cardList.length > 0) {
            const SourceId = cardList.map((res) => res.lenderId);

            setChangedSourceId([...SourceId]);
            setCardListData([...cardList]);
            const filteredLender = allLender.filter((item) => {
                return !cardList.some((lender) => {
                    return lender.lenderId === item.lenderId;
                });
            });

            console.log(filteredLender);
            setLender(filteredLender);
        } else {
            setCardListData([]);
            setChangedSourceId([]);
            setLender(allLender);
        }
    };

    const setMarketPlaceRule = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });

        const abc = cardListData?.map((res, index) => {
            return {
                lenderId: res?.lenderId,
                position: index + 1,
                type: res?.type
            };
        });

        const xyz =
            typeof changedSourceId[0] !== 'string'
                ? changedSourceId
                : allLender
                      .filter((res) => changedSourceId.includes(res.lenderId))
                      .map((res, index) => ({
                          lenderId: res.lenderId,
                          position: index + 1,
                          type: res.type || ''
                      }));

        const sequenceArr = changedSourceId.length >= abc.length ? xyz : abc;
        const payload = {
            dsaId: id,
            orders: sequenceArr,
            status: true
        };
        payload.lenderVisible = formik.values.lenderVisible;
        if (isUpdate == false) {
            apiService
                .post(apiEndPointsConfig.addLenderSequencing, { data: payload })
                .then((response) => {
                    if (response?.data?.error) {
                        enqueueSnackbar(response?.data?.data, { variant: 'error' });
                    } else {
                        enqueueSnackbar('Lender Sequencing Set Successfully', { variant: 'success' });
                        navigate('/dsa');
                    }
                })
                .catch((error) => {
                    enqueueSnackbar('Something went Wrong', { variant: 'error' });
                });
        } else {
            apiService
                .post(apiEndPointsConfig.updateLenderSequencing, { data: payload })
                .then((response) => {
                    if (response?.data?.error) {
                        enqueueSnackbar(response?.data?.data, { variant: 'error' });
                    } else {
                        enqueueSnackbar('Lender Sequencing Updated Successfully', { variant: 'success' });
                        navigate('/dsa');
                    }
                })
                .catch((error) => {
                    enqueueSnackbar('Something went Wrong', { variant: 'error' });
                });
        }
    };

    const resetMarketPlaceRule = () => {
        getAllFormData();
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });

        if (ruleRowData?.data?.orders) {
            const lenderIds = [];
            ruleRowData?.data?.orders?.map((item) => {
                const xyz = allLender.find((res) => res.lenderId === item?.lenderId);
                lenderIds.push(xyz);
            });
            const lenderlist = allLender.filter((item) => {
                return !ruleRowData?.data?.orders.some((lender) => {
                    return lender === item?.lenderId;
                });
            });

            setCardListData(lenderIds);
            setLender(lenderlist);

            setChangedSourceId(ruleRowData?.data?.orders);
        } else {
            setLender(allLender);
            setCardListData([]);
            setChangedSourceId([]);
        }
    };

    return (
        <>
            <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{ width: { xs: '730px', md: '100%' } }}>
                <Stack width={'30%'} p={1}>
                    <Stack spacing={4}>
                        <FormLabel htmlFor="lenderVisible">Maximum Lender Visible</FormLabel>
                        <Autocomplete
                            size="small"
                            limitTags={2}
                            fullWidth
                            id="lenderVisible-list-autocomplete"
                            value={formik.values.lenderVisible || []}
                            options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]}
                            getOptionLabel={(option) => String(option)}
                            name="lenderVisible"
                            disableClearable
                            onChange={(event, newValue) => formik.setFieldValue('lenderVisible', newValue, true)}
                            renderInput={(params) => <TextField fullWidth size="small" {...params} placeholder="Select Lender Visible" />}
                            // renderOption={(props, option, { selected }) => (
                            //     <li {...props}>
                            //         {/* <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} /> */}
                            //         {option}
                            //     </li>
                            // )}
                        />
                        {formik.touched.lenderVisible && formik.errors.lenderVisible ? (
                            <div className="error-message">{formik.errors.lenderVisible}</div>
                        ) : null}
                    </Stack>
                </Stack>
                <Box pr={2}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to set market place rule',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => {
                                    setMarketPlaceRule();
                                }
                            });
                        }}
                        startIcon={<BeenhereIcon />}
                    >
                        Save
                    </Button>
                    <Button
                        sx={{
                            marginLeft: '1rem'
                        }}
                        variant="outlined"
                        onClick={() => {
                            setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure to reset to initial state?',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => {
                                    resetMarketPlaceRule();
                                }
                            });
                        }}
                        startIcon={<RestartAltIcon />}
                    >
                        Reset
                    </Button>
                    <Button
                        sx={{
                            marginLeft: '1rem'
                        }}
                        variant="outlined"
                        onClick={() => {
                            setConfirmDialog({
                                isOpen: true,
                                title: 'Are you sure you want to close without saving?',
                                subTitle: "You can't undo this operation",
                                onConfirm: () => {
                                    navigate('/dsa');
                                }
                            });
                        }}
                        startIcon={<HighlightOffOutlinedIcon />}
                    >
                        Close
                    </Button>
                </Box>
                <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
            </Box>
            <Box
                sx={{
                    backgroundImage: `url(${img})`,
                    backgroundRepeat: 'repeat',
                    // backgroundSize: '30px',
                    width: '100%',
                    height: '100%'
                }}
                pt={4}
            >
                <Stack spacing={2} direction={'row'}>
                    <Box
                        sx={{
                            width: '70%'
                        }}
                    >
                        <CardList
                            cards={cardListData || []}
                            onCardPositionChange={handleCardPositionChange}
                            onRemoveCard={handleRemoveCard}
                        />
                    </Box>
                    <Box
                        padding={2}
                        sx={{
                            width: '30%',
                            background: '#ffffff',
                            borderRadius: '10px',
                            marginRight: '1rem !important'
                        }}
                    >
                        <Box>{lender.map((item, i) => getLendersCard(item, i))}</Box>
                    </Box>
                </Stack>
            </Box>
        </>
    );
}

export default DsaLenderSequencing;
