import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    impressionDetailsTableData: []
};

export const ImpressionPageDetailsSlice = createSlice({
    name: 'impressionDetails',
    initialState,
    reducers: {
        getImpressionDetails: (state, action) => {
            state.impressionDetailsTableData = action.payload;
        }
    }
});

export const impressionPageDetailsAction = ImpressionPageDetailsSlice.actions;
