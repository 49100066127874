import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allLeads: [],
    nonEligibleSelectedFilter: null
};

export const noneligibleLeadSlice = createSlice({
    name: 'noneligibleLeads',
    initialState,
    reducers: {
        getLeads: (state, action) => {
            state.allLeads = action.payload;
        },
        getSelectedNonEligible: (state, action) => {
            state.nonEligibleSelectedFilter = action.payload;
        }
    }
});

export const noneligibleLeadsAction = noneligibleLeadSlice.actions;
