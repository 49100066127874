import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    GBDate: [],
    GBDateSelectedFilters: null,
    GBPublisher: [],
    GBPublisherSelectedFilters: null,
    GBStatusCount: [],
    GBClickedLender: [],
    clickedLenderSelectedFilter: null,
    GridName: 'Group By'
};

export const GroupByLeadSlice = createSlice({
    name: 'GBLSlice',
    initialState,
    reducers: {
        getGBDate: (state, action) => {
            state.GBDate = action.payload;
        },
        getGBPublisher: (state, action) => {
            state.GBPublisher = action.payload;
        },
        getGBStatusCount: (state, action) => {
            state.GBStatusCount = action.payload;
        },
        getGBClickedLender: (state, action) => {
            state.GBClickedLender = action.payload;
        },
        getGBDateSelectedFilters: (state, action) => {
            state.GBDateSelectedFilters = action.payload;
        },
        getGBPublisherSelectedFilters: (state, action) => {
            state.GBPublisherSelectedFilters = action.payload;
        },
        getGBClickedLenderSelectedFilters: (state, action) => {
            state.clickedLenderSelectedFilter = action.payload;
        },
        getGridName: (state, action) => {
            state.GridName = action.payload;
        }
    }
});

export const GroupByLeadSliceAction = GroupByLeadSlice.actions;
