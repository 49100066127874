import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    exitpagestats: [],
    exitpageimpression: 0,
    exitPageSelected: null
};

export const exitPageSlice = createSlice({
    name: 'exitPageSlice',
    initialState,
    reducers: {
        getExitPageStats: (state, action) => {
            state.exitpagestats = action.payload;
        },
        getExitPageImpression: (state, action) => {
            state.exitpageimpression = action.payload;
        },
        getExitPageSelected: (state, action) => {
            state.exitPageSelected = action.payload;
        }
    }
});

export const exitPageActions = exitPageSlice.actions;
