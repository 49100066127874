import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allLeads: [],
  // updateCreditPolicy: []
};

export const creditPolicySlice = createSlice({
  name: "creditPolicy",
  initialState,
  reducers: {
    getLeads: (state, action) => {
      state.allLeads = action.payload;
    },
    // getUpdatedCreditPolicy: (state, action) => {
    //     state.updateCreditPolicy = action.payload;
    // }
  },
});

export const creditPolicyAction = creditPolicySlice.actions;
