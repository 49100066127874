import React from 'react';
import { Box, Stack, Typography, InfoOutlined, Button, Divider, Tooltip } from '../../../collections/Imports';

const PageHeader = ({ title, pageAction, onBack, backIcon, helpText }) => {
    return (
        <Box sx={{ pl: 4, '@media (max-width: 900px)': { marginLeft: '-25%' } }}>
            <Stack
                direction="row"
                sx={{
                    alignItems: 'center'
                }}
            >
                <Button style={backIcon ? { minWidth: '30px' } : { display: 'none' }} onClick={onBack}>
                    {backIcon}
                </Button>
                <Typography
                    sx={{
                        color: '#202223',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontFamily: 'Inter',
                        fontSize: {
                            xs: '35px',
                            lg: '24px'
                        },
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: 'normal',
                        letterSpacing: '-0.48px',
                        paddingRight: 1
                    }}
                >
                    {title}
                </Typography>{' '}
                <Tooltip title={helpText}>
                    <InfoOutlined
                        sx={{
                            color: '#87909B',
                            fontSize: {
                                xs: '30px',
                                lg: '20px'
                            }
                        }}
                    />
                </Tooltip>
            </Stack>
            <Divider sx={{ width: '100%', pt: '8px', position: 'absolute', left: 0 }} />
        </Box>
    );
};

export default PageHeader;
