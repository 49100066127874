import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Avatar,
  Divider,
  IconButton,
  Tooltip,
} from "@mui/material";
import Icon from "@mdi/react";
import { mdiEyeArrowRightOutline, mdiMapMarkerOutline } from "@mdi/js";
import ReactEcharts from "echarts-for-react";
import circleCheck from "../../../assets/images/circleCheck.svg";
import total from "../../../assets/images/total.svg";
import moneyicon from "../../../assets/images/moneyicon.svg";
import salarymode from "../../../assets/images/salarymode.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  ArrowBack,
  CommonChart,
  PageHeader,
  TablePagination,
  apiEndPointsConfig,
  moment,
} from "collections/Imports";
import { customerDetailAction } from "store/leads/customerDetailsSlice";
import apiService from "helpers/apiService";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
const CustomerDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const childRef = useRef();
  const [columnDef, setColumnDef] = useState([]);
  const { singleCustomerDetail, allLoans, loanApplicationTableData } =
    useSelector((state) => state.customerDetails);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [totalData, setTotalData] = useState();
  const [controller, setController] = useState({
    page: 0,
    rowsPerPage: 1000,
  });
  const [tableDataLoading, setTableDataLoading] = useState(true);
  const fetchDetails = async () => {
    const data = {
      userId: id,
    };
    const response = await apiService.post(
      apiEndPointsConfig.Get_Profile_Details,
      { data }
    );

    dispatch(
      customerDetailAction.getSingleCustomerDetail(response?.data?.data)
    );
  };

  useEffect(() => {
    fetchDetails();
  }, [id]);
  const FormatedData = singleCustomerDetail;
  console.log(FormatedData, "kkkk");
  const profileInfoLeft = [
    { label: "First Name:", value: FormatedData.firstName  },
    { label: "Last Name:", value: FormatedData.lastName  },
    { label: "Contact No:", value: FormatedData.contactNo  },
    { label: "Email ID:", value: FormatedData.emailId  },
    { label: "DOB:", value: FormatedData.dob  },
    { label: "Gender:", value: FormatedData.gender  },
    { label: "PAN Number:", value: FormatedData.panCard  },
    { label: "Marital Status:", value: FormatedData.maritalStatus  },
    {
      label: "Academic Qualification:",
      value: FormatedData.academicQualification ,
    },
  ];

  const employmentDetailsLeft = [
    { label: "Profession:", value: FormatedData.profession  },
    { label: "Designation:", value: FormatedData.designation  },
    { label: "Mode of salary:", value: FormatedData.modeOfSalary  },
    { label: "Salary:", value: FormatedData.salary  },
    { label: "Current Tenure:", value: FormatedData.currentTenure  },
    { label: "Company type:", value: FormatedData.companyType  },
    { label: "Industry:", value: FormatedData.industry  },
    { label: "Company name:", value: FormatedData.companyName  },
    { label: "Bank:", value: FormatedData.bank  },
    { label: "Company address:", value: FormatedData.companyAddress  },
    { label: "Pin Code:", value: FormatedData.employmentPinCode  },
  ];

  const columns = [...columnDef];
  useEffect(() => {
    getPageData();
  }, [controller]);

  const getPageData = async () => {
    setTableDataLoading(true);
    const data = {
      userId: id,
      index: controller?.page,
      itemPerIndex: controller?.rowsPerPage,
    };

    apiService
      .post(apiEndPointsConfig.Get_Applied_Loans, { data })
      .then((response) => {
        console.log(response?.data?.data, "hhhh");
        dispatch(
          customerDetailAction.getAppliedLoan(
            response?.data?.data?.loanApplications
          )
        );
        // dispatch(customerDetailAction.getloanApplication(data));
        setTotalData(response?.data?.data?.total);
        setTableDataLoading(false);
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("Something went Wrong", { variant: "error" });
      });
  };

  useEffect(() => {
    if (allLoans && allLoans.length) {
      const allKeys = Object.keys(allLoans[0])?.filter(
        (res) =>
          res !== "applicationDate&Time"
          
      );

      const columnModification = {
        Action: {
          key: "Action",
          field: "Action",
          textAlign: "center",
          headerName: "Action",
          cellRenderer: renderMarketPlaceLink,
        },
      };

      let newColumns = allKeys.map((key) => {
        return {
          field: key,
          headerName: key.charAt(0).toUpperCase() + key.slice(1),
          textAlign: "center",
          defaultSort: "asc",
          cellClass: "my-class",
          editable: false,
          filterParams: {
            buttons: ["apply", "reset"],
            closeOnApply: true,
          },
        };
      });
      newColumns.push({
        key: 'applicationDate&Time',
        cellClass: 'my-class',
        field: 'applicationDate&Time',
        headerName: 'Application Date & Time',
        textAlign: 'center',
        editable: false,
        defaultWidth: 200,
        cellRenderer: renderCreatedOn,
        filterParams: {
            buttons: ['apply', 'reset'],
            closeOnApply: true
        }
    });
   

  
      newColumns.push(...Object.values(columnModification));
      setColumnDef(newColumns);
    }
  }, [allLoans]);

  const handleToggleFullscreen = () => {
    const element = childRef.current;
    if (!isFullscreen) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
        setIsFullscreen(true);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        setIsFullscreen(false);
      }
    }
  };

  // specify the data

  const handlePageChange = (event, newPage) => {
    setController({
      ...controller,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setController({
      ...controller,
      rowsPerPage: parseInt(event.target.value),
      page: 0,
    });
  };
  const handleClick = useCallback(
    (data) => {
      navigate(`/customerList-details/${data.leadId}`);
    },
    [navigate]
  );
  const renderCreatedOn = ({ data }) => (
    <span>
        {moment(data['applicationDate&Time']).utc().format('LLL')}
    </span>
);
  const renderMarketPlaceLink = ({ data }) => (
    <span
      style={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Tooltip title="View Log">
        <IconButton onClick={() => handleClick(data)}>
          <Icon
            color="secondary"
            path={mdiEyeArrowRightOutline}
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            size={1}
          />
        </IconButton>
      </Tooltip>
    </span>
  );

  const getOption = () => ({
    series: [
      {
        type: "gauge",
        progress: {
          show: true,
          width: 10,
        },
        axisLine: {
          lineStyle: {
            width: 10,
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          length: 10,
          lineStyle: {
            width: 2,
            color: "#D9D9D9",
          },
        },
        axisLabel: {
          show: false,
        },
        anchor: {
          show: true,
          showAbove: true,
          size: 25,
          itemStyle: {
            borderWidth: 10,
          },
        },
        title: {
          show: false,
        },
        detail: {
          valueAnimation: true,
          fontSize: "20px",
          offsetCenter: [0, "70%"],
        },
        data: [
          {
            value: FormatedData.creditScore || 0,
          },
        ],
      },
    ],
  });

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <PageHeader
        title={"Customer Details"}
        helpText={"Customer Details"}
        backIcon={<ArrowBack />}
        onBack={() => navigate(-1)}
      />
      <Grid container spacing={2} mt={3}>
        {/* Profile Picture and Pan Score */}
        <Grid item xs={12} sm={3} >
          <Paper sx={{ padding: 2, textAlign: "center",paddingTop:'30px',height:'565px' }}>
            <Avatar
              alt="Sim ran Nigam"
              src="/path/to/profile/image.jpg"
              sx={{ width: 100, height: 100, margin: "auto" }}
            />
            <Typography
              variant="body1"
              sx={{
                color: "#2A4BC0",
                fontSize: { xs: "10px", sm: "12px", md: "14px" },
                paddingBottom: "10px",
                paddingTop: "25px",
              }}
            >
              Pan Score:{" "}
              <strong style={{ color: "#000" }}>
                {FormatedData.panScore }{" "}
              </strong>
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "10px", sm: "12px", md: "14px" },
                paddingBottom: "10px",
              }}
            >
              User ID:
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px" }, pb: 3 }}
            >
              <strong>{FormatedData.userId }</strong>
            </Typography>
            <Icon path={mdiMapMarkerOutline} size={1} />
            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "10px", sm: "12px", md: "14px" },
                paddingBottom: "10px",
              }}
            >
              Address:
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px" } }}
            >
              <strong>{FormatedData?.address?.addressLine1 },</strong>
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px" } }}
            >
              <strong>{FormatedData?.address?.city },</strong>
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px" }, pb: 3 }}
            >
              <strong>{FormatedData?.address?.state }</strong>
            </Typography>

            <Typography
              variant="body1"
              sx={{
                fontSize: { xs: "10px", sm: "12px", md: "14px" },
                paddingBottom: "5px",
              }}
            >
              Pincode:
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "14px" }, pb: 3 }}
            >
              <strong>{FormatedData?.address?.pinCode }</strong>
            </Typography>
          </Paper>
        </Grid>

     
        <Grid item xs={12} sm={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Paper
                sx={{
                  padding: 2,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-around",
                 
                }}
              >
                <img src={circleCheck} alt="icon" />
                <Box>
                  <Typography variant="body1">Approved Offers</Typography>
                  <Typography variant="h3">{FormatedData.approvedOffers }</Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper
                sx={{
                  padding: 2,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <img src={total} alt="icon" />
                <Box>
                  <Typography variant="body1">Total Applications</Typography>
                  <Typography variant="h3">{FormatedData.totalApplications }</Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper
                sx={{
                  padding: 2,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <img src={moneyicon} alt="icon" />
                <Box>
                  <Typography variant="body1">Salary</Typography>
                  <Typography variant="h3">{FormatedData.salary  }</Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Paper
                sx={{
                  padding: 2,
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "space-around",
                }}
              >
                <img src={salarymode} alt="icon" />
                <Box>
                  <Typography variant="body1">Salary Mode</Typography>
                  <Typography variant="h3">
                    {FormatedData.modeOfSalary }
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={7}>
              <Paper sx={{ padding: 2 }}>
                <Typography variant="h3" sx={{ fontWeight: "700", pb: 2 }}>
                  Profile Info
                </Typography>
                <Divider />
                <Grid
                  container
                  spacing={2}
                  sx={{ marginX: "auto", paddingY: 2 }}
                >
                  {profileInfoLeft.map((detail, index) => (
                    <Grid item sm={6} key={index}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: { xs: "10px", sm: "12px", md: "14px" },
                          paddingBottom: "5px",
                        }}
                      >
                        {detail.label}
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: { xs: "10px", sm: "12px", md: "14px" },
                          pb: 2,
                        }}
                      >
                        <strong>{detail.value}</strong>
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12} sm={5}>
              <Paper sx={{ padding: 2,  height:'460px' }}>
                <Typography variant="h3" sx={{ fontWeight: "600", pb: 2 }}>
                  Credit Score
                </Typography>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingY: 2,
                  }}
                >
                  <ReactEcharts
                    option={getOption()}
                    style={{ height: "300px", width: "100%" }}
                  />
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Typography variant="h3" sx={{ fontWeight: "600", pb: 2 }}>
              Employment Details
            </Typography>
            <Divider />
            <Grid container spacing={2} sx={{ marginX: "auto", paddingY: 2 }}>
              {employmentDetailsLeft.map((detail, index) => (
                <Grid item sm={3} key={index}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "10px", sm: "12px", md: "14px" },
                      paddingBottom: "5px",
                    }}
                  >
                    {detail.label}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: { xs: "10px", sm: "12px", md: "14px" },
                      pb: 2,
                    }}
                  >
                    <strong>{detail.value}</strong>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ padding: 2 }}>
            <Grid item xs={12} ref={childRef} sx={{ paddingY: 2 }}>
              {allLoans && (
                <CommonChart
                  title={"Loan Application"}
                  // exportModuleName={'Non Eligible Leads'}
                  onToggleFullscreen={handleToggleFullscreen}
                  isFullscreen={isFullscreen}
                  addActionIcon
                  // onRefresh={onRefresh}
                  rowData={allLoans || []}
                  columnDefs={columns}
                  isGridRequired={true}
                  isServerPagination={true}
                  isLoading={tableDataLoading}
                >
                  <TablePagination
                    component="div"
                    onPageChange={handlePageChange}
                    page={controller.page}
                    count={totalData}
                    rowsPerPage={controller.rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    rowsPerPageOptions={[1000, 2500, 5000, 10000]}
                    style={{
                      ...(isFullscreen && {
                        position: "absolute",
                        bottom: "20px",
                        right: 0,
                        left: 0,
                      }),
                    }}
                  />
                </CommonChart>
              )}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomerDetails;
