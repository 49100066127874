import MainLayout from "layout/MainLayout";
import Loadable from "../Components/ui-component/Loadable";
import ProtectedRoute from "./ProtectedRoute";
import {
  lazy,
  Navigate,
  Routes,
  useEffect,
  useState,
} from "../collections/Imports";

import PunchinDashboard from 'views/punchin-dashboard';
import NotFound from '../views/notFound/NotFound';
import loadingImg from '../assets/images/loading-full.gif';
import UniqueByLeadsData from 'views/leads/dedupe/details-view-by-unique';
import DsaMasterPage from 'views/dsa';
import DsaConfig from 'views/dsa/dsaConfig';
import LeadJourney from 'views/lead-journey';
import ListByStatusCount from 'views/lead-journey/leads-list-by-status-count';
import MPClickedLendersGBD from 'views/lead-journey/group-by-date/mpClickedLender';
import MPClickedLendersGBP from 'views/lead-journey/group-by-publisher/gbp-mpClickedLender';
import DSADashboard from 'views/dsa-dashboard';
import DSAReport from 'views/dsa-report';
import CustomerDetails from 'views/leads/customer-Details';
import CustomerListDetails from 'views/leads/customerList-details';
import PromotionalOffer from "views/promotional-offer";
const Dashboard = Loadable(lazy(() => import('views/dashboard')));
const MarketPlace = Loadable(lazy(() => import('views/market-place')));
const LenderMaster = Loadable(lazy(() => import('views/lenders')));
const Leads = Loadable(lazy(() => import('views/leads')));

// const PivotLeads = Loadable(lazy(() => import('views/pivotTable')));
const DropOff = Loadable(lazy(() => import("views/drop-off")));
const MisUploads = Loadable(
  lazy(() => import("views/lenders/lender-config/MisUploads"))
);
const SettingMaster = Loadable(lazy(() => import("views/settings")));
const ExitPageDetails = Loadable(
  lazy(() => import("views/lenders/ExitPage/ExitPageDetails"))
);
const StatusMapping = Loadable(
  lazy(() => import("views/lenders/lender-config/statusMapping"))
);
const LeadLogDetails = Loadable(
  lazy(() => import("views/leads/leadLogs/leadLogDetails/index"))
);
const Schedule = Loadable(lazy(() => import("views/schedule")));
const Publisher = Loadable(lazy(() => import("views/publisher")));
const ImpressionDetails = Loadable(
  lazy(() => import("views/lenders/lenderImpressionDetails"))
);
const ClickCountDetails = Loadable(
  lazy(() => import("views/lenders/lenderClickCountDetails"))
);
const SetUpdateMarketPlaceRule = Loadable(
  lazy(() => import("views/market-place/components/Set-update-marketPlaceRule"))
);

const CampaignListingPage = Loadable(
  lazy(() => import("views/CampaignListing"))
);

const MainRoutes = (allNav) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the duration in milliseconds (e.g., 3000 for 3 seconds)

    return () => clearTimeout(timer);
  }, []); // useEffect will run once when the component mounts

  const LoadingFallback = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img src={loadingImg} alt="Loading..." style={{ width: "300px" }} />
    </div>
  );

  return {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: allNav.find((item) => item.to === "/dashboard") ? (
          <Navigate to="/dashboard" replace />
        ) : loading ? (
          <LoadingFallback />
        ) : (
          <NotFound />
        ),
      },
      {
        path: "/dashboard",
        element: (
          <ProtectedRoute>
            {allNav.find((item) => item.to === "/dashboard") ? (
              <Dashboard />
            ) : loading ? (
              <LoadingFallback />
            ) : (
              <NotFound />
            )}
          </ProtectedRoute>
        ),
      },
      {
        path: "/leads",
        element: (
          <ProtectedRoute>
            {allNav.find((item) => item.to === "/leads") ? (
              <Leads />
            ) : loading ? (
              <LoadingFallback />
            ) : (
              <NotFound />
            )}
          </ProtectedRoute>
        ),
      },
      {
        path: "/leads/unique-by-data/:id",
        element: (
          <ProtectedRoute>
            {allNav.find((item) => item.to === "/leads") ? (
              <UniqueByLeadsData />
            ) : loading ? (
              <LoadingFallback />
            ) : (
              <NotFound />
            )}
          </ProtectedRoute>
        ),
      },
      {
        path: "/leads/:id",
        element: (
          <ProtectedRoute>
            <LeadLogDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: "/customer-details/:id",
        element: (
          <ProtectedRoute>
            <CustomerDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: "/customerList-details/:id",
        element: (
          <ProtectedRoute>
            <CustomerListDetails />
          </ProtectedRoute>
        ),
      },

      {
        path: "/lender-configuration/:id",
        element: (
          <ProtectedRoute>
            <StatusMapping />
          </ProtectedRoute>
        ),
      },
      {
        path: "/drop-off",
        element: (
          <ProtectedRoute>
            {allNav.some((item) => item.to === "/drop-off") ? (
              <DropOff />
            ) : loading ? (
              <LoadingFallback />
            ) : (
              <NotFound />
            )}
          </ProtectedRoute>
        ),
      },
      {
        path: "/mis-upload",
        element: (
          <ProtectedRoute>
            {allNav.some((item) => item.to === "/mis-upload") ? (
              <MisUploads />
            ) : loading ? (
              <LoadingFallback />
            ) : (
              <NotFound />
            )}
          </ProtectedRoute>
        ),
      },

      {
        path: "/lenders",
        element: (
          <ProtectedRoute>
            {allNav.some((item) => item.to === "/lenders") ? (
              <LenderMaster />
            ) : loading ? (
              <LoadingFallback />
            ) : (
              <NotFound />
            )}
          </ProtectedRoute>
        ),
      },
      {
        path: "/lenders/:id",
        element: (
          <ProtectedRoute>
            <ExitPageDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: "/lenders/lender-impression/:id",
        element: (
          <ProtectedRoute>
            <ImpressionDetails />
          </ProtectedRoute>
        ),
      },
      {
        path: "/lenders/lender-click-count/:id",
        element: (
          <ProtectedRoute>
            <ClickCountDetails />
          </ProtectedRoute>
        ),
      },

            {
                path: '/scheduler',
                element: (
                    <ProtectedRoute>
                        {allNav.some((item) => item.to === '/scheduler') ? <Schedule /> : loading ? <LoadingFallback /> : <NotFound />}
                    </ProtectedRoute>
                )
            },
            {
                path: '/settings',
                element: (
                    <ProtectedRoute>
                        {allNav.some((item) => item.to === '/settings') ? <SettingMaster /> : loading ? <LoadingFallback /> : <NotFound />}
                    </ProtectedRoute>
                )
            },
            {
                path: '/punchin',
                element: (
                    <ProtectedRoute>
                        {allNav.some((item) => item.to === '/punchin') ? (
                            <PunchinDashboard />
                        ) : loading ? (
                            <LoadingFallback />
                        ) : (
                            <NotFound />
                        )}
                    </ProtectedRoute>
                )
            },
            {
                path: '/publisher',
                element: (
                    <ProtectedRoute>
                        {allNav.some((item) => item.to === '/publisher') ? <Publisher /> : loading ? <LoadingFallback /> : <NotFound />}
                    </ProtectedRoute>
                )
            },
            {
                path: '/market-place',
                element: (
                    <ProtectedRoute>
                        {allNav.some((item) => item.to === '/market-place') ? (
                            <MarketPlace />
                        ) : loading ? (
                            <LoadingFallback />
                        ) : (
                            <NotFound />
                        )}
                    </ProtectedRoute>
                )
            },
            {
                path: '/market-place/:id',
                element: (
                    <ProtectedRoute>
                        <SetUpdateMarketPlaceRule />
                    </ProtectedRoute>
                )
            },
            {
              path: '/promotional-offer',
              element: (
                  <ProtectedRoute>
                      {allNav.some((item) => item.to === '/promotional-offer') ? (
                          <PromotionalOffer />
                      ) : loading ? (
                          <LoadingFallback />
                      ) : (
                          <NotFound />
                      )}
                  </ProtectedRoute>
              )
          },
         

            //------------Note: START lead-journey routes here------------
            {
                path: '/lead-journey',
                element: (
                    <ProtectedRoute>
                        <LeadJourney />
                    </ProtectedRoute>
                )
            },
            {
                path: '/lead-journey/:id',
                element: (
                    <ProtectedRoute>
                        <ListByStatusCount />
                    </ProtectedRoute>
                )
            },
            {
                path: '/lead-journey/mp-clicked-lenders/',
                element: (
                    <ProtectedRoute>
                        <MPClickedLendersGBD />
                    </ProtectedRoute>
                )
            },
            {
                path: '/lead-journey/mp-gbp-clicked-lenders',
                element: (
                    <ProtectedRoute>
                        <MPClickedLendersGBP />
                    </ProtectedRoute>
                )
            },

      //------------Note: END lead-journey routes------------

      {
        path: "/dsa",
        element: (
          <ProtectedRoute>
            {allNav.some((item) => item.to === "/dsa") ? (
              <DsaMasterPage />
            ) : loading ? (
              <LoadingFallback />
            ) : (
              <NotFound />
            )}
          </ProtectedRoute>
        ),
      },
      {
        path: "/dsa/:id",
        element: (
          <ProtectedRoute>
            {allNav.some((item) => item.to === "/dsa") ? (
              <DsaConfig />
            ) : loading ? (
              <LoadingFallback />
            ) : (
              <NotFound />
            )}
          </ProtectedRoute>
        ),
      },
      {
        path: "/DSADashboard",
        element: (
          <ProtectedRoute>
            {allNav.some((item) => item.to === "/DSADashboard") ? (
              <DSADashboard />
            ) : loading ? (
              <LoadingFallback />
            ) : (
              <NotFound />
            )}
          </ProtectedRoute>
        ),
      },
      {
        path: "/DSAReport",
        element: (
          <ProtectedRoute>
            {allNav.some((item) => item.to === "/DSAReport") ? (
              <DSAReport />
            ) : loading ? (
              <LoadingFallback />
            ) : (
              <NotFound />
            )}
          </ProtectedRoute>
        ),
      },

      // Campaigns related Routes

      {
        path: "/campaigns",
        element: (
          <ProtectedRoute>
            {allNav.some((item) => item.to === "/campaigns") ? (
              <CampaignListingPage />
            ) : loading ? (
              <LoadingFallback />
            ) : (
              <NotFound />
            )}
          </ProtectedRoute>
        ),
      },
      { path: "*", element: <NotFound /> },
    ],
  };
};
export default MainRoutes;
