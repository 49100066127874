import React, { useEffect, useRef } from 'react';
import { init } from 'echarts';
import { Box, CommonChart, useState } from '../../../../collections/Imports';
import { useSelector } from 'react-redux';

const TrendOfDisbursal = () => {
    const myChart = useRef(null);
    const childRef = useRef();
    const { trendOfDisbursal } = useSelector((state) => state.dsaDashboard);
    const [xaxis, setXaxis] = useState([]);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [chartData, setChartData] = useState({
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: xaxis
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [],
                type: 'line',
                areaStyle: {}
            }
        ]
    });

    useEffect(() => {
        const chart = init(myChart.current);

        const handleResize = () => {
            chart.resize();
        };
        window.addEventListener('resize', handleResize);

        chart.showLoading();

        if (Object.keys(chartData)?.length === 0 || chartData?.xAxis?.length === 0) {
            chart.setOption({
                graphic: [
                    {
                        type: 'text',
                        left: 'center',
                        top: 'middle',
                        style: {
                            text: 'No data found',
                            textAlign: 'center',
                            fill: '#999',
                            fontSize: 16,
                            fontWeight: 'bold'
                        }
                    }
                ]
            });
            setTimeout(() => {
                chart.hideLoading();
            }, 4000);
        } else {
            const options = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                // legend: {
                //     data: ['Eligible', 'Accepted', 'Rejected', 'Total', 'Disbursed'],
                //     left: 'center',
                //     top: 0,
                //     itemStyle: {
                //         color: ['#d6e5fb', '#d6fbf5', '#fee6e2', '#e8e9eb', '#ceffd3']
                //     }
                // },
                // title: [
                //     {
                //         right: '60%',
                //         left: 'center',
                //         text: title
                //     }
                // ],
                // toolbox: {
                //     show: true,
                //     orient: 'vertical',
                //     left: 'right',
                //     feature: {
                //         mark: { show: true },
                //         dataView: { show: false, readOnly: false },
                //         magicType: { show: true, type: ['line', 'bar', 'stack'] },
                //         restore: { show: false },
                //         saveAsImage: { show: true }
                //     }
                // },
                xAxis: [
                    {
                        type: 'category',
                        show: true,
                        axisTick: { show: false },
                        data: chartData?.xAxis,
                        axisLabel: {
                            rotate: 45
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: 'Count',
                        nameLocation: 'end',
                        show: true,
                        nameGap: 15
                    }
                ],
                series: chartData?.series,
                // color: ['#d6e5fb', '#d6fbf5', '#fee6e2', '#e8e9eb', '#ceffd3'],
                dataZoom: [
                    {
                        type: 'slider',
                        show: chartData?.xAxis?.length > 7,
                        xAxisIndex: [0],
                        bottom: -20,
                        start: 0,
                        end: Math.min(100 * (7 / chartData?.xAxis?.length), 100)
                    }
                ]
            };

            chart.setOption(options);
            chart.hideLoading();
        }
        return () => {
            chart.dispose();
        };
        // }
    }, [chartData]);

    useEffect(() => {
        if (xaxis.length) {
            const replicaChart = { ...chartData };
            const getAllEligible = trendOfDisbursal.map((res, i) => {
                return res[xaxis[i]];
            });
            // const getAllAccepted = leadPerStatus.map((res, i) => {
            //     return res[xaxis[i]]?.accepted;
            // });
            // const getAllRejected = leadPerStatus.map((res, i) => {
            //     return res[xaxis[i]]?.rejected;
            // });
            // const getAllTotal = leadPerStatus.map((res, i) => {
            //     return res[xaxis[i]]?.total;
            // });
            // const getAllDisbursed = leadPerStatus.map((res, i) => {
            //     return res[xaxis[i]]?.disbursed;
            // });

            replicaChart.xAxis = xaxis;
            replicaChart.series[0].data = getAllEligible;
            // replicaChart.series[1].data = getAllAccepted;
            // replicaChart.series[2].data = getAllRejected;
            // replicaChart.series[3].data = getAllTotal;
            // replicaChart.series[3].data = getAllDisbursed;
            setChartData(replicaChart);
        }
    }, [xaxis]);
    useEffect(() => {
        if (trendOfDisbursal?.length) {
            prepareChartData();
        }
    }, [trendOfDisbursal]);

    const prepareChartData = () => {
        const apiChartData = [...trendOfDisbursal];
        // debugger;
        const getXaxis = apiChartData.map((res) => {
            const a = Object.keys(res);
            return a[0];
        });

        setXaxis(getXaxis);
    };

    const handleToggleFullscreen = () => {
        if (childRef.current) {
            if (!isFullscreen) {
                if (childRef.current.requestFullscreen) {
                    childRef.current.requestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    if (trendOfDisbursal?.length) {
                        prepareChartData();
                    }
                    document.exitFullscreen();
                }
            }
            setIsFullscreen(!isFullscreen);
        }
    };
    const onRefresh = () => {
        // getLeadStatus();
    };
    return (
        <Box width={'100%'} ref={childRef} className={`child-component ${isFullscreen ? 'fullscreen' : ''}`}>
            <CommonChart
                title={'Trend Of Disbursal'}
                onToggleFullscreen={handleToggleFullscreen}
                isFullscreen={isFullscreen}
                // onRefresh={onRefresh}
            >
                <Box
                    ref={myChart}
                    sx={{
                        width: '100%',
                        height: { xs: isFullscreen ? '80vh' : '38rem', md: isFullscreen ? '90vh' : '30rem' }
                    }}
                ></Box>
            </CommonChart>
        </Box>
    );
};

export default TrendOfDisbursal;
