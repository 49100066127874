import React, { useEffect, useRef, useState } from 'react';
import { init } from 'echarts';
// import 'echarts-gl';
import { useSelector } from 'react-redux';

const CommonStackBarChart = ({ title, height, data, onSectionClick }) => {
    const myChart = useRef(null);

    const [chartData, setChartData] = useState({});

    useEffect(() => {
        if (Object.keys(data).length) {
            setChartData(data);
        }
    });

    const handleClick = (params) => {
        if (params.componentType === 'series' && params.seriesType === 'bar') {
            const lenderName = params.name;
            const sectionName = params.seriesName;
            onSectionClick(lenderName, sectionName);
        }
    };

    useEffect(() => {
        const chart = init(myChart.current);

        const handleResize = () => {
            chart.resize();
        };
        window.addEventListener('resize', handleResize);

        const options = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                },
                formatter: (params) => {
                    let total = 0;
                    let tooltipContent = `${params[0].name}<br/>`;
                    params.forEach((param) => {
                        total += param.value;
                        tooltipContent += `${param.marker}${param.seriesName}: ${param.value}<br/>`;
                    });
                    tooltipContent += `<br/>Total: ${total}`;
                    return tooltipContent;
                }
            },
            legend: {
                data: chartData?.legend,
                left: 'center'
            },
            grid: {
                top: '10%',
                left: '3%',
                right: '3%',
                bottom: '3%',
                containLabel: true
            },
            chart: chartData?.chart,
            plotOptions: chartData?.plotOptions,
            dataLabels: chartData?.dataLabels,
            title: [
                {
                    right: '60%',
                    left: 'center',
                    text: title
                }
            ],
            xAxis: [
                {
                    type: 'value'
                }
            ],
            yAxis: [
                {
                    type: 'category',
                    axisTick: { show: false },
                    data: chartData?.yaxis,
                    axisLabel: {
                        interval: 0,
                        fontSize: window.innerWidth < 768 ? 5 : 10 // Adjust the font size based on screen width
                    }
                }
            ],
            series: chartData?.series
        };

        chart.setOption(options);
        chart.on('click', handleClick);
        // }
    }, [chartData]);

    return (
        <div
            ref={myChart}
            style={{
                width: '100%',
                height: height,
                paddingRight: 0,
                marginRight: 0
            }}
        ></div>
    );
};

export default CommonStackBarChart;
