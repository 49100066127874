import { Box, Divider, PageHeader, Tab, TabContext, TabList, TabPanel, useTheme } from '../../collections/Imports';
import { FreezedSvg, NonEligibleSvg, LiveSvg } from '../../Components/ui-component/svg-icons/commonSvgIcons';
import { useTabContext } from '../../context/TabContaxt/TabContext';
import DsaMasterPage from './LiveDsa';
import InactiveDsa from './InactiveDsa';
import Freezed from './freezed';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { tabValuesDSASliceAction } from 'store/dsa/tabValueDsaSlice';
import Grid from '@mui/system/Unstable_Grid/Grid';

function Dsa() {
    // const [tabValue, setTabValue] = useState(1);
    const dispatch = useDispatch();
    const { tabValuesDSA } = useSelector((state) => state?.tabValuesDSA);

    // let validTabValue = tabValuesDSA || [1, 2, 3].includes(tabValue) ? tabValue : tabValuesDSA;

    const handleChange = (e, newValue) => {
        // setTabValue(newValue);
        dispatch(tabValuesDSASliceAction.getTabValuesDSA(newValue));
    };

    // useEffect(
    //     (e) => {
    //         handleChange(e, tabValuesDSA);
    //     },
    //     [tabValuesDSA]
    // );

    const theme = useTheme();
    const tabTextColorActive = '#3E7DFD';
    const tabTextColorInactive = '#525252';
    return (
        <Grid sx={{ marginTop: { xs: '25px', md: '0px' }, marginLeft: { xs: '-10%', md: '0px' } }}>
            <PageHeader title={'Dsa'} helpText={'Direct Selling Agent'} />
            <TabContext value={tabValuesDSA}>
                <Box pl={2} sx={{ '@media (max-width: 900px)': { marginLeft: '-22%', paddingLeft: 0 } }}>
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        sx={{
                            p: 0,
                            m: 0,
                            height: '15px',
                            '& .MuiTabs-indicator': {
                                borderRadius: '4px 4px 0px 0px',
                                borderBottom: '3px solid #3E7DFD'
                            }
                        }}
                    >
                        <Tab
                            icon={<LiveSvg color={tabValuesDSA === 1 ? tabTextColorActive : tabTextColorInactive} />}
                            iconPosition="start"
                            label="Live Dsa"
                            value={1}
                            style={{
                                color: tabValuesDSA === 1 ? tabTextColorActive : tabTextColorInactive,
                                leadingTrim: 'both',
                                textEdge: 'cap',
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: tabValuesDSA === 1 ? 600 : 400,
                                lineHeight: 'normal',
                                padding: 0,
                                margin: 0,
                                marginLeft: '15px',
                                marginTop: '-10px',
                                '& .Mui-selected': {
                                    borderBottom: '3px solid #3E7DFD',
                                    borderWidth: '2px',
                                    borderRadius: '4px 4px 0px 0px'
                                },
                                '& .MuiTabs-indicator': {
                                    borderRadius: '4px 4px 0px 0px',
                                    borderBottom: '3px solid #3E7DFD'
                                }
                            }}
                        />

                        <Tab
                            icon={<NonEligibleSvg color={tabValuesDSA === 2 ? tabTextColorActive : tabTextColorInactive} />}
                            iconPosition="start"
                            label="Inactive Dsa"
                            value={2}
                            style={{
                                color: tabValuesDSA === 2 ? tabTextColorActive : tabTextColorInactive,
                                leadingTrim: 'both',
                                textEdge: 'cap',
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: tabValuesDSA === 2 ? 600 : 400,
                                lineHeight: 'normal',
                                padding: 0,
                                margin: 0,
                                marginLeft: '15px',
                                marginTop: '-10px',
                                '&.Mui-selected': {
                                    borderBottom: '3px solid #3E7DFD',
                                    borderWidth: '2px',
                                    borderRadius: '4px 4px 0px 0px'
                                },
                                '& .MuiTabs-indicator': {
                                    borderRadius: '4px 4px 0px 0px',
                                    borderBottom: '3px solid #3E7DFD'
                                }
                            }}
                        />
                        <Tab
                            icon={<FreezedSvg color={tabValuesDSA === 3 ? tabTextColorActive : tabTextColorInactive} />}
                            iconPosition="start"
                            label="Freezed Dsa"
                            value={3}
                            style={{
                                color: tabValuesDSA === 3 ? tabTextColorActive : tabTextColorInactive,
                                leadingTrim: 'both',
                                textEdge: 'cap',
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: tabValuesDSA === 3 ? 600 : 400,
                                lineHeight: 'normal',
                                padding: '0px',
                                margin: 0,
                                marginLeft: '15px',
                                marginTop: '-10px',
                                '&.Mui-selected': {
                                    borderBottom: '3px solid #3E7DFD',
                                    borderWidth: '2px',
                                    borderRadius: '4px 4px 0px 0px'
                                },
                                '& .MuiTabs-indicator': {
                                    borderRadius: '4px 4px 0px 0px',
                                    borderBottom: '3px solid #3E7DFD'
                                }
                            }}
                        />
                    </TabList>
                </Box>
                <Divider />
                <Box
                    pl={1}
                    sx={{
                        '@media (max-width: 900px)': { marginLeft: '-25%', width: 600 },
                        '@media (max-width: 375px)': { width: 375 }
                    }}
                >
                    <TabPanel sx={{ '@media (max-width: 375px)': { width: 375 } }} value={1} dir={theme.direction}>
                        <DsaMasterPage />
                    </TabPanel>
                    <TabPanel sx={{ '@media (max-width: 375px)': { width: 375 } }} value={2} dir={theme.direction}>
                        <InactiveDsa />
                    </TabPanel>
                    <TabPanel sx={{ '@media (max-width: 375px)': { width: 375 } }} value={3} dir={theme.direction}>
                        <Freezed />
                    </TabPanel>
                </Box>
            </TabContext>
        </Grid>
    );
}

export default Dsa;
