import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    leadStatus: [],
    selectedLeadStatus: []
};
export const kpiSlice = createSlice({
    name: 'kpi',
    initialState,
    reducers: {
        getLeadStatusSuccess: (state, action) => {
            state.leadStatus = action.payload;
        },
        getSelectedLeadStatus: (state, action) => {
            state.selectedLeadStatus = action.payload;
        }
    }
});

export const kpiLeadAction = kpiSlice.actions;
