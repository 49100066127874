import React, { useEffect, useRef, useState } from 'react';
import { init } from 'echarts';

const CommonPieChart = ({ title, data, onSectionClick, height }) => {
    const myChart = useRef(null);

    const [chartData, setChartData] = useState({});

    useEffect(() => {
        if (Object.keys(data).length) {
            setChartData(data);
        }
    });

    const handleClick = (params) => {
        if (params.componentType === 'series' && params.seriesType === 'pie') {
            const sectionName = params.data.name;
            onSectionClick(sectionName);
        }
    };

    useEffect(() => {
        const chart = init(myChart.current);

        const handleResize = () => {
            chart.resize();
        };
        window.addEventListener('resize', handleResize);

        const options = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                data: chartData?.legend,
                orient: 'vertical',
                left: 'left'
            },
            title: [
                {
                    right: '60%',
                    left: 'center',
                    text: title
                }
            ],
            series: chartData?.series
        };

        chart.setOption(options);

        chart.on('click', handleClick);
    }, [chartData]);

    return (
        <div
            ref={myChart}
            style={{
                width: '100%',
                height: height,
                paddingRight: 0,
                marginRight: 0
            }}
        ></div>
    );
};

export default CommonPieChart;
