import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    marketPlaceTableData: [],
    mpSelectedFilter: null,
    kpiData: []
};

export const marketPlaceSlice = createSlice({
    name: 'marketPlace',
    initialState,
    reducers: {
        getMarketPlaceTableData: (state, action) => {
            state.marketPlaceTableData = action.payload;
        },
        getMpSelectedFilter: (state, action) => {
            state.mpSelectedFilter = action.payload;
        },
        getKpiData: (state, action) => {
            state.kpiData = action.payload;
        }
    }
});

export const marketPlaceActions = marketPlaceSlice.actions;
