import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    tabValuesDSA: 1
};

export const tabValuesDSASlice = createSlice({
    name: 'tabValuesDSA',
    initialState,
    reducers: {
        getTabValuesDSA: (state, action) => {
            state.tabValuesDSA = action.payload;
        }
    }
});

export const tabValuesDSASliceAction = tabValuesDSASlice.actions;
