import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    AllData: []
};

export const DsaSchedulerSlice = createSlice({
    name: 'dsaScheduler',
    initialState,
    reducers: {
        scheduleData: (state, action) => {
            state.AllData = action.payload;
        }
    }
});

export const dsaSchedulerActions = DsaSchedulerSlice.actions;
