import apiEndPointsConfig from 'helpers/apiEndPointsConfig';
import apiService from 'helpers/apiService';

export const getPunchInKpiCall = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.PunchInKPI, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error(error);
    }
};

export const getPunchInLenderStatusCall = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.PunchInLenderStatus, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error(error);
    }
};
