import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import Routes from 'routes';
import themes from 'themes';
import NavigationScroll from 'layout/NavigationScroll';
import './App.css';
import { SnackbarProvider } from 'notistack';
import { TabProvider } from 'context/TabContaxt/TabContext';
const App = () => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes()}>
                <TabProvider>
                    <SnackbarProvider maxSnack={1} dense action>
                        <CssBaseline />
                        <NavigationScroll>
                            <Routes />
                        </NavigationScroll>
                    </SnackbarProvider>
                </TabProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
