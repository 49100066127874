import Grid from '@mui/system/Unstable_Grid/Grid';
import {
    useEffect,
    ArrowBack,
    useNavigate,
    useRef,
    useState,
    Box,
    CommonChart,
    useSelector,
    moment,
    useParams
} from '../../../../collections/Imports';
import PageHeader from 'Components/ui-component/pageHeader';

const UniqueByLeadsData = () => {
    const childRef = useRef();
    const navigate = useNavigate();
    const [columnDefs, setColumnDefs] = useState([]);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const { listLeadsUniqueBy } = useSelector((state) => state?.dedupeLeads);
    const [loading, setIsLoading] = useState(true);

    const params = useParams();

    const { id } = params;

    useEffect(() => {
        if (listLeadsUniqueBy && listLeadsUniqueBy?.userLeads?.length > 0) {
            const col = [...listLeadsUniqueBy?.headers].filter((res) => res?.field !== 'createdOn');
            let newColumns = col;
            newColumns.push({
                field: 'createdOn',
                key: 'createdOn',
                headerName: 'Created On',
                minWidth: 200,
                cellRenderer: ({ data }) => moment(data?.createdOn, 'DD-MM-YYYY hh:mm:ss A').utc().format('LLL')
            });
            setColumnDefs(newColumns);
            setIsLoading(false);
        }
    }, [listLeadsUniqueBy]);

    const handleToggleFullscreen = () => {
        const element = childRef.current;
        if (!isFullscreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
                setIsFullscreen(true);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullscreen(false);
            }
        }
    };

    return (
        <Grid sx={{ width: { xs: '720px', md: '100%' }, marginLeft: { xs: '-25%', md: '0' }, marginTop: { xs: '25px', md: '0' } }}>
            <Box sx={{ marginLeft: { xs: '10%', md: '0%' } }}>
                <PageHeader title={`Unique By ${id}`} helpText={`Unique By ${id}`} backIcon={<ArrowBack />} onBack={() => navigate(-1)} />
            </Box>
            <Box p={5} pt={2} sx={{ marginTop: { xs: '20px', md: '0' }, marginLeft: { xs: '-10%', md: '0' } }}>
                <Box
                    sx={{ marginTop: 2, width: { sx: '200px', md: '100%' } }}
                    className={`child-component ${isFullscreen ? 'fullscreen' : ''}`}
                    spacing={isFullscreen ? 0 : 2}
                    ref={childRef}
                >
                    <CommonChart
                        title={`Unique By ${id}`}
                        exportModuleName={`Unique By ${id}`}
                        isFullscreen={isFullscreen}
                        onToggleFullscreen={handleToggleFullscreen}
                        columnDefs={columnDefs}
                        isLoading={loading}
                        rowData={listLeadsUniqueBy?.userLeads}
                        isGridRequired={true}
                    ></CommonChart>
                </Box>
            </Box>
        </Grid>
    );
};

export default UniqueByLeadsData;
