import React from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

const ActionSubmits = (props) => {
    const { data, field, actionHandleSubmit } = props;
    return (
        <span
            style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'end'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    cursor: `${data[field] === 0 ? 'not-allowed' : 'pointer'}`,
                    '& button': {
                        borderRadius: '16px',
                        color: `${data[field] === 0 ? '#7D94E7' : '#2A4BC0'}`,
                        background: `${data[field] === 0 ? '#fff' : '#EFF3FF'}`,
                        border: `${data[field] === 0 ? '1px solid #DEF0FC' : '1px solid #78C9FF'}`,
                        padding: '2px 12px',
                        cursor: `${data[field] === 0 ? 'not-allowed' : 'pointer'}`,
                        transition: 'background-color 0.3s ease-in-out',
                        '&:hover': {
                            background: `${data[field] === 0 ? '#fff' : '#2A4BC0'}`,
                            color: `${data[field] === 0 ? '#7D94E7' : '#fff'}`,
                            border: 'none'
                        }
                    }
                }}
            >
                <Tooltip title={data[field] === 0 ? '' : `View ${field} List`}>
                    <button disabled={data[field] === 0} onClick={() => actionHandleSubmit(data, field)}>
                        {data[field]}
                    </button>
                </Tooltip>
            </Box>
        </span>
    );
};

export default ActionSubmits;
