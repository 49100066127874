import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, IconButton, Button, Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { CloseIcon } from 'collections/Imports';

const DialogContainer = styled(Dialog)(({ theme }) => ({
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5)
}));

const TitleContainer = styled(DialogTitle)({
    textAlign: 'center'
});

const ContentContainer = styled(DialogContent)({
    textAlign: 'center'
});

const ActionsContainer = styled(DialogActions)({
    justifyContent: 'center'
});

export default function SimplePopup(props) {
    const { loading } = props;
    const { confirmDialog, setConfirmDialog } = props;

    return (
        <DialogContainer open={confirmDialog.isOpen}>
            <TitleContainer>
                <Box display={'flex'} justifyContent={'end'}>
                    <CloseIcon onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} variant="contained" />
                </Box>
            </TitleContainer>
            <ContentContainer>
                <Typography variant="subtitle3">{confirmDialog.title}</Typography>
                <Typography variant="h4" color={'#ee9329'} pt={1}>
                    {confirmDialog.subTitle}
                </Typography>
            </ContentContainer>
            <Box bgcolor={'#e7e7e7'}>
                <ActionsContainer bgcolor={'red'}>
                    <Box pb={2}>
                        <Typography pb={1}>
                            <span style={{ color: '#000' }}> Email: </span>
                            <span variant="subtitle2"> {confirmDialog.email}</span>
                        </Typography>
                        <Typography>
                            <span style={{ color: '#000' }}> Password: </span>
                            <span variant="subtitle2"> {confirmDialog.password}</span>
                        </Typography>
                    </Box>
                </ActionsContainer>
            </Box>
        </DialogContainer>
    );
}
