import React from 'react';
import { FormControl, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

const CheckboxGroup = ({ formik, valuesKey, options }) => {
    const handleCheckboxChange = (optionKey) => (e) => {
        if (optionKey === 'all') {
            const allKeys = {};
            for (let i = 0; i < options.length; i++) {
                const a = options[i]['key'];
                allKeys[a] = formik.values[valuesKey]['all'] ? false : true;
            }
            formik.setFieldValue(valuesKey, {
                ...allKeys
            });
        } else if (Object.keys(formik.values[valuesKey]).includes(optionKey)) {
            const newVal = { ...formik.values[valuesKey] };
            newVal[optionKey] = !newVal[optionKey];
            const a = Object.values(newVal);
            a.shift();
            if (a.includes(false)) {
                newVal['all'] = false;
            } else {
                newVal['all'] = true;
            }
            formik.setFieldValue(valuesKey, {
                ...newVal
            });
        }
    };

    return (
        <FormControl>
            <FormGroup>
                {options?.map((option) => (
                    <FormControlLabel
                        key={option.key}
                        control={
                            <Checkbox
                                checked={formik?.values[valuesKey] ? formik?.values[valuesKey][option?.key] : false}
                                onChange={handleCheckboxChange(option?.key)}
                            />
                        }
                        label={option.label}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};

export default CheckboxGroup;
