import apiEndPointsConfig from 'helpers/apiEndPointsConfig';
import apiService from 'helpers/apiService';
export const getLeadsOffUtmSource = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.leadsUtmSource, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error(error);
        return error;
    }
};

//dedupe api calling

export const getDedupeLeadsService = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.DEDUPE_LEADS, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error('Error in API Request:', error);
        throw error;
    }
};

export const getUniqueBy = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.UNIQUE_BY, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error('Error in API Request:', error);
        throw error;
    }
};

export const getUniqueClickData = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.UNIQUE_CLICK_DATA, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error('Error in API Request:', error);
        throw error;
    }
};
export const getFilteredLeadLog = async (requestData) => {
    try {
        const response = await apiService.post(apiEndPointsConfig.GET_FILTERED_LEAD_LOG, { data: requestData });
        const responseData = response?.data?.data;
        return responseData;
    } catch (error) {
        console.error('Error in API Request:', error);
        throw error;
    }
};
