import { Grid, useRef, useState, Box, CommonChart } from '../../../collections/Imports.js';

import { useSelector } from 'react-redux';

export default function PunchinStatsTable({ onStatsTableRefresh, isPisLoading }) {
    const { punchinLenderStats } = useSelector((state) => state.punchIn);
    const childRef = useRef();
    const [isFullscreen, setIsFullscreen] = useState(false);

    const groups = [
        { name: 'rejected', header: 'Rejected', type: 'details', headerAlign: 'center' },
        { name: 'accepted', header: 'Accepted', type: 'details', headerAlign: 'center' },
        { name: 'duplicate', header: 'Duplicate', type: 'details', headerAlign: 'center' },
        { name: 'error', header: 'Error', type: 'details', headerAlign: 'center' }
    ];

    // Function to generate columns dynamically based on groups
    const generateColumns = (groups) => {
        const columns = [
            {
                field: 'lenderId',
                headerName: 'Lender ID'
            },
            {
                field: 'lenderName',
                headerName: 'Lender Name',
                cellRenderer: ({ data }) => {
                    return data?.lenderName?.charAt(0).toUpperCase() + data?.lenderName?.slice(1);
                }
            },
            { field: 'total', headerName: 'Total' }
        ];

        groups.forEach((group, index) => {
            columns.push({
                headerName: group.header,
                children: [
                    {
                        field: `status[${index}].percentage`,
                        headerName: 'Percentage %',
                        cellRenderer: ({ data }) => {
                            return data?.status[index]?.percentage ? `${data?.status[index]?.percentage} %` : '0%';
                        }
                    },
                    {
                        field: `status[${index}].count`,
                        headerName: 'Count',
                        cellRenderer: ({ data }) => {
                            return data?.status[index]?.count ? data?.status[index]?.count : 0;
                        }
                    }
                ]
            });
        });

        return columns;
    };

    const columnDefs = generateColumns(groups);

    const handleToggleFullscreen = () => {
        const element = childRef.current;
        if (!isFullscreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
                setIsFullscreen(true);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullscreen(false);
            }
        }
    };

    return (
        <>
            <Grid container>
                <Grid item sx={{ width: { xs: '730px', md: '100%' } }}>
                    <Box>
                        <Box sx={{ pl: '4px' }} ref={childRef} className={`child-component ${isFullscreen ? 'fullscreen' : ''}`}>
                            {punchinLenderStats && (
                                <CommonChart
                                    title={'Lender Stats'}
                                    onToggleFullscreen={handleToggleFullscreen}
                                    isFullscreen={isFullscreen}
                                    onRefresh={onStatsTableRefresh}
                                    isLoading={isPisLoading}
                                    isGridRequired={true}
                                    rowData={punchinLenderStats}
                                    columnDefs={columnDefs}
                                />
                            )}
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
