import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allLeads: [],
    rowData: [],
    dedupeSelectedFilter: null,
    panCard: {},
    contactNo: {},
    bothPC: {},
    listLeadsUniqueBy: []
};
export const dedupeLeadsSlice = createSlice({
    name: 'dedupeLeads',
    initialState,
    reducers: {
        getLeads: (state, action) => {
            state.allLeads = action.payload;
        },
        getDedupeSelectedFilter: (state, action) => {
            state.dedupeSelectedFilter = action.payload;
        },
        getPanCard: (state, action) => {
            state.panCard = action.payload;
        },
        getContactNo: (state, action) => {
            state.contactNo = action.payload;
        },
        getbothPC: (state, action) => {
            state.bothPC = action.payload;
        },
        getListLeadsUniqueBy: (state, action) => {
            state.listLeadsUniqueBy = action.payload;
        },
        getRowData: (state, action) => {
            state.rowData = action.payload;
        }
    }
});

export const dedupeLeadSliceAction = dedupeLeadsSlice.actions;
