//Note:  (Lead Journey) - (Group By Publisher) - Market Place Click Lenders

import ActionSubmits from 'views/market-place-analytics(NotUSING)/common/ActionSubmits';
import {
    useEffect,
    useNavigate,
    useRef,
    useState,
    Box,
    CommonChart,
    ArrowBack,
    useSelector,
    moment,
    useLocation
} from '../../../../collections/Imports';
import PageHeader from 'Components/ui-component/pageHeader';
import { groupMpLenderLeadClickLog } from 'helpers/credmudra/market-place-analytics';
import { useDispatch } from 'react-redux';
import { GroupByLeadSliceAction } from 'store/lead-journey/GroupByLeadSlice';
import Grid from '@mui/system/Unstable_Grid/Grid';

const MPClickedLenders = () => {
    const childRef = useRef();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [columnDefs, setColumnDefs] = useState([]);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const { GBClickedLender, GBPublisherSelectedFilters } = useSelector((state) => state.groupByLead);

    const [loading, setIsLoading] = useState(true);

    const { state } = location;

    const actionHandleSubmit = async (data, field) => {
        if (GBPublisherSelectedFilters != null) {
            const { fromDate, toDate } = GBPublisherSelectedFilters;
            const newData = {
                fromDate: moment(fromDate).startOf('day').toISOString(),
                toDate: moment(toDate).endOf('day').toISOString(),
                lenderId: data?.lenderId,
                sources: state,
                mediums: ['All'],
                campaigns: ['All']
            };

            const response = await groupMpLenderLeadClickLog(newData);
            if (response) {
                dispatch(GroupByLeadSliceAction.getGBStatusCount(response));
                dispatch(GroupByLeadSliceAction.getGridName('Group By Publisher'));
                navigate(`/lead-journey/${field}`);
            }
        }
    };

    useEffect(() => {
        if (GBClickedLender && GBClickedLender.length) {
            const allKeys = Object.keys(GBClickedLender[0]).filter((res) => res !== 'clickCount');
            let newColumns = allKeys.map((key) => {
                return {
                    field: key,
                    headerName: key.charAt(0).toUpperCase() + key.slice(1),
                    defaultWidth: 180,
                    textAlign: 'center',
                    defaultSort: 'asc',
                    cellClass: 'my-class',
                    editable: false,
                    filterParams: {
                        buttons: ['apply', 'reset'],
                        closeOnApply: true
                    }
                };
            });

            newColumns.push({
                field: 'clickCount',
                headerName: 'Click Count',
                header: 'clickCount',
                editable: false,
                defaultWidth: 200,
                cellRenderer: (params) => <ActionSubmits data={params?.data} field={'clickCount'} actionHandleSubmit={actionHandleSubmit} />
            });

            setColumnDefs(newColumns);
        }
    }, [GBClickedLender]);

    const handleToggleFullscreen = () => {
        const element = childRef.current;
        if (!isFullscreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
                setIsFullscreen(true);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullscreen(false);
            }
        }
    };
    return (
        <Grid sx={{ marginLeft: { xs: '-12%', md: '0%' }, marginTop: { xs: '25px', md: '0' } }}>
            <PageHeader title={`Group By Publisher`} helpText={`Group By Publisher`} backIcon={<ArrowBack />} onBack={() => navigate(-1)} />
            <Box p={5} pt={2}>
                <Box
                    sx={{ marginTop: 2, marginLeft: { xs: '-25%', md: '0%' } }}
                    className={`child-component ${isFullscreen ? 'fullscreen' : ''}`}
                    spacing={isFullscreen ? 0 : 2}
                    ref={childRef}
                >
                    <CommonChart
                        title={'Market Place Click Lenders'}
                        exportModuleName={'Market Place Click Lenders'}
                        isFullscreen={isFullscreen}
                        onToggleFullscreen={handleToggleFullscreen}
                        columnDefs={columnDefs}
                        loading={loading}
                        rowData={GBClickedLender}
                        isGridRequired={true}
                    ></CommonChart>
                </Box>
            </Box>
        </Grid>
    );
};

export default MPClickedLenders;
