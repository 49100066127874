import { Navigate, React } from '../collections/Imports';

const ProtectedRoute = ({ children }) => {
    const user = localStorage.getItem('accessToken');
    if (user) {
        return children;
    } else {
        return <Navigate to="/login" replace />;
    }
};

export default ProtectedRoute;
