// Note: This is Master Component File For Lead Journey

import { useTabContext } from 'context/TabContaxt/TabContext';
import { Box, Divider, PageHeader, Tab, TabContext, TabList, TabPanel } from '../../collections/Imports';
import { GroupByDateSvg, GroupByPublisherSvg } from '../../Components/ui-component/svg-icons/commonSvgIcons';
import GroupByDate from './group-by-date';
import GroupByPublisher from './group-by-publisher';
import Grid from '@mui/system/Unstable_Grid/Grid';

function LeadJourney() {
    const { tabValue, handleChange } = useTabContext();

    const validTabValue = [1, 2].includes(tabValue) ? tabValue : 1;

    const tabIconColorActive = '#97B7F9';
    const tabTextColorActive = '#3E7DFD';
    const tabIconColorInactive = '#525252';
    const tabTextColorInactive = '#525252';

    return (
        <Grid sx={{ marginLeft: { xs: '-10%', md: '0%', width: '100%' }, marginTop: { xs: '25px', md: '0' } }}>
            <PageHeader title={'Lead Journey'} helpText={'Lead Journey'} />
            <TabContext value={validTabValue}>
                <Box pl={2} sx={{ '@media (max-width: 900px)': { marginLeft: '-23%', paddingLeft: 0 } }}>
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        sx={{
                            p: 0,
                            m: 0,
                            height: '15px',
                            '& .MuiTabs-indicator': {
                                borderRadius: '4px 4px 0px 0px',
                                borderBottom: '3px solid #3E7DFD'
                            }
                        }}
                    >
                        <Tab
                            icon={<GroupByDateSvg color={validTabValue === 1 ? tabIconColorActive : tabIconColorInactive} />}
                            iconPosition="start"
                            label="Group by date"
                            value={1}
                            style={{
                                color: validTabValue === 1 ? tabTextColorActive : tabTextColorInactive,
                                // Rest of the styles for the first tab...
                                leadingTrim: 'both',
                                textEdge: 'cap',
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: validTabValue === 1 ? 600 : 400,
                                lineHeight: 'normal',
                                padding: 0,
                                margin: 0,
                                marginLeft: '15px',
                                marginTop: '-10px',
                                '& .Mui-selected': {
                                    borderBottom: '3px solid #3E7DFD',
                                    borderWidth: '2px',
                                    borderRadius: '4px 4px 0px 0px'
                                },
                                '& .MuiTabs-indicator': {
                                    borderRadius: '4px 4px 0px 0px',
                                    borderBottom: '3px solid #3E7DFD'
                                }
                            }}
                        />

                        <Tab
                            icon={<GroupByPublisherSvg color={validTabValue === 2 ? tabIconColorActive : tabIconColorInactive} />}
                            iconPosition="start"
                            label="Group by publisher"
                            value={2}
                            style={{
                                color: validTabValue === 2 ? tabTextColorActive : tabTextColorInactive,
                                leadingTrim: 'both',
                                textEdge: 'cap',
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: validTabValue === 2 ? 600 : 400,
                                lineHeight: 'normal',
                                padding: 0,
                                margin: 0,
                                marginLeft: '15px',
                                marginTop: '-10px',
                                '& .Mui-selected': {
                                    borderBottom: '3px solid #3E7DFD',
                                    borderWidth: '2px',
                                    borderRadius: '4px 4px 0px 0px'
                                },
                                '& .MuiTabs-indicator': {
                                    borderRadius: '4px 4px 0px 0px',
                                    borderBottom: '3px solid #3E7DFD'
                                }
                            }}
                        />
                    </TabList>
                </Box>
                <Divider />
                <Box pl={1} pr={4} sx={{ '@media (max-width: 900px)': { marginLeft: '-25%', paddingLeft: 0, width: '770px' } }}>
                    <TabPanel value={1} sx={{ paddingRight: '0' }}>
                        <GroupByDate />
                    </TabPanel>
                    <TabPanel value={2} sx={{ paddingRight: '0' }}>
                        <GroupByPublisher />
                    </TabPanel>
                </Box>
            </TabContext>
        </Grid>
    );
}

export default LeadJourney;
