import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    peakTimeMapData: [],
    peakTime_TimeStats: []
};

export const peakTimeSlice = createSlice({
    name: 'peaktime',
    initialState,
    reducers: {
        getMapData: (state, action) => {
            state.peakTimeMapData = action.payload;
        },
        getTimeStats: (state, action) => {
            state.peakTime_TimeStats = action.payload;
        }
    }
});

export const peakTimeMapAction = peakTimeSlice.actions;
export const peakTimeStatAction = peakTimeSlice.actions;
