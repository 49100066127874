import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    offersData: []
};

export const OfferSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
        getOffers: (state, action) => {
            state.offersData = action.payload;
        }
    }
});

export const offersActions = OfferSlice.actions;
