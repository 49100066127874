import { Box, Card, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Icon from '@mdi/react';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { mdiCloseCircleOutline, mdiPlusCircleOutline } from '@mdi/js';
const CardList = ({ cards, onCardPositionChange, onRemoveCard }) => {
    const [cardList, setCardList] = useState([]);

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const { source, destination } = result;

        // Get the previous and current positions
        const previousPosition = source.index;
        const currentPosition = destination.index;

        // Reorder the cards in the state
        const updatedCards = Array.from(cardList);
        const [movedCard] = updatedCards.splice(source.index, 1);
        updatedCards.splice(destination.index, 0, movedCard);
        setCardList(updatedCards);

        // Pass the currentPosition to the parent component
        if (typeof onCardPositionChange === 'function') {
            onCardPositionChange(result);
        }
    };
    useEffect(() => {
        if (cards?.length) {
            setCardList(cards);
        } else {
            setCardList([]);
        }
    }, [cards]);
    const crossHandle = (index) => {
        const found = cardList.filter((res) => res.lenderId !== index.lenderId);
        setCardList(found);
        onRemoveCard(found);
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="cardList" direction="vertical">
                {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} style={{ margin: 'auto', width: '50%' }}>
                        {cardList.map((card, index) => (
                            <Draggable key={card?.lenderName} draggableId={`draggable-${card?.lenderId}-${index}`} index={index}>
                                {(provided) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{ ...provided.draggableProps.style }}
                                    >
                                        <Box>
                                            <div>
                                                <Icon
                                                    path={mdiCloseCircleOutline}
                                                    title="Remove"
                                                    onClick={() => crossHandle(card)}
                                                    size={'20px'}
                                                    style={{
                                                        top: '-8px',
                                                        cursor: 'pointer',
                                                        cssFloat: 'right',
                                                        width: '20px',
                                                        height: '20px',
                                                        position: 'relative',
                                                        right: '12px',
                                                        background: 'white',
                                                        borderRadius: '15px'
                                                    }}
                                                />
                                            </div>
                                            <Card
                                                key={index}
                                                sx={{
                                                    border: '1px solid #0C247B',
                                                    borderRadius: '10px',
                                                    marginBottom: '8px'
                                                }}
                                            >
                                                <Stack spacing={2} direction={'row'}>
                                                    <Box
                                                        padding={1}
                                                        sx={{
                                                            margin: 'auto'
                                                        }}
                                                    >
                                                        <Typography variant="h4">
                                                            {card?.lenderName.charAt(0).toUpperCase() + card?.lenderName.slice(1)}
                                                        </Typography>
                                                    </Box>
                                                    <Box
                                                        padding={1}
                                                        sx={{
                                                            backgroundColor: '#0C247B',
                                                            borderRadius: '50%',
                                                            width: '2rem',
                                                            height: '2rem',
                                                            color: '#ffffff',
                                                            textAlign: 'center',
                                                            margin: '10px !important'
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                color: '#ffffff',
                                                                textAlign: 'center'
                                                            }}
                                                            variant="h4"
                                                        >
                                                            {index + 1}
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            </Card>
                                        </Box>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided?.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default CardList;
