import React, { useEffect, useRef, useState } from 'react';
import {
    ArrowBack,
    Box,
    Divider,
    PageHeader,
    Tab,
    TabContext,
    TabList,
    TabPanel,
    useLocation,
    useNavigate,
    useTheme
} from '../../../collections/Imports';
import { MarketPlaceIconSvg, ScheduleIconSvg } from '../../../Components/ui-component/svg-icons/commonSvgIcons';
import DsaLenderSequencing from './lenderSequencing';
import DsaSchedule from './dsaSchedule';
import Grid from '@mui/system/Unstable_Grid/Grid';
function DsaConfig() {
    const [tabValue, setTabValue] = useState(1);
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const navigate = useNavigate();
    const location = useLocation();
    const tabValueParam = location.state?.tabValueParam;
    let validTabValue = tabValueParam || [1, 2, 3, 4].includes(tabValue) ? tabValue : 1;
    const theme = useTheme();
    useEffect(
        (e) => {
            handleChange(e, tabValueParam);
        },
        [tabValueParam]
    );

    const tabTextColorActive = '#3E7DFD';
    const tabTextColorInactive = '#525252';

    return (
        <Grid sx={{ marginTop: { xs: '25px', md: '0px' }, marginLeft: { xs: '-12%', md: '0px' } }}>
            <PageHeader
                title={'DSA Config'}
                backIcon={<ArrowBack />}
                onBack={() => navigate(-1)}
                helpText={'Configuration and Settings of DSA'}
            />
            <TabContext value={validTabValue}>
                <Box pl={2} sx={{ '@media (max-width: 900px)': { marginLeft: '-20%', paddingLeft: 0 } }}>
                    <TabList
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                        sx={{
                            p: 0,
                            m: 0,
                            height: '15px',
                            '& .MuiTabs-indicator': {
                                borderRadius: '4px 4px 0px 0px',
                                borderBottom: '3px solid #3E7DFD'
                            }
                        }}
                    >
                        <Tab
                            icon={<MarketPlaceIconSvg color={validTabValue === 1 ? tabTextColorActive : tabTextColorInactive} />}
                            iconPosition="start"
                            label="Lender Sequencing"
                            value={1}
                            style={{
                                color: validTabValue === 1 ? tabTextColorActive : tabTextColorInactive,
                                leadingTrim: 'both',
                                textEdge: 'cap',
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: validTabValue === 1 ? 600 : 400,
                                lineHeight: 'normal',
                                padding: 0,
                                margin: 0,
                                marginLeft: '15px',
                                marginTop: '-10px',
                                '& .Mui-selected': {
                                    borderBottom: '3px solid #3E7DFD',
                                    borderWidth: '2px',
                                    borderRadius: '4px 4px 0px 0px'
                                },
                                '& .MuiTabs-indicator': {
                                    borderRadius: '4px 4px 0px 0px',
                                    borderBottom: '3px solid #3E7DFD'
                                }
                            }}
                        />

                        <Tab
                            icon={<ScheduleIconSvg color={validTabValue === 2 ? tabTextColorActive : tabTextColorInactive} />}
                            iconPosition="start"
                            label="Schedule"
                            value={2}
                            style={{
                                color: validTabValue === 2 ? tabTextColorActive : tabTextColorInactive,
                                leadingTrim: 'both',
                                textEdge: 'cap',
                                fontFamily: 'Inter',
                                fontSize: '15px',
                                fontStyle: 'normal',
                                fontWeight: validTabValue === 2 ? 600 : 400,
                                lineHeight: 'normal',
                                padding: 0,
                                margin: 0,
                                marginLeft: '15px',
                                marginTop: '-10px',
                                '&.Mui-selected': {
                                    borderBottom: '3px solid #3E7DFD',
                                    borderWidth: '2px',
                                    borderRadius: '4px 4px 0px 0px'
                                },
                                '& .MuiTabs-indicator': {
                                    borderRadius: '4px 4px 0px 0px',
                                    borderBottom: '3px solid #3E7DFD'
                                }
                            }}
                        />
                    </TabList>
                </Box>
                <Divider />
                <Box
                    pl={1}
                    sx={{
                        '@media (max-width: 900px)': { marginLeft: '-24%', width: '750px' }
                    }}
                >
                    <TabPanel sx={{ '@media (max-width: 900px)': { width: '780px' } }} value={1} dir={theme.direction}>
                        <DsaLenderSequencing />
                    </TabPanel>
                    <TabPanel sx={{ '@media (max-width: 900px)': { width: '750px' } }} value={2} dir={theme.direction}>
                        <DsaSchedule />
                    </TabPanel>
                </Box>
            </TabContext>
        </Grid>
    );
}

export default DsaConfig;
