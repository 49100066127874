import PropTypes from 'prop-types';
// eslint-disable-next-line no-unused-vars
import { Drawer } from '../../../collections/Imports';

const CommonDrawer = (props) => {
    const { open, onClose, children } = props;
    return (
        <Drawer
            variant="temporary"
            anchor={'right'}
            open={open}
            onClose={onClose}
            className="drawar-style"
            sx={{
                '& .MuiDrawer-paperAnchorRight': {
                    margin: 2,
                    height: { xs: '900px', sm: '98vh' },
                    borderRadius: '8px',
                    border: '1px solid #DFE3E7',
                    background: '#FFF',
                    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.12)'
                }
            }}
        >
            {children}
        </Drawer>
    );
};

export default CommonDrawer;

CommonDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired
};
