import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';

function CommonAccordion({ defaultExpanded, title, count, children }) {
    return (
        <Accordion defaultExpanded={defaultExpanded}>
            <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>
                    {title} {count && <span className="count-style">{count}</span>}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ backgroundColor: '#e0e0e05e' }}>{children}</AccordionDetails>
        </Accordion>
    );
}

export default CommonAccordion;
