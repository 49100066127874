import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    gridData: [],
    lender: [],
    status: [],
    selectedPunchinFilter: null
};

export const punchinDataTableSlice = createSlice({
    name: 'punchinData',
    initialState,
    reducers: {
        getPunchInDataTableGridData: (state, action) => {
            state.gridData = action.payload;
        },
        getPunchInLender: (state, action) => {
            state.lender = action.payload;
        },
        getPunchInStatus: (state, action) => {
            state.status = action.payload;
        },
        getSelectedPunchinFilter: (state, action) => {
            state.selectedPunchinFilter = action.payload;
        }
    }
});

export const punchInDataTableAction = punchinDataTableSlice.actions;
