import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allLeads: [],
    selectedGoogleExitPageFilter: null
};
export const googleExitPageSlice = createSlice({
    name: 'googleExitPage',
    initialState,
    reducers: {
        getLeads: (state, action) => {
            state.allLeads = action.payload;
        },
        getSelectedFilter: (state, action) => {
            state.selectedGoogleExitPageFilter = action.payload;
        }
    }
});

export const googleExitPageSliceAction = googleExitPageSlice.actions;
