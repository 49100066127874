import React from 'react';
import {
    useEffect,
    useRef,
    useState,
    useFormik,
    moment,
    apiEndPointsConfig,
    Yup,
    Box,
    CommonChart,
    AddOutlined,
    useDispatch,
    useSelector,
    useParams,
    Icon,
    Tooltip,
    ConfirmDialog,
    useSnackbar
} from '../../../collections/Imports';
import AddEditSchedule from './components/addEditSchedule';
import apiService from 'helpers/apiService';
import { dsaSchedulerActions } from 'store/dsa/dsaScheduleSlice';
import { mdiDelete, mdiPencil } from '@mdi/js';

function DsaSchedule() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams();
    const childRef = useRef();
    const { AllData } = useSelector((state) => state.dsaSchedule);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const [columnDef, setColumnDef] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingGif, setLoadingGif] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [isAddOpen, setAddIsOpen] = useState(false);
    const [edit, setEdit] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const validationSchema = Yup.object().shape({
        name: Yup.array().min(1).required('required')
    });

    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: '',
        subTitle: ''
    });

    const inititalValues = {
        name: ['All'],
        fromDate: moment('2020-12-01').startOf('day').toISOString(),
        toDate: moment().endOf('day').toISOString(),
        empType: 'All',
        status: 'All'
    };
    const handleSubmit = async (values) => {};
    const formik = useFormik({
        initialValues: inititalValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });

    const addToggleDrawer = () => {
        setIsEdit(false);
        setAddIsOpen((prev) => !prev);
    };

    const handleAddDrawer = () => {
        setIsEdit(true);
        setAddIsOpen((prev) => !prev);
    };

    useEffect(() => {
        getScheduleData();
    }, []);

    const getScheduleData = async () => {
        try {
            const response = await apiService.post(apiEndPointsConfig.getDsaSchedule, { data: { dsaId: id } });
            dispatch(dsaSchedulerActions.scheduleData(response?.data?.data));
        } catch (error) {
            console.error(error);
        }
    };

    const handleEdit = (data) => {
        setEdit(data);
        handleAddDrawer();
    };

    const handleDelete = (data) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
        const payload = {
            id: data?.dsaScheduleId
        };
        apiService
            .post(apiEndPointsConfig.deleteDsaSchedule, { data: payload })
            .then((response) => {
                if (response.data.status.code === 200) {
                    enqueueSnackbar(`DSA Schedule is Deleted Successfully`, {
                        variant: 'success'
                    });

                    getScheduleData();
                } else {
                    enqueueSnackbar(response.data.data, { variant: 'error' });
                }
            })
            .catch((error) => {
                enqueueSnackbar(error?.message, { variant: 'error' });
            });
    };

    useEffect(() => {
        if (AllData && AllData?.dsaSchedule?.length > 0) {
            const col = [...AllData.headers];
            let newColumns = col;

            newColumns?.push({
                key: 'action',
                field: 'action',
                headerName: 'Action',
                textAlign: 'center',
                pinned: 'right',
                sortable: false,
                defaultWidth: 100,
                cellRenderer: ({ data }) => {
                    return (
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '0.5rem' }}>
                            <Tooltip
                                sx={{
                                    cursor: 'pointer'
                                }}
                                title={'Edit'}
                                arrow
                            >
                                <Icon path={mdiPencil} color="#0C247B" size={1} onClick={() => handleEdit(data)} />
                            </Tooltip>
                            <Tooltip title={'Delete'} arrow>
                                <Icon
                                    path={mdiDelete}
                                    color={'#c2061bc4'}
                                    size={1}
                                    onClick={() => {
                                        setConfirmDialog({
                                            isOpen: true,
                                            title: 'Are you sure you want to delete?',
                                            subTitle: "You can't undo this operation",
                                            onConfirm: () => {
                                                handleDelete(data);
                                            }
                                        });
                                    }}
                                />
                            </Tooltip>
                        </Box>
                    );
                }
            });

            setColumnDef(newColumns);

            setLoadingGif(false);
        }
    }, [AllData]);
    const handleToggleFullscreen = () => {
        const element = childRef.current;
        if (!isFullscreen) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
                setIsFullscreen(true);
            }
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
                setIsFullscreen(false);
            }
        }
    };

    return (
        <Box mt={2}>
            <Box
                spacing={isFullscreen ? 0 : 2}
                ref={childRef}
                className={`child-component ${isFullscreen ? 'fullscreen' : ''}`}
                sx={{ width: { xs: '730px', md: '100%' } }}
            >
                <CommonChart
                    title={'DSA Schedule'}
                    rowData={AllData?.dsaSchedule}
                    columnDefs={columnDef}
                    isGridRequired={true}
                    // isLoading={loadingGif}
                    addActionIcon={<AddOutlined />}
                    addAction={addToggleDrawer}
                    AddButtonName={'Add Rule'}
                    onToggleFullscreen={handleToggleFullscreen}
                    className={`child-component ${isFullscreen ? 'fullscreen' : ''}`}
                    isFullscreen={isFullscreen}
                />
            </Box>
            {isAddOpen && (
                <AddEditSchedule
                    setIsOpen={setAddIsOpen}
                    edit={edit}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    addToggleDrawer={addToggleDrawer}
                    setEdit={setEdit}
                    loading={loading}
                    setLoading={setLoading}
                    getPageData={getScheduleData}
                />
            )}
            <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
        </Box>
    );
}

export default DsaSchedule;
