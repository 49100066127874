import {
    useState,
    Grid,
    Paper,
    Skeleton,
    AcceptedIcon,
    RejectedIcon,
    DuplicateIcon,
    ErrorIcon,
    TotalLeadIcon,
    KpiCard
} from '../../../collections/Imports';
import { useSelector } from 'react-redux';
function Kpi() {
    const [loading, setLoading] = useState(false);
    const { punchInKpi } = useSelector((state) => state.punchIn);
    return (
        <Grid container spacing={1.9} sx={{ pl: '4px' }}>
            {loading ? (
                <>
                    {[...Array(6)].map((_, index) => (
                        <Grid item xs={12} sm={6} md={2.4} key={index}>
                            <Paper sx={{ padding: 3 }}>
                                <Skeleton variant="text" width="100%" height={50} />
                                <Skeleton variant="text" width="80%" height={100} />
                            </Paper>
                        </Grid>
                    ))}
                </>
            ) : (
                punchInKpi?.length > 0 &&
                punchInKpi?.map((item, index) => (
                    <Grid item xs={6} sm={6} md={2.4} key={index}>
                        <KpiCard
                            title={
                                item.status === 'total' ? (
                                    <span>Total</span>
                                ) : item.status === 'rejected' ? (
                                    <span>Rejected</span>
                                ) : item.status === 'accepted' ? (
                                    <span>Accepted</span>
                                ) : item.status === 'duplicate' ? (
                                    <span>Duplicate</span>
                                ) : item.status === 'error' ? (
                                    <span>Error</span>
                                ) : (
                                    <span>{item.status}</span>
                                )
                            }
                            count={item?.count || '0'}
                            icon={
                                (index === 0 && <img src={TotalLeadIcon} alt="total-lead-icon" />) ||
                                (index === 1 && <img src={RejectedIcon} alt="rejected-lead-icon" />) ||
                                (index === 2 && <img src={AcceptedIcon} alt="accepted-lead-icon" />) ||
                                (index === 3 && <img src={DuplicateIcon} alt="duplicate-lead-icon" />) ||
                                (index === 4 && <img src={ErrorIcon} alt="error-lead-icon" />)
                            }
                        />
                    </Grid>
                ))
            )}
        </Grid>
    );
}
export default Kpi;
