import React from 'react';
import { useSelector } from 'react-redux';
import {
    useState,
    Grid,
    Paper,
    Skeleton,
    AcceptedIcon,
    RejectedIcon,
    DuplicateIcon,
    ErrorIcon,
    TotalLeadIcon,
    DisAmount,
    Eligible,
    KpiCard
} from '../../../../collections/Imports';
import Icon from '@mdi/react';
import { mdiCurrencyRupee, mdiAccountCheckOutline } from '@mdi/js';

const DSAKpis = () => {
    const { kpis } = useSelector((state) => state?.dsaDashboard);
    const [loading, setLoading] = useState(false);

    return (
        <Grid container spacing={1.9}>
            {loading ? (
                <>
                    {[...Array(6)].map((_, index) => (
                        <Grid item xs={12} sm={6} md={2.4} key={index}>
                            <Paper sx={{ padding: 3 }}>
                                <Skeleton variant="text" width="100%" height={50} />
                                <Skeleton variant="text" width="80%" height={100} />
                            </Paper>
                        </Grid>
                    ))}
                </>
            ) : (
                Object.keys(kpis)?.length > 0 &&
                Object.keys(kpis)?.map((item, index) => (
                    <Grid item xs={12} sm={12} md={6} lg={3} key={index}>
                        <KpiCard
                            title={
                                item === 'LiveDsa' ? (
                                    <span>Live Dsa</span>
                                ) : item === 'accepted' ? (
                                    <span>Accepted</span>
                                ) : item === 'leads' ? (
                                    <span>Leads</span>
                                ) : item === 'disbursed' ? (
                                    <span>Disbursal Amount</span>
                                ) : (
                                    <span>{item.status}</span>
                                )
                            }
                            count={kpis[item] || '0'}
                            icon={
                                (index === 0 && <img src={TotalLeadIcon} alt="total-lead-icon" />) ||
                                // (index === 3 && <img src={RejectedIcon} alt="rejected-lead-icon" />) ||
                                (index === 2 && <img src={AcceptedIcon} alt="accepted-lead-icon" />) ||
                                (index === 1 && <Icon color="#0e236c" path={mdiAccountCheckOutline} size={1} />) ||
                                // (index === 4 && <img src={ErrorIcon} alt="error-lead-icon" />) ||
                                (index === 3 && <Icon color="green" path={mdiCurrencyRupee} size={1} />)
                            }
                        />
                    </Grid>
                ))
            )}
        </Grid>
    );
};

export default DSAKpis;
